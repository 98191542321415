











































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import NavBar from '@/components/external/ni-app/NavBar.vue';
import AppBar from '@/components/external/ni-app/AppBar.vue';
import NiFooter from '@/components/external/ni-app/NiFooter.vue';

@Component({
  components: {
    NavBar,
    AppBar,
    NiFooter,
  },
})
export default class NiApp extends Vue {
  // Data property
  name = 'App';
  showNavBar = true;
  @Prop() title!: string;
  @Prop() navigation!: [];
  @Prop() knownApps!: [];
  @Prop() icon!: string;
  @Prop() currentUser!: {};

  toggleNavBar() {
    this.showNavBar = !this.showNavBar;
  }
}
