import { client } from './apollo';
import fetchDogApplications from './gql/query/fetchDogApplications.gql';
import fetchDogApplication from './gql/query/fetchDogApplication.gql';
import fetchDogSearch from './gql/query/fetchDogSearch.gql';
import {
  FetchDogApplicationQuery,
  FetchDogApplicationQueryVariables,
  FetchDogApplicationsQuery,
  FetchDogApplicationsQueryVariables,
  FetchDogSearchQuery,
  FetchDogSearchQueryVariables,
} from '@/generated/graphql';
import { ApolloQueryResult } from '@apollo/client';

export class DogApi {
  public static async search(vars: FetchDogSearchQueryVariables): Promise<ApolloQueryResult<FetchDogSearchQuery>> {
    return client.query<FetchDogSearchQuery, FetchDogSearchQueryVariables>({
      variables: vars,
      query: fetchDogSearch,
    });
  }

  public static async find(
    vars: FetchDogApplicationsQueryVariables
  ): Promise<ApolloQueryResult<FetchDogApplicationsQuery>> {
    return client.query<FetchDogApplicationsQuery, FetchDogApplicationsQueryVariables>({
      variables: vars,
      query: fetchDogApplications,
    });
  }

  public static async application(
    vars: FetchDogApplicationQueryVariables
  ): Promise<ApolloQueryResult<FetchDogApplicationQuery>> {
    return client.query<FetchDogApplicationQuery, FetchDogApplicationQueryVariables>({
      variables: vars,
      query: fetchDogApplication,
    });
  }
}
