import { ApexOptions } from 'apexcharts';

export const ROUTER = {
  SEARCH: 'search',
  SEARCH_RESULT: 'SearchResult',
  WORKFLOW_LIST: 'WORKFLOW_LIST',
  WORKFLOW_DETAILS: 'WORKFLOW_DETAILS',

  INTEGRATIONS: 'Integrations',
  REPORT: 'report',
  GROUPS: 'groups',
  GROUP_DETAILS: 'GroupDetails',
};

export const STATES = {
  PLANNED: 'planned',
};
export const RAW_STATES = {
  PLANNED: 'WorkflowStates::Planned',
};

export const EVENTS = {
  AUTHENTICATED: 'AUTHENTICATED',
  GLOBAL_ALERT: 'GLOBAL_ALERT',
};

export const REPORT = {
  CHART_TEXT_STYLE: { color: '#FFF' },
  CHART_GRID_STYLE: { color: '#787878' },
  CHART_TITLE_STYLE: { color: '#FFF' },
};

export const DEFAULT_CHART_OPTIONS: ApexOptions = {
  dataLabels: {
    style: {
      colors: ['#616161'],
    },
    dropShadow: { enabled: false },
  },
};

export const FETCH_REPORT_DEBOUNCE = 3000;

export const CUSTOM_DATE_RANGE_NAME = 'custom';

export const SEARCH_MIN_CHARACTER = 3;
