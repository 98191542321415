export function cap(value: string) {
  value = String(value);
  if (!value) {
    return 'NA';
  }

  if (value.toLowerCase() == 'ios') {
    return value && value[0] + value.slice(1).toUpperCase();
  } else {
    return value && value[0].toUpperCase() + value.slice(1);
  }
}

export function allCap(value: string) {
  return value && value.toUpperCase();
}

export function stripSubject(value: string) {
  return value.split('|').pop();
}
export function avatarName(value: string) {
  let mail = value.split('|').pop();
  mail = mail!.split('@')[0];
  const name = mail!.split('.');
  if (name.length > 1) {
    return `${name[0][0]}${name[1][0]}`.toUpperCase();
  }
  return mail.substring(1, 3);
}
