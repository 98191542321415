import { WorkflowStoreState } from './workflow';
import { Workflow } from '@/generated/graphql';

export const WorkflowGetter = {
  GET_SELECTED_WORKFLOWS: 'GET_SELECTED_WORKFLOWS',
  GET_WORKFLOW_STATES: 'GET_WORKFLOW_STATES',
  GET_WORKFLOW_HISTORY: 'GET_WORKFLOW_HISTORY',
  IS_LOADING: 'IS_LOADING',
  IS_RECERTIFIABLE: 'IS_RECERTIFIABLE',
  GET_WORKFLOW_LOCK: 'GET_WORKFLOW_LOCK',
  GET_WORKFLOW_VIEWER: 'GET_WORKFLOW_VIEWER',
};

export default {
  [WorkflowGetter.GET_SELECTED_WORKFLOWS](state: WorkflowStoreState) {
    return state.selectedWorkflows;
  },

  [WorkflowGetter.GET_WORKFLOW_HISTORY](state: WorkflowStoreState) {
    return state.workflowHistory;
  },

  [WorkflowGetter.IS_LOADING](state: WorkflowStoreState) {
    return state.loading;
  },

  [WorkflowGetter.GET_WORKFLOW_STATES](state: WorkflowStoreState) {
    if (state.selectedWorkflows === null) return [];
    let workflowStates;
    if (state.selectedWorkflows?.length) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      workflowStates = state.selectedWorkflows.reduce((states: any, workflow: Workflow) => {
        if (workflow.workflowStates) {
          return states.concat(workflow?.workflowStates);
        }
      }, []);
    }
    return workflowStates || [];
  },

  [WorkflowGetter.IS_RECERTIFIABLE](state: WorkflowStoreState) {
    if (state.selectedWorkflows === null) return false;
    if (state.selectedWorkflows[0]?.workflowStates) {
      // selectedWorkflows[0] is always the current workflow by definition;
      return state.selectedWorkflows[0]?.workflowStates[0]?.stateType === 'WorkflowStates::Production';
    }
    return false;
  },

  [WorkflowGetter.GET_WORKFLOW_LOCK](state: WorkflowStoreState) {
    return state.selectedWorkflowsLocked;
  },
  [WorkflowGetter.GET_WORKFLOW_VIEWER](state: WorkflowStoreState) {
    return state.viewers;
  },
};
