export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  Json: any;
};

/** Autogenerated input type of AddIntegrationsToCertificationGroupMutation */
export type AddIntegrationsToCertificationGroupMutationInput = {
  /** id of the certification group */
  id: Scalars['Int'];
  /** Integrations to be added to group */
  integrations: Array<IntegrationInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of AddIntegrationsToCertificationGroupMutation */
export type AddIntegrationsToCertificationGroupMutationPayload = {
  __typename?: 'AddIntegrationsToCertificationGroupMutationPayload';
  certificationGroup?: Maybe<CertificationGroup>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  status: Scalars['String'];
};

export type Application = {
  __typename?: 'Application';
  adMeasurement?: Maybe<Scalars['Boolean']>;
  agfIdentity?: Maybe<Scalars['Boolean']>;
  appType?: Maybe<Scalars['String']>;
  applicationTemplateId?: Maybe<Scalars['String']>;
  cms?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  darReport?: Maybe<Scalars['String']>;
  ddrsEntity?: Maybe<DdrsEntity>;
  ddrsEntityId?: Maybe<Scalars['String']>;
  deploymentType?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dpr?: Maybe<Scalars['Boolean']>;
  facebookSuppression?: Maybe<Scalars['Boolean']>;
  geoFencing?: Maybe<Scalars['String']>;
  groupName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  implementationType?: Maybe<Scalars['String']>;
  jicCode?: Maybe<Scalars['String']>;
  legacyPing?: Maybe<Scalars['Boolean']>;
  localTvEligible?: Maybe<Scalars['Boolean']>;
  marketIntelligence?: Maybe<Scalars['Boolean']>;
  mocrEntityId?: Maybe<Scalars['Int']>;
  multiLingualCms?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  operatingSystem?: Maybe<Scalars['String']>;
  owner?: Maybe<Owner>;
  ownerType?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Scalars['String']>>;
  rowVersion?: Maybe<Scalars['Int']>;
  singleConfiguration?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  storeUrl?: Maybe<Scalars['String']>;
  supportUrl?: Maybe<Scalars['String']>;
  suspendedProducts?: Maybe<Array<Scalars['String']>>;
  syndicated?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  userOptOut?: Maybe<Scalars['String']>;
  vaBeacon?: Maybe<Scalars['Boolean']>;
  videoCensus?: Maybe<Scalars['Boolean']>;
  vri?: Maybe<Scalars['Boolean']>;
  websiteUrl?: Maybe<Scalars['String']>;
};

export type AssignedUser = {
  __typename?: 'AssignedUser';
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type AssignedUserInput = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of AttachWorkflowStateFileMutation */
export type AttachWorkflowStateFileMutationInput = {
  /** Signed blob ID generated via `createDirectUpload` mutation */
  signedBlobId: Scalars['String'];
  /** workflow state id to attach file */
  workflowStateId: Scalars['ID'];
  /** blob id to find attachment id */
  blobId: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of AttachWorkflowStateFileMutation */
export type AttachWorkflowStateFileMutationPayload = {
  __typename?: 'AttachWorkflowStateFileMutationPayload';
  attachmentId: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  status: Scalars['String'];
};

export type Brand = {
  __typename?: 'Brand';
  adSupported?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<Parent>;
  parentId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  subCategory?: Maybe<SubCategory>;
  subCategoryId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type C6s = {
  __typename?: 'C6s';
  cid?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  effectiveStartDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  syndicated?: Maybe<Scalars['String']>;
};

export type Category = {
  __typename?: 'Category';
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type CertificationGroup = {
  __typename?: 'CertificationGroup';
  certificationState: Scalars['String'];
  contact?: Maybe<AssignedUser>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  integrations: Array<Integration>;
  marketViewEntity?: Maybe<MarketViewEntity>;
  name: Scalars['String'];
  ratio?: Maybe<Scalars['Float']>;
  recertifiable: Recertifiability;
};

export type Channel = {
  __typename?: 'Channel';
  adSupported?: Maybe<Scalars['String']>;
  brand?: Maybe<Brand>;
  brandId?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subCategory?: Maybe<SubCategory>;
  subCategoryId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type Comment = {
  __typename?: 'Comment';
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isEditable?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  workflowState?: Maybe<WorkflowState>;
};

export type Country = {
  __typename?: 'Country';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  primaryTimezone?: Maybe<Scalars['String']>;
  rowVersion?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateCertificationGroupMutation */
export type CreateCertificationGroupMutationInput = {
  /** Name of the Group */
  name: Scalars['String'];
  /** Percentage of groups t  hat need to be certified */
  ratio?: Maybe<Scalars['Float']>;
  /** Optional Description */
  description?: Maybe<Scalars['String']>;
  /** Optional Description */
  integrations?: Maybe<Array<IntegrationInput>>;
  /** Nielsen contact for this group */
  contact?: Maybe<AssignedUserInput>;
  /** Parent entity for this group */
  marketViewEntity?: Maybe<MarketViewEntityInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateCertificationGroupMutation */
export type CreateCertificationGroupMutationPayload = {
  __typename?: 'CreateCertificationGroupMutationPayload';
  certificationGroup?: Maybe<CertificationGroup>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  status: Scalars['String'];
};

/** Autogenerated input type of CreateCommentMutation */
export type CreateCommentMutationInput = {
  content: Scalars['String'];
  workflowStateId: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateCommentMutation */
export type CreateCommentMutationPayload = {
  __typename?: 'CreateCommentMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  comment?: Maybe<Comment>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateCompleteRecertificationMutation */
export type CreateCompleteRecertificationMutationInput = {
  integrations: Array<Scalars['ID']>;
  products?: Maybe<Array<Scalars['String']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateCompleteRecertificationMutation */
export type CreateCompleteRecertificationMutationPayload = {
  __typename?: 'CreateCompleteRecertificationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  integrations?: Maybe<Array<Integration>>;
  message: Scalars['String'];
  status: Scalars['String'];
};

/** Autogenerated input type of CreateDeviceTypeGroupMutation */
export type CreateDeviceTypeGroupMutationInput = {
  deviceGroup: DeviceTypeGroupInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateDeviceTypeGroupMutation */
export type CreateDeviceTypeGroupMutationPayload = {
  __typename?: 'CreateDeviceTypeGroupMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  group?: Maybe<DeviceTypeGroup>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateDirectUploadMutation */
export type CreateDirectUploadMutationPayload = {
  __typename?: 'CreateDirectUploadMutationPayload';
  directUpload?: Maybe<DirectUpload>;
  message: Scalars['String'];
  status: Scalars['String'];
};

/** Autogenerated input type of CreateIntegrationMutation */
export type CreateIntegrationMutationInput = {
  name: Scalars['String'];
  appId: Scalars['String'];
  os: Scalars['String'];
  region: Scalars['String'];
  clientId: Scalars['String'];
  c6: Scalars['String'];
  deviceTypeId?: Maybe<Scalars['Int']>;
  hierarchyEntityName?: Maybe<Scalars['String']>;
  dcrStreamTypeId?: Maybe<Scalars['Int']>;
  assignedUser?: Maybe<AssignedUserInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateIntegrationMutation */
export type CreateIntegrationMutationPayload = {
  __typename?: 'CreateIntegrationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  integration?: Maybe<Integration>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateRecertWorkflowMutation */
export type CreateRecertWorkflowMutationInput = {
  integrationId: Scalars['ID'];
  product: Scalars['String'];
  data?: Maybe<Scalars['Json']>;
  assignee?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateRecertWorkflowMutation */
export type CreateRecertWorkflowMutationPayload = {
  __typename?: 'CreateRecertWorkflowMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  status: Scalars['String'];
  workflow?: Maybe<Workflow>;
};

/** Autogenerated input type of CreateReportFiltersMutation */
export type CreateReportFiltersMutationInput = {
  data: ReportFilterInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateReportFiltersMutation */
export type CreateReportFiltersMutationPayload = {
  __typename?: 'CreateReportFiltersMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  filters?: Maybe<ReportFilter>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateWorkflowMutation */
export type CreateWorkflowMutationInput = {
  integrationId: Scalars['Int'];
  product: Scalars['String'];
  data?: Maybe<Scalars['Json']>;
  assignee?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateWorkflowMutation */
export type CreateWorkflowMutationPayload = {
  __typename?: 'CreateWorkflowMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  status: Scalars['String'];
  workflow?: Maybe<Workflow>;
};

export type DdrsEntity = {
  __typename?: 'DdrsEntity';
  activeLocalDdrsCount?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  effectiveDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  level?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['String']>;
  rowVersion?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  vcId?: Maybe<Scalars['String']>;
};

export type DefaultCid = {
  __typename?: 'DefaultCid';
  clientId?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  mvParentId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated return type of DeleteCertificationGroupMutation */
export type DeleteCertificationGroupMutationPayload = {
  __typename?: 'DeleteCertificationGroupMutationPayload';
  message: Scalars['String'];
  status: Scalars['String'];
};

/** Autogenerated input type of DeleteIntegrationMutation */
export type DeleteIntegrationMutationInput = {
  appId: Array<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteIntegrationMutation */
export type DeleteIntegrationMutationPayload = {
  __typename?: 'DeleteIntegrationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  status: Scalars['String'];
};

/** Autogenerated input type of DeleteWorkflowStateFileMutation */
export type DeleteWorkflowStateFileMutationInput = {
  attachmentId: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteWorkflowStateFileMutation */
export type DeleteWorkflowStateFileMutationPayload = {
  __typename?: 'DeleteWorkflowStateFileMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  status: Scalars['String'];
};

/** Autogenerated input type of DeleteWorkflowStateLinkMutation */
export type DeleteWorkflowStateLinkMutationInput = {
  id: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteWorkflowStateLinkMutation */
export type DeleteWorkflowStateLinkMutationPayload = {
  __typename?: 'DeleteWorkflowStateLinkMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  status: Scalars['String'];
};

export enum Deployment {
  Test = 'test',
  Production = 'production'
}

export type DeviceTypeGroup = {
  __typename?: 'DeviceTypeGroup';
  createdAt: Scalars['ISO8601DateTime'];
  deviceTypes: Array<Tag>;
  id: Scalars['Int'];
  name: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type DeviceTypeGroupInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  deviceTypes: Array<TagInput>;
};

/** Represents direct upload credentials */
export type DirectUpload = {
  __typename?: 'DirectUpload';
  /** Created blob record ID */
  blobId: Scalars['ID'];
  /** HTTP request headers (JSON-encoded) */
  headers: Scalars['String'];
  /** Created blob record signed ID */
  signedBlobId: Scalars['ID'];
  /** Upload URL */
  url: Scalars['String'];
};

export enum DogStatus {
  Active = 'active',
  Inactive = 'inactive'
}

export type FileUrl = {
  __typename?: 'FileUrl';
  name: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type Filter = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<DogStatus>;
  ownerId?: Maybe<Scalars['String']>;
  appType?: Maybe<Scalars['String']>;
  operatingSystem?: Maybe<Os>;
  deploymentType?: Maybe<Deployment>;
  product?: Maybe<Scalars['String']>;
  productType?: Maybe<Product>;
  jicCode?: Maybe<JicCode>;
  countryCode?: Maybe<Scalars['String']>;
};

export type FiltersInput = {
  marketViewEntities?: Maybe<Array<MarketViewEntityInput>>;
  os?: Maybe<Array<Scalars['String']>>;
  region?: Maybe<Array<Scalars['String']>>;
  products?: Maybe<Array<Scalars['String']>>;
  daterange?: Maybe<Array<Scalars['String']>>;
  deviceGroups?: Maybe<Array<ReportDeviceTypeGroupInput>>;
  status?: Maybe<Array<DogStatus>>;
};

export enum Group {
  All = 'ALL',
  Standard = 'Standard',
  Superusers = 'Superusers',
  Admins = 'Admins'
}

export type GroupUser = {
  __typename?: 'GroupUser';
  total?: Maybe<Scalars['Int']>;
  users: UsersConnection;
};


export type GroupUserUsersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type Integration = {
  __typename?: 'Integration';
  appId?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  assignedUser?: Maybe<AssignedUser>;
  c6?: Maybe<Scalars['String']>;
  certificationGroup?: Maybe<CertificationGroup>;
  certificationState?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  deploymentName?: Maybe<Scalars['String']>;
  deploymentState?: Maybe<Scalars['String']>;
  deviceTypes?: Maybe<Array<Tag>>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  individualState?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  jicCode?: Maybe<Scalars['String']>;
  marketViewEntity?: Maybe<MarketViewEntity>;
  name?: Maybe<Scalars['String']>;
  os?: Maybe<Scalars['String']>;
  parentName?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Scalars['String']>>;
  recertifiable: Recertifiability;
  region?: Maybe<Scalars['String']>;
  sdkVersion?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Tag>>;
  updatedAt: Scalars['ISO8601DateTime'];
  workflows?: Maybe<Array<Workflow>>;
};

/** The connection type for Integration. */
export type IntegrationConnection = {
  __typename?: 'IntegrationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IntegrationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Integration>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type IntegrationEdge = {
  __typename?: 'IntegrationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Integration>;
};

export type IntegrationInput = {
  id: Scalars['Int'];
};

export enum JicCode {
  Barc = 'BARC',
  Nrsc = 'NRSC',
  Agf = 'AGF'
}


export type MarketViewEntity = {
  __typename?: 'MarketViewEntity';
  c6?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  level?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentName?: Maybe<Scalars['String']>;
  parentTreeId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type MarketViewEntityInput = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  parentTreeId?: Maybe<Scalars['Int']>;
  parentName?: Maybe<Scalars['String']>;
};

export type MarketViewEntityParentFilter = {
  __typename?: 'MarketViewEntityParentFilter';
  id: Scalars['Int'];
  name: Scalars['String'];
  selection: Array<Scalars['Int']>;
};

export type MarketViewEntityParentFilterInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
  selection: Array<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addIntegrationsToCertificationGroup?: Maybe<AddIntegrationsToCertificationGroupMutationPayload>;
  /** Attach Signed blob id to workflow state */
  attachWorkflowStateFile?: Maybe<AttachWorkflowStateFileMutationPayload>;
  createCertificationGroup?: Maybe<CreateCertificationGroupMutationPayload>;
  createComment?: Maybe<CreateCommentMutationPayload>;
  createCompleteRecertification?: Maybe<CreateCompleteRecertificationMutationPayload>;
  createDeviceTypeGroup?: Maybe<CreateDeviceTypeGroupMutationPayload>;
  createDirectUpload?: Maybe<CreateDirectUploadMutationPayload>;
  createIntegration?: Maybe<CreateIntegrationMutationPayload>;
  createRecertWorkflow?: Maybe<CreateRecertWorkflowMutationPayload>;
  createReportFilters?: Maybe<CreateReportFiltersMutationPayload>;
  createWorkflow?: Maybe<CreateWorkflowMutationPayload>;
  deleteCertificationGroup?: Maybe<DeleteCertificationGroupMutationPayload>;
  deleteIntegration?: Maybe<DeleteIntegrationMutationPayload>;
  deleteWorkflowStateFile?: Maybe<DeleteWorkflowStateFileMutationPayload>;
  deleteWorkflowStateLink?: Maybe<DeleteWorkflowStateLinkMutationPayload>;
  removeIntegrationsFromCertificationGroup?: Maybe<RemoveIntegrationsFromCertificationGroupMutationPayload>;
  updateAssignedUser?: Maybe<UpdateAssignedUserMutationPayload>;
  updateCertificationGroup?: Maybe<UpdateCertificationGroupMutationPayload>;
  updateComment?: Maybe<UpdateCommentMutationPayload>;
  updateDcrStreamType?: Maybe<UpdateDcrStreamTypeMutationPayload>;
  updateDeviceTypeGroup?: Maybe<UpdateDeviceTypeGroupMutationPayload>;
  updateIntegration?: Maybe<UpdateIntegrationMutationPayload>;
  updateReportFilters?: Maybe<UpdateReportFiltersMutationPayload>;
  updateWorkflow?: Maybe<UpdateWorkflowMutationPayload>;
  updateWorkflowState?: Maybe<UpdateWorkflowStateMutationPayload>;
  updateWorkflowStateLink?: Maybe<UpdateWorkflowStateLinkMutationPayload>;
  workflowTransition?: Maybe<WorkflowTransitionMutationPayload>;
};


export type MutationAddIntegrationsToCertificationGroupArgs = {
  input: AddIntegrationsToCertificationGroupMutationInput;
};


export type MutationAttachWorkflowStateFileArgs = {
  input: AttachWorkflowStateFileMutationInput;
};


export type MutationCreateCertificationGroupArgs = {
  input: CreateCertificationGroupMutationInput;
};


export type MutationCreateCommentArgs = {
  input: CreateCommentMutationInput;
};


export type MutationCreateCompleteRecertificationArgs = {
  input: CreateCompleteRecertificationMutationInput;
};


export type MutationCreateDeviceTypeGroupArgs = {
  input: CreateDeviceTypeGroupMutationInput;
};


export type MutationCreateDirectUploadArgs = {
  filename: Scalars['String'];
  byteSize: Scalars['Int'];
  checksum: Scalars['String'];
  contentType: Scalars['String'];
};


export type MutationCreateIntegrationArgs = {
  input: CreateIntegrationMutationInput;
};


export type MutationCreateRecertWorkflowArgs = {
  input: CreateRecertWorkflowMutationInput;
};


export type MutationCreateReportFiltersArgs = {
  input: CreateReportFiltersMutationInput;
};


export type MutationCreateWorkflowArgs = {
  input: CreateWorkflowMutationInput;
};


export type MutationDeleteCertificationGroupArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteIntegrationArgs = {
  input: DeleteIntegrationMutationInput;
};


export type MutationDeleteWorkflowStateFileArgs = {
  input: DeleteWorkflowStateFileMutationInput;
};


export type MutationDeleteWorkflowStateLinkArgs = {
  input: DeleteWorkflowStateLinkMutationInput;
};


export type MutationRemoveIntegrationsFromCertificationGroupArgs = {
  input: RemoveIntegrationsFromCertificationGroupMutationInput;
};


export type MutationUpdateAssignedUserArgs = {
  input: UpdateAssignedUserMutationInput;
};


export type MutationUpdateCertificationGroupArgs = {
  input: UpdateCertificationGroupMutationInput;
};


export type MutationUpdateCommentArgs = {
  input: UpdateCommentMutationInput;
};


export type MutationUpdateDcrStreamTypeArgs = {
  input: UpdateDcrStreamTypeMutationInput;
};


export type MutationUpdateDeviceTypeGroupArgs = {
  input: UpdateDeviceTypeGroupMutationInput;
};


export type MutationUpdateIntegrationArgs = {
  input: UpdateIntegrationMutationInput;
};


export type MutationUpdateReportFiltersArgs = {
  input: UpdateReportFiltersMutationInput;
};


export type MutationUpdateWorkflowArgs = {
  input: UpdateWorkflowMutationInput;
};


export type MutationUpdateWorkflowStateArgs = {
  input: UpdateWorkflowStateMutationInput;
};


export type MutationUpdateWorkflowStateLinkArgs = {
  input: UpdateWorkflowStateLinkMutationInput;
};


export type MutationWorkflowTransitionArgs = {
  input: WorkflowTransitionMutationInput;
};

export enum Os {
  Android = 'android',
  Cloud = 'cloud',
  Ios = 'ios',
  Browser = 'browser',
  Na = 'na'
}

export type Owner = {
  __typename?: 'Owner';
  activeAppsCount?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  publisherType?: Maybe<Scalars['String']>;
  rowVersion?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type Parent = {
  __typename?: 'Parent';
  adSupported?: Maybe<Scalars['String']>;
  defaultCid?: Maybe<DefaultCid>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export enum Product {
  DcrStatic = 'dcr_static',
  DcrVideo = 'dcr_video',
  Dtvr = 'dtvr',
  Dar = 'dar',
  Da = 'da'
}

export type Query = {
  __typename?: 'Query';
  fetchAllUsersForHierarchy: Array<UserAssignment>;
  fetchCatSessions: Array<TestSession>;
  fetchCertificationGroup: CertificationGroup;
  fetchCertificationGroups: Array<CertificationGroup>;
  fetchCertifiedGroupWorkflows: Array<Workflow>;
  fetchComments: Array<Comment>;
  fetchDcrStreamTypes: Array<Tag>;
  fetchDeviceTypeGroups: Array<DeviceTypeGroup>;
  fetchDogApplication: Application;
  fetchDogApplications: Array<Application>;
  fetchDogCountries: Array<Country>;
  fetchDogSearch: Array<Application>;
  fetchFilters: Array<ReportFilter>;
  fetchGroupUsers: Array<GroupUser>;
  fetchHenAssignedUsers: Array<UserAssignment>;
  fetchIntegration: Integration;
  fetchIntegrations: IntegrationConnection;
  fetchIntegrationsByAppId: Array<Integration>;
  fetchOwlBrand: Brand;
  fetchOwlC6s: Array<C6s>;
  fetchOwlChannel: Channel;
  fetchOwlExcerpt: TreeNode;
  fetchOwlFind?: Maybe<Array<TreeNode>>;
  fetchOwlParent: Parent;
  fetchOwlTreeNodes: Array<TreeNode>;
  fetchReports: Scalars['Json'];
  fetchSearchResults: Array<Integration>;
  fetchTags: Array<Tag>;
  fetchToken: Scalars['String'];
  fetchTransitions: Array<Scalars['Json']>;
  fetchWorkflow: Workflow;
  fetchWorkflowFileUrl: FileUrl;
  fetchWorkflowLogs: Array<Version>;
  fetchWorkflowStateLink: WorkflowStateLink;
  fetchWorkflows: Array<Workflow>;
};


export type QueryFetchAllUsersForHierarchyArgs = {
  marketViewEntity: MarketViewEntityInput;
  startFromEntity?: Maybe<Scalars['Boolean']>;
};


export type QueryFetchCatSessionsArgs = {
  appId: Scalars['String'];
};


export type QueryFetchCertificationGroupArgs = {
  id: Scalars['Int'];
};


export type QueryFetchCertificationGroupsArgs = {
  searchStr?: Maybe<Scalars['String']>;
  osType?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  assignedUser?: Maybe<AssignedUserInput>;
  marketViewEntity?: Maybe<MarketViewEntityInput>;
};


export type QueryFetchCertifiedGroupWorkflowsArgs = {
  id: Scalars['Int'];
};


export type QueryFetchCommentsArgs = {
  workflowStateId?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
};


export type QueryFetchDeviceTypeGroupsArgs = {
  name: Scalars['String'];
};


export type QueryFetchDogApplicationArgs = {
  id: Scalars['String'];
};


export type QueryFetchDogApplicationsArgs = {
  clientId: Scalars['String'];
  vcId: Scalars['String'];
};


export type QueryFetchDogSearchArgs = {
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Filter>;
};


export type QueryFetchFiltersArgs = {
  currentUser: Scalars['Boolean'];
};


export type QueryFetchGroupUsersArgs = {
  perPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  groupType?: Maybe<Group>;
};


export type QueryFetchHenAssignedUsersArgs = {
  marketViewEntity: MarketViewEntityInput;
  filterOut?: Maybe<AssignedUserInput>;
};


export type QueryFetchIntegrationArgs = {
  integrationId: Scalars['Int'];
};


export type QueryFetchIntegrationsArgs = {
  searchStr?: Maybe<Scalars['String']>;
  osType?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  jicCode?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  deviceTypeGroupId?: Maybe<Scalars['Int']>;
  withCommonParent?: Maybe<MarketViewEntityInput>;
  status?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryFetchIntegrationsByAppIdArgs = {
  appId: Scalars['String'];
};


export type QueryFetchOwlBrandArgs = {
  id: Scalars['Int'];
};


export type QueryFetchOwlC6sArgs = {
  id: Scalars['Int'];
};


export type QueryFetchOwlChannelArgs = {
  id: Scalars['Int'];
};


export type QueryFetchOwlExcerptArgs = {
  id: Scalars['Int'];
  q?: Maybe<Scalars['String']>;
  type?: Maybe<Scope>;
  status?: Maybe<Array<Status>>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryFetchOwlFindArgs = {
  id: Scalars['Int'];
};


export type QueryFetchOwlParentArgs = {
  id: Scalars['Int'];
};


export type QueryFetchOwlTreeNodesArgs = {
  q?: Maybe<Scalars['String']>;
  type?: Maybe<Array<Scope>>;
  status?: Maybe<Array<Status>>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryFetchReportsArgs = {
  filters: FiltersInput;
};


export type QueryFetchSearchResultsArgs = {
  searchStr: Scalars['String'];
};


export type QueryFetchTagsArgs = {
  name?: Maybe<Scalars['String']>;
  kind?: Maybe<TagKind>;
};


export type QueryFetchTransitionsArgs = {
  workflowId: Scalars['Int'];
};


export type QueryFetchWorkflowArgs = {
  workflowId: Scalars['Int'];
};


export type QueryFetchWorkflowFileUrlArgs = {
  workflowStateId: Scalars['ID'];
  attachmentId: Scalars['ID'];
};


export type QueryFetchWorkflowLogsArgs = {
  integrationId: Scalars['Int'];
  product: Scalars['String'];
};


export type QueryFetchWorkflowStateLinkArgs = {
  workflowStateLinkId: Scalars['Int'];
};


export type QueryFetchWorkflowsArgs = {
  integrationId: Scalars['Int'];
  product?: Maybe<Scalars['String']>;
};

export enum Recertifiability {
  /** Integration is recertifiable */
  Recertifiable = 'recertifiable',
  /** Integration is recertifiable, but group is not yet */
  GroupNotYet = 'group_not_yet',
  /** Integration is not */
  NotRecertifiable = 'not_recertifiable'
}

/** Autogenerated input type of RemoveIntegrationsFromCertificationGroupMutation */
export type RemoveIntegrationsFromCertificationGroupMutationInput = {
  /** id of the certification group */
  id: Scalars['Int'];
  /** Integrations to be removed from group */
  integrations: Array<IntegrationInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RemoveIntegrationsFromCertificationGroupMutation */
export type RemoveIntegrationsFromCertificationGroupMutationPayload = {
  __typename?: 'RemoveIntegrationsFromCertificationGroupMutationPayload';
  certificationGroup?: Maybe<CertificationGroup>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  status: Scalars['String'];
};

export type ReportDeviceTypeGroupInput = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  deviceTypes?: Maybe<Array<TagInput>>;
};

export type ReportFilter = {
  __typename?: 'ReportFilter';
  createdAt: Scalars['ISO8601DateTime'];
  email?: Maybe<Scalars['String']>;
  filters?: Maybe<SavedFilter>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ReportFilterInput = {
  name: Scalars['String'];
  filters?: Maybe<SavedFiltersInput>;
};

export type SavedFilter = {
  __typename?: 'SavedFilter';
  daterange?: Maybe<SavedFilterDaterange>;
  deviceGroups?: Maybe<Array<DeviceTypeGroup>>;
  marketViewEntities?: Maybe<Array<MarketViewEntityParentFilter>>;
  os?: Maybe<Array<Scalars['String']>>;
  products?: Maybe<Array<Scalars['String']>>;
  region?: Maybe<Array<Scalars['String']>>;
  selectedParents?: Maybe<Array<Scalars['Int']>>;
  status?: Maybe<Array<Scalars['String']>>;
};

export type SavedFilterDaterange = {
  __typename?: 'SavedFilterDaterange';
  endDate: Scalars['String'];
  name: Scalars['String'];
  startDate: Scalars['String'];
};

export type SavedFilterDaterangeInput = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  name: Scalars['String'];
};

export type SavedFiltersInput = {
  marketViewEntities?: Maybe<Array<MarketViewEntityParentFilterInput>>;
  os?: Maybe<Array<Scalars['String']>>;
  region?: Maybe<Array<Scalars['String']>>;
  products?: Maybe<Array<Scalars['String']>>;
  daterange?: Maybe<SavedFilterDaterangeInput>;
  deviceGroups?: Maybe<Array<ReportDeviceTypeGroupInput>>;
  status?: Maybe<Array<DogStatus>>;
};

export enum Scope {
  Parent = 'parent',
  Brand = 'brand',
  Channel = 'channel'
}

export enum Status {
  Active = 'active',
  Archived = 'archived',
  Legacy = 'legacy',
  Waiting = 'waiting',
  Rejected = 'rejected',
  FutureDated = 'future_dated',
  ChangeRequest = 'change_request',
  Orphaned = 'orphaned',
  UpdatesRequired = 'updates_required'
}

export type SubCategory = {
  __typename?: 'SubCategory';
  category?: Maybe<Category>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type Tag = {
  __typename?: 'Tag';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['Int'];
  kind: TagKind;
  name: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type TagInput = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export enum TagKind {
  /** device_type */
  DeviceType = 'device_type',
  /** freeform */
  Freeform = 'freeform',
  /** dcr_stream_type */
  DcrStreamType = 'dcr_stream_type'
}

export type TestSession = {
  __typename?: 'TestSession';
  appId?: Maybe<Scalars['String']>;
  catId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type TreeNode = {
  __typename?: 'TreeNode';
  ancestor?: Maybe<TreeNode>;
  c6s?: Maybe<Array<C6s>>;
  children?: Maybe<Array<TreeNode>>;
  id: Scalars['Int'];
  name: Scalars['String'];
  type: Scalars['String'];
};

/** Autogenerated input type of UpdateAssignedUserMutation */
export type UpdateAssignedUserMutationInput = {
  workflowStateId: Scalars['ID'];
  assignee: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateAssignedUserMutation */
export type UpdateAssignedUserMutationPayload = {
  __typename?: 'UpdateAssignedUserMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  status: Scalars['String'];
  workflowState?: Maybe<WorkflowState>;
};

/** Autogenerated input type of UpdateCertificationGroupMutation */
export type UpdateCertificationGroupMutationInput = {
  /** id of the certification group */
  id: Scalars['Int'];
  /** Percentage of groups that need to be certified */
  name?: Maybe<Scalars['String']>;
  /** Percentage of groups that need to be certified */
  ratio?: Maybe<Scalars['Float']>;
  /** Optional Description */
  description?: Maybe<Scalars['String']>;
  /** Optional integrations to be removed from gropu */
  removeIntegrations?: Maybe<Array<IntegrationInput>>;
  /** Optional integrations to be added to group */
  addIntegrations?: Maybe<Array<IntegrationInput>>;
  /** Nielsen contact for this group */
  contact?: Maybe<AssignedUserInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateCertificationGroupMutation */
export type UpdateCertificationGroupMutationPayload = {
  __typename?: 'UpdateCertificationGroupMutationPayload';
  certificationGroup?: Maybe<CertificationGroup>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  status: Scalars['String'];
};

/** Autogenerated input type of UpdateCommentMutation */
export type UpdateCommentMutationInput = {
  id: Scalars['Int'];
  content: Scalars['String'];
  workflowStateId: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateCommentMutation */
export type UpdateCommentMutationPayload = {
  __typename?: 'UpdateCommentMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  comment?: Maybe<Comment>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateDcrStreamTypeMutation */
export type UpdateDcrStreamTypeMutationInput = {
  integrationId: Scalars['Int'];
  dcrStreamTypeId?: Maybe<Scalars['Int']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateDcrStreamTypeMutation */
export type UpdateDcrStreamTypeMutationPayload = {
  __typename?: 'UpdateDcrStreamTypeMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  integration?: Maybe<Integration>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateDeviceTypeGroupMutation */
export type UpdateDeviceTypeGroupMutationInput = {
  deviceGroup: ReportDeviceTypeGroupInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateDeviceTypeGroupMutation */
export type UpdateDeviceTypeGroupMutationPayload = {
  __typename?: 'UpdateDeviceTypeGroupMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  group?: Maybe<DeviceTypeGroup>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateIntegrationMutation */
export type UpdateIntegrationMutationInput = {
  integrationId: Scalars['Int'];
  deviceTypeId?: Maybe<Scalars['Int']>;
  dcrStreamTypeId?: Maybe<Scalars['Int']>;
  assignedUser?: Maybe<AssignedUserInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateIntegrationMutation */
export type UpdateIntegrationMutationPayload = {
  __typename?: 'UpdateIntegrationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  integration?: Maybe<Integration>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type UpdateReportFilterInput = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  filters?: Maybe<SavedFiltersInput>;
};

/** Autogenerated input type of UpdateReportFiltersMutation */
export type UpdateReportFiltersMutationInput = {
  data?: Maybe<UpdateReportFilterInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateReportFiltersMutation */
export type UpdateReportFiltersMutationPayload = {
  __typename?: 'UpdateReportFiltersMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  filters?: Maybe<ReportFilter>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateWorkflowMutation */
export type UpdateWorkflowMutationInput = {
  workflowId: Scalars['ID'];
  stateType: Scalars['String'];
  data?: Maybe<Scalars['Json']>;
  assignee?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateWorkflowMutation */
export type UpdateWorkflowMutationPayload = {
  __typename?: 'UpdateWorkflowMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  status: Scalars['String'];
  workflow?: Maybe<Workflow>;
};

/** Autogenerated input type of UpdateWorkflowStateLinkMutation */
export type UpdateWorkflowStateLinkMutationInput = {
  id?: Maybe<Scalars['ID']>;
  workflowStateId: Scalars['ID'];
  link: Scalars['String'];
  linkType: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateWorkflowStateLinkMutation */
export type UpdateWorkflowStateLinkMutationPayload = {
  __typename?: 'UpdateWorkflowStateLinkMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  status: Scalars['String'];
  workflowStateLink?: Maybe<WorkflowStateLink>;
};

/** Autogenerated input type of UpdateWorkflowStateMutation */
export type UpdateWorkflowStateMutationInput = {
  workflowStateId: Scalars['ID'];
  data?: Maybe<Scalars['Json']>;
  assignee?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateWorkflowStateMutation */
export type UpdateWorkflowStateMutationPayload = {
  __typename?: 'UpdateWorkflowStateMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  status: Scalars['String'];
  workflowState?: Maybe<WorkflowState>;
};

export type User = {
  __typename?: 'User';
  avatarUrl?: Maybe<Scalars['String']>;
  centrifugoToken?: Maybe<Scalars['String']>;
  comments?: Maybe<Array<Comment>>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  integrations?: Maybe<Array<Integration>>;
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Scalars['String']>>;
  role?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type UserAssignment = {
  __typename?: 'UserAssignment';
  entity: MarketViewEntity;
  user: AssignedUser;
};

export type Users = {
  __typename?: 'Users';
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** The connection type for Users. */
export type UsersConnection = {
  __typename?: 'UsersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UsersEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Users>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type UsersEdge = {
  __typename?: 'UsersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Users>;
};

export type Version = {
  __typename?: 'Version';
  createdAt: Scalars['ISO8601DateTime'];
  event?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  itemId?: Maybe<Scalars['Int']>;
  itemSubtype?: Maybe<Scalars['String']>;
  itemType?: Maybe<Scalars['String']>;
  objectChanges?: Maybe<Scalars['Json']>;
  whodunnit?: Maybe<Scalars['String']>;
};

export type Workflow = {
  __typename?: 'Workflow';
  createdAt: Scalars['ISO8601DateTime'];
  currentState?: Maybe<WorkflowState>;
  id: Scalars['Int'];
  integrationId?: Maybe<Scalars['Int']>;
  product?: Maybe<Scalars['String']>;
  recertReference?: Maybe<Scalars['Int']>;
  smState?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  workflowStates?: Maybe<Array<WorkflowState>>;
};

export type WorkflowState = {
  __typename?: 'WorkflowState';
  assignee?: Maybe<Scalars['String']>;
  comments?: Maybe<Array<Comment>>;
  createdAt: Scalars['ISO8601DateTime'];
  fields?: Maybe<Scalars['Json']>;
  files?: Maybe<Scalars['Json']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  stateType?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  validation?: Maybe<Scalars['Json']>;
  workflow: Workflow;
  workflowStateLinks?: Maybe<Array<WorkflowStateLink>>;
};

export type WorkflowStateLink = {
  __typename?: 'WorkflowStateLink';
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  link?: Maybe<Scalars['String']>;
  linkType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  workflowState?: Maybe<WorkflowState>;
  workflowStateId?: Maybe<Scalars['Int']>;
};

/** Autogenerated input type of WorkflowTransitionMutation */
export type WorkflowTransitionMutationInput = {
  workflowId: Scalars['ID'];
  stateType: Scalars['Json'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of WorkflowTransitionMutation */
export type WorkflowTransitionMutationPayload = {
  __typename?: 'WorkflowTransitionMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  status: Scalars['String'];
  workflow?: Maybe<Workflow>;
};

export type AddIntegrationsToCertificationGroupMutationVariables = Exact<{
  id: Scalars['Int'];
  integrations: Array<IntegrationInput> | IntegrationInput;
}>;


export type AddIntegrationsToCertificationGroupMutation = (
  { __typename?: 'Mutation' }
  & { addIntegrationsToCertificationGroup?: Maybe<(
    { __typename?: 'AddIntegrationsToCertificationGroupMutationPayload' }
    & Pick<AddIntegrationsToCertificationGroupMutationPayload, 'message' | 'status'>
    & { certificationGroup?: Maybe<(
      { __typename?: 'CertificationGroup' }
      & Pick<CertificationGroup, 'id' | 'name' | 'description' | 'ratio' | 'certificationState'>
      & { marketViewEntity?: Maybe<(
        { __typename?: 'MarketViewEntity' }
        & Pick<MarketViewEntity, 'id'>
      )>, integrations: Array<(
        { __typename?: 'Integration' }
        & Pick<Integration, 'id' | 'os' | 'region' | 'name' | 'appId' | 'parentName' | 'c6' | 'status' | 'isActive' | 'clientId' | 'createdAt' | 'updatedAt' | 'individualState'>
        & { tags?: Maybe<Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'name' | 'kind'>
        )>>, marketViewEntity?: Maybe<(
          { __typename?: 'MarketViewEntity' }
          & Pick<MarketViewEntity, 'id' | 'parentName' | 'c6' | 'clientId' | 'createdAt' | 'updatedAt'>
        )>, workflows?: Maybe<Array<(
          { __typename?: 'Workflow' }
          & Pick<Workflow, 'id' | 'product' | 'smState'>
        )>> }
      )>, contact?: Maybe<(
        { __typename?: 'AssignedUser' }
        & Pick<AssignedUser, 'id' | 'name' | 'email'>
      )> }
    )> }
  )> }
);

export type AttachWorkflowStateFileMutationMutationVariables = Exact<{
  signedBlobId: Scalars['String'];
  workflowStateId: Scalars['ID'];
  blobId: Scalars['Int'];
}>;


export type AttachWorkflowStateFileMutationMutation = (
  { __typename?: 'Mutation' }
  & { attachWorkflowStateFile?: Maybe<(
    { __typename?: 'AttachWorkflowStateFileMutationPayload' }
    & Pick<AttachWorkflowStateFileMutationPayload, 'attachmentId' | 'message' | 'status'>
  )> }
);

export type CreateCertificationGroupMutationVariables = Exact<{
  name: Scalars['String'];
  ratio?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  integrations?: Maybe<Array<IntegrationInput> | IntegrationInput>;
  marketViewEntity?: Maybe<MarketViewEntityInput>;
}>;


export type CreateCertificationGroupMutation = (
  { __typename?: 'Mutation' }
  & { createCertificationGroup?: Maybe<(
    { __typename?: 'CreateCertificationGroupMutationPayload' }
    & Pick<CreateCertificationGroupMutationPayload, 'message' | 'status'>
    & { certificationGroup?: Maybe<(
      { __typename?: 'CertificationGroup' }
      & Pick<CertificationGroup, 'id' | 'name' | 'description' | 'ratio' | 'certificationState'>
      & { marketViewEntity?: Maybe<(
        { __typename?: 'MarketViewEntity' }
        & Pick<MarketViewEntity, 'id'>
      )>, integrations: Array<(
        { __typename?: 'Integration' }
        & Pick<Integration, 'id' | 'os' | 'region' | 'name' | 'appId' | 'parentName' | 'c6' | 'status' | 'isActive' | 'clientId' | 'createdAt' | 'updatedAt' | 'certificationState' | 'individualState'>
        & { tags?: Maybe<Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'name' | 'kind'>
        )>>, marketViewEntity?: Maybe<(
          { __typename?: 'MarketViewEntity' }
          & Pick<MarketViewEntity, 'id' | 'parentName' | 'c6' | 'clientId' | 'createdAt' | 'updatedAt'>
        )>, workflows?: Maybe<Array<(
          { __typename?: 'Workflow' }
          & Pick<Workflow, 'id' | 'product' | 'smState'>
        )>> }
      )>, contact?: Maybe<(
        { __typename?: 'AssignedUser' }
        & Pick<AssignedUser, 'id' | 'name' | 'email'>
      )> }
    )> }
  )> }
);

export type CreateCommentMutationVariables = Exact<{
  content: Scalars['String'];
  workflowStateId: Scalars['Int'];
}>;


export type CreateCommentMutation = (
  { __typename?: 'Mutation' }
  & { createComment?: Maybe<(
    { __typename?: 'CreateCommentMutationPayload' }
    & Pick<CreateCommentMutationPayload, 'clientMutationId' | 'message' | 'status'>
    & { comment?: Maybe<(
      { __typename?: 'Comment' }
      & Pick<Comment, 'id' | 'content' | 'createdAt' | 'updatedAt' | 'email' | 'isEditable'>
    )> }
  )> }
);

export type StartCompleteRecertificationMutationVariables = Exact<{
  integrations: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type StartCompleteRecertificationMutation = (
  { __typename?: 'Mutation' }
  & { createCompleteRecertification?: Maybe<(
    { __typename?: 'CreateCompleteRecertificationMutationPayload' }
    & Pick<CreateCompleteRecertificationMutationPayload, 'message' | 'status'>
  )> }
);

export type CreateCustomDeviceGroupMutationVariables = Exact<{
  name: Scalars['String'];
  devices: Array<TagInput> | TagInput;
}>;


export type CreateCustomDeviceGroupMutation = (
  { __typename?: 'Mutation' }
  & { createDeviceTypeGroup?: Maybe<(
    { __typename?: 'CreateDeviceTypeGroupMutationPayload' }
    & { group?: Maybe<(
      { __typename?: 'DeviceTypeGroup' }
      & Pick<DeviceTypeGroup, 'id' | 'name'>
      & { deviceTypes: Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type CreateDirectUploadMutationMutationVariables = Exact<{
  filename: Scalars['String'];
  byteSize: Scalars['Int'];
  checksum: Scalars['String'];
  contentType: Scalars['String'];
}>;


export type CreateDirectUploadMutationMutation = (
  { __typename?: 'Mutation' }
  & { createDirectUpload?: Maybe<(
    { __typename?: 'CreateDirectUploadMutationPayload' }
    & { directUpload?: Maybe<(
      { __typename?: 'DirectUpload' }
      & Pick<DirectUpload, 'blobId' | 'headers' | 'signedBlobId' | 'url'>
    )> }
  )> }
);

export type CreateIntegrationMutationVariables = Exact<{
  name: Scalars['String'];
  appId: Scalars['String'];
  os: Scalars['String'];
  region: Scalars['String'];
  clientId: Scalars['String'];
  c6: Scalars['String'];
  deviceTypeId?: Maybe<Scalars['Int']>;
  hierarchyEntityName?: Maybe<Scalars['String']>;
  dcrStreamTypeId?: Maybe<Scalars['Int']>;
  assignedUser?: Maybe<AssignedUserInput>;
}>;


export type CreateIntegrationMutation = (
  { __typename?: 'Mutation' }
  & { createIntegration?: Maybe<(
    { __typename?: 'CreateIntegrationMutationPayload' }
    & Pick<CreateIntegrationMutationPayload, 'clientMutationId' | 'message' | 'status'>
    & { integration?: Maybe<(
      { __typename?: 'Integration' }
      & Pick<Integration, 'appId' | 'clientId' | 'id' | 'jicCode' | 'name' | 'os' | 'region' | 'status' | 'isActive' | 'createdAt' | 'updatedAt'>
      & { deviceTypes?: Maybe<Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'id' | 'name' | 'kind' | 'createdAt' | 'updatedAt'>
      )>>, tags?: Maybe<Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'id' | 'name' | 'kind' | 'createdAt' | 'updatedAt'>
      )>>, marketViewEntity?: Maybe<(
        { __typename?: 'MarketViewEntity' }
        & Pick<MarketViewEntity, 'id' | 'parentName' | 'c6' | 'clientId' | 'createdAt' | 'updatedAt'>
      )>, assignedUser?: Maybe<(
        { __typename?: 'AssignedUser' }
        & Pick<AssignedUser, 'id' | 'name' | 'email'>
      )> }
    )> }
  )> }
);

export type StartRecertificationMutationVariables = Exact<{
  integrationId: Scalars['ID'];
  product: Scalars['String'];
  assignee: Scalars['String'];
  data: Scalars['Json'];
}>;


export type StartRecertificationMutation = (
  { __typename?: 'Mutation' }
  & { createRecertWorkflow?: Maybe<(
    { __typename?: 'CreateRecertWorkflowMutationPayload' }
    & Pick<CreateRecertWorkflowMutationPayload, 'clientMutationId' | 'message' | 'status'>
    & { workflow?: Maybe<(
      { __typename?: 'Workflow' }
      & Pick<Workflow, 'createdAt' | 'id' | 'integrationId' | 'product' | 'smState' | 'updatedAt' | 'recertReference'>
      & { workflowStates?: Maybe<Array<(
        { __typename?: 'WorkflowState' }
        & Pick<WorkflowState, 'createdAt' | 'fields' | 'id' | 'name' | 'assignee' | 'validation' | 'stateType' | 'updatedAt'>
        & { workflowStateLinks?: Maybe<Array<(
          { __typename?: 'WorkflowStateLink' }
          & Pick<WorkflowStateLink, 'createdAt' | 'id' | 'link' | 'linkType' | 'updatedAt'>
        )>>, comments?: Maybe<Array<(
          { __typename?: 'Comment' }
          & Pick<Comment, 'id' | 'content' | 'createdAt' | 'updatedAt' | 'email'>
        )>> }
      )>> }
    )> }
  )> }
);

export type CreateReportFiltersMutationVariables = Exact<{
  data: ReportFilterInput;
}>;


export type CreateReportFiltersMutation = (
  { __typename?: 'Mutation' }
  & { createReportFilters?: Maybe<(
    { __typename?: 'CreateReportFiltersMutationPayload' }
    & { filters?: Maybe<(
      { __typename?: 'ReportFilter' }
      & Pick<ReportFilter, 'name' | 'id' | 'email' | 'createdAt' | 'updatedAt'>
      & { filters?: Maybe<(
        { __typename?: 'SavedFilter' }
        & Pick<SavedFilter, 'region' | 'products' | 'os' | 'status'>
        & { deviceGroups?: Maybe<Array<(
          { __typename?: 'DeviceTypeGroup' }
          & Pick<DeviceTypeGroup, 'id'>
        )>>, marketViewEntities?: Maybe<Array<(
          { __typename?: 'MarketViewEntityParentFilter' }
          & Pick<MarketViewEntityParentFilter, 'id' | 'name' | 'selection'>
        )>>, daterange?: Maybe<(
          { __typename?: 'SavedFilterDaterange' }
          & Pick<SavedFilterDaterange, 'name' | 'startDate' | 'endDate'>
        )> }
      )> }
    )> }
  )> }
);

export type StartCertificationMutationVariables = Exact<{
  integrationId: Scalars['Int'];
  product: Scalars['String'];
  assignee: Scalars['String'];
  data: Scalars['Json'];
}>;


export type StartCertificationMutation = (
  { __typename?: 'Mutation' }
  & { createWorkflow?: Maybe<(
    { __typename?: 'CreateWorkflowMutationPayload' }
    & Pick<CreateWorkflowMutationPayload, 'clientMutationId' | 'message' | 'status'>
    & { workflow?: Maybe<(
      { __typename?: 'Workflow' }
      & Pick<Workflow, 'createdAt' | 'id' | 'integrationId' | 'product' | 'smState' | 'updatedAt' | 'recertReference'>
      & { workflowStates?: Maybe<Array<(
        { __typename?: 'WorkflowState' }
        & Pick<WorkflowState, 'createdAt' | 'validation' | 'fields' | 'id' | 'name' | 'assignee' | 'stateType' | 'updatedAt'>
        & { workflowStateLinks?: Maybe<Array<(
          { __typename?: 'WorkflowStateLink' }
          & Pick<WorkflowStateLink, 'createdAt' | 'id' | 'link' | 'linkType' | 'updatedAt'>
        )>>, comments?: Maybe<Array<(
          { __typename?: 'Comment' }
          & Pick<Comment, 'id' | 'content' | 'createdAt' | 'updatedAt' | 'email'>
        )>> }
      )>> }
    )> }
  )> }
);

export type RemoveIntegrationsFromCertificationGroupMutationVariables = Exact<{
  id: Scalars['Int'];
  integrations: Array<IntegrationInput> | IntegrationInput;
}>;


export type RemoveIntegrationsFromCertificationGroupMutation = (
  { __typename?: 'Mutation' }
  & { removeIntegrationsFromCertificationGroup?: Maybe<(
    { __typename?: 'RemoveIntegrationsFromCertificationGroupMutationPayload' }
    & Pick<RemoveIntegrationsFromCertificationGroupMutationPayload, 'message' | 'status'>
    & { certificationGroup?: Maybe<(
      { __typename?: 'CertificationGroup' }
      & Pick<CertificationGroup, 'id' | 'name' | 'description' | 'ratio' | 'certificationState'>
      & { marketViewEntity?: Maybe<(
        { __typename?: 'MarketViewEntity' }
        & Pick<MarketViewEntity, 'id'>
      )>, integrations: Array<(
        { __typename?: 'Integration' }
        & Pick<Integration, 'id' | 'os' | 'region' | 'name' | 'appId' | 'parentName' | 'c6' | 'status' | 'isActive' | 'clientId' | 'createdAt' | 'updatedAt' | 'individualState'>
        & { tags?: Maybe<Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'name' | 'kind'>
        )>>, marketViewEntity?: Maybe<(
          { __typename?: 'MarketViewEntity' }
          & Pick<MarketViewEntity, 'id' | 'parentName' | 'c6' | 'clientId' | 'createdAt' | 'updatedAt'>
        )>, workflows?: Maybe<Array<(
          { __typename?: 'Workflow' }
          & Pick<Workflow, 'id' | 'product' | 'smState'>
        )>> }
      )>, contact?: Maybe<(
        { __typename?: 'AssignedUser' }
        & Pick<AssignedUser, 'id' | 'name' | 'email'>
      )> }
    )> }
  )> }
);

export type UpdateCertificationGroupMutationVariables = Exact<{
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  ratio?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  removeIntegrations?: Maybe<Array<IntegrationInput> | IntegrationInput>;
  addIntegrations?: Maybe<Array<IntegrationInput> | IntegrationInput>;
}>;


export type UpdateCertificationGroupMutation = (
  { __typename?: 'Mutation' }
  & { updateCertificationGroup?: Maybe<(
    { __typename?: 'UpdateCertificationGroupMutationPayload' }
    & Pick<UpdateCertificationGroupMutationPayload, 'message' | 'status'>
    & { certificationGroup?: Maybe<(
      { __typename?: 'CertificationGroup' }
      & Pick<CertificationGroup, 'id' | 'name' | 'description' | 'ratio' | 'certificationState'>
      & { marketViewEntity?: Maybe<(
        { __typename?: 'MarketViewEntity' }
        & Pick<MarketViewEntity, 'id'>
      )>, integrations: Array<(
        { __typename?: 'Integration' }
        & Pick<Integration, 'id' | 'os' | 'region' | 'name' | 'appId' | 'parentName' | 'c6' | 'status' | 'isActive' | 'clientId' | 'createdAt' | 'updatedAt' | 'individualState'>
        & { tags?: Maybe<Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'name' | 'kind'>
        )>>, marketViewEntity?: Maybe<(
          { __typename?: 'MarketViewEntity' }
          & Pick<MarketViewEntity, 'id' | 'parentName' | 'c6' | 'clientId' | 'createdAt' | 'updatedAt'>
        )>, workflows?: Maybe<Array<(
          { __typename?: 'Workflow' }
          & Pick<Workflow, 'id' | 'product' | 'smState'>
        )>> }
      )>, contact?: Maybe<(
        { __typename?: 'AssignedUser' }
        & Pick<AssignedUser, 'id' | 'name' | 'email'>
      )> }
    )> }
  )> }
);

export type UpdateCommentMutationVariables = Exact<{
  content: Scalars['String'];
  workflowStateId: Scalars['Int'];
  id: Scalars['Int'];
}>;


export type UpdateCommentMutation = (
  { __typename?: 'Mutation' }
  & { updateComment?: Maybe<(
    { __typename?: 'UpdateCommentMutationPayload' }
    & Pick<UpdateCommentMutationPayload, 'clientMutationId' | 'message' | 'status'>
    & { comment?: Maybe<(
      { __typename?: 'Comment' }
      & Pick<Comment, 'id' | 'content' | 'createdAt' | 'updatedAt' | 'email' | 'isEditable'>
    )> }
  )> }
);

export type UpdateIntegrationMutationVariables = Exact<{
  integrationId: Scalars['Int'];
  deviceTypeId?: Maybe<Scalars['Int']>;
  dcrStreamTypeId?: Maybe<Scalars['Int']>;
  assignedUser?: Maybe<AssignedUserInput>;
}>;


export type UpdateIntegrationMutation = (
  { __typename?: 'Mutation' }
  & { updateIntegration?: Maybe<(
    { __typename?: 'UpdateIntegrationMutationPayload' }
    & Pick<UpdateIntegrationMutationPayload, 'clientMutationId' | 'message' | 'status'>
    & { integration?: Maybe<(
      { __typename?: 'Integration' }
      & Pick<Integration, 'appId' | 'clientId' | 'c6' | 'id' | 'name' | 'status' | 'isActive' | 'parentName'>
      & { deviceTypes?: Maybe<Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'id' | 'name' | 'createdAt' | 'updatedAt'>
      )>>, tags?: Maybe<Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'id' | 'name' | 'kind' | 'createdAt' | 'updatedAt'>
      )>>, marketViewEntity?: Maybe<(
        { __typename?: 'MarketViewEntity' }
        & Pick<MarketViewEntity, 'id' | 'parentName' | 'c6' | 'clientId'>
      )>, assignedUser?: Maybe<(
        { __typename?: 'AssignedUser' }
        & Pick<AssignedUser, 'id' | 'name' | 'email'>
      )> }
    )> }
  )> }
);

export type UpdateReportFiltersMutationVariables = Exact<{
  data: UpdateReportFilterInput;
}>;


export type UpdateReportFiltersMutation = (
  { __typename?: 'Mutation' }
  & { updateReportFilters?: Maybe<(
    { __typename?: 'UpdateReportFiltersMutationPayload' }
    & { filters?: Maybe<(
      { __typename?: 'ReportFilter' }
      & Pick<ReportFilter, 'id' | 'name' | 'email' | 'createdAt' | 'updatedAt'>
      & { filters?: Maybe<(
        { __typename?: 'SavedFilter' }
        & Pick<SavedFilter, 'region' | 'products' | 'os' | 'status'>
        & { deviceGroups?: Maybe<Array<(
          { __typename?: 'DeviceTypeGroup' }
          & Pick<DeviceTypeGroup, 'id'>
        )>>, marketViewEntities?: Maybe<Array<(
          { __typename?: 'MarketViewEntityParentFilter' }
          & Pick<MarketViewEntityParentFilter, 'id' | 'selection'>
        )>>, daterange?: Maybe<(
          { __typename?: 'SavedFilterDaterange' }
          & Pick<SavedFilterDaterange, 'name' | 'startDate' | 'endDate'>
        )> }
      )> }
    )> }
  )> }
);

export type UpdateWorkflowStateMutationVariables = Exact<{
  workflowStateId: Scalars['ID'];
  data: Scalars['Json'];
  assignee: Scalars['String'];
}>;


export type UpdateWorkflowStateMutation = (
  { __typename?: 'Mutation' }
  & { updateWorkflowState?: Maybe<(
    { __typename?: 'UpdateWorkflowStateMutationPayload' }
    & Pick<UpdateWorkflowStateMutationPayload, 'clientMutationId' | 'message' | 'status'>
    & { workflowState?: Maybe<(
      { __typename?: 'WorkflowState' }
      & Pick<WorkflowState, 'assignee' | 'createdAt' | 'fields' | 'id' | 'name' | 'stateType' | 'updatedAt' | 'validation'>
      & { workflowStateLinks?: Maybe<Array<(
        { __typename?: 'WorkflowStateLink' }
        & Pick<WorkflowStateLink, 'createdAt' | 'id' | 'link' | 'linkType' | 'updatedAt'>
      )>>, comments?: Maybe<Array<(
        { __typename?: 'Comment' }
        & Pick<Comment, 'id' | 'content' | 'createdAt' | 'updatedAt' | 'email'>
      )>> }
    )> }
  )> }
);

export type UpdateWorkflowStateLinkMutationVariables = Exact<{
  workflowStateId: Scalars['ID'];
  link: Scalars['String'];
  linkType: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
}>;


export type UpdateWorkflowStateLinkMutation = (
  { __typename?: 'Mutation' }
  & { updateWorkflowStateLink?: Maybe<(
    { __typename?: 'UpdateWorkflowStateLinkMutationPayload' }
    & { workflowStateLink?: Maybe<(
      { __typename?: 'WorkflowStateLink' }
      & Pick<WorkflowStateLink, 'id'>
    )> }
  )> }
);

export type WorkflowTransitionMutationVariables = Exact<{
  workflowId: Scalars['ID'];
  stateType: Scalars['Json'];
}>;


export type WorkflowTransitionMutation = (
  { __typename?: 'Mutation' }
  & { workflowTransition?: Maybe<(
    { __typename?: 'WorkflowTransitionMutationPayload' }
    & Pick<WorkflowTransitionMutationPayload, 'clientMutationId' | 'message' | 'status'>
    & { workflow?: Maybe<(
      { __typename?: 'Workflow' }
      & Pick<Workflow, 'createdAt' | 'id' | 'integrationId' | 'product' | 'smState' | 'updatedAt' | 'recertReference'>
      & { workflowStates?: Maybe<Array<(
        { __typename?: 'WorkflowState' }
        & Pick<WorkflowState, 'createdAt' | 'validation' | 'fields' | 'id' | 'name' | 'assignee' | 'stateType' | 'updatedAt'>
        & { workflowStateLinks?: Maybe<Array<(
          { __typename?: 'WorkflowStateLink' }
          & Pick<WorkflowStateLink, 'createdAt' | 'id' | 'link' | 'linkType' | 'updatedAt'>
        )>>, comments?: Maybe<Array<(
          { __typename?: 'Comment' }
          & Pick<Comment, 'id' | 'content' | 'createdAt' | 'updatedAt' | 'email'>
        )>> }
      )>> }
    )> }
  )> }
);

export type FetchAllUsersForHierarchyQueryVariables = Exact<{
  marketViewEntity: MarketViewEntityInput;
  startFromEntity?: Maybe<Scalars['Boolean']>;
}>;


export type FetchAllUsersForHierarchyQuery = (
  { __typename?: 'Query' }
  & { fetchAllUsersForHierarchy: Array<(
    { __typename?: 'UserAssignment' }
    & { user: (
      { __typename?: 'AssignedUser' }
      & Pick<AssignedUser, 'id' | 'name' | 'email'>
    ), entity: (
      { __typename?: 'MarketViewEntity' }
      & Pick<MarketViewEntity, 'id' | 'name' | 'level'>
    ) }
  )> }
);

export type FetchAssignedUsersQueryVariables = Exact<{
  marketViewEntity: MarketViewEntityInput;
  filterOut?: Maybe<AssignedUserInput>;
}>;


export type FetchAssignedUsersQuery = (
  { __typename?: 'Query' }
  & { fetchHenAssignedUsers: Array<(
    { __typename?: 'UserAssignment' }
    & { user: (
      { __typename?: 'AssignedUser' }
      & Pick<AssignedUser, 'id' | 'name' | 'email'>
    ), entity: (
      { __typename?: 'MarketViewEntity' }
      & Pick<MarketViewEntity, 'id' | 'name' | 'level'>
    ) }
  )> }
);

export type FetchCatSessionsQueryVariables = Exact<{
  appId: Scalars['String'];
}>;


export type FetchCatSessionsQuery = (
  { __typename?: 'Query' }
  & { fetchCatSessions: Array<(
    { __typename?: 'TestSession' }
    & Pick<TestSession, 'catId' | 'title' | 'appId' | 'integrationName' | 'createdAt'>
  )> }
);

export type FetchCertificationGroupQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type FetchCertificationGroupQuery = (
  { __typename?: 'Query' }
  & { fetchCertificationGroup: (
    { __typename?: 'CertificationGroup' }
    & Pick<CertificationGroup, 'id' | 'name' | 'description' | 'certificationState' | 'ratio' | 'recertifiable'>
    & { marketViewEntity?: Maybe<(
      { __typename?: 'MarketViewEntity' }
      & Pick<MarketViewEntity, 'id'>
    )>, integrations: Array<(
      { __typename?: 'Integration' }
      & Pick<Integration, 'id' | 'os' | 'region' | 'name' | 'appId' | 'parentName' | 'c6' | 'status' | 'isActive' | 'clientId' | 'createdAt' | 'updatedAt' | 'individualState' | 'recertifiable'>
      & { tags?: Maybe<Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'name' | 'kind'>
      )>>, marketViewEntity?: Maybe<(
        { __typename?: 'MarketViewEntity' }
        & Pick<MarketViewEntity, 'id' | 'parentName' | 'c6' | 'clientId' | 'createdAt' | 'updatedAt'>
      )>, workflows?: Maybe<Array<(
        { __typename?: 'Workflow' }
        & Pick<Workflow, 'id' | 'product' | 'smState'>
      )>> }
    )>, contact?: Maybe<(
      { __typename?: 'AssignedUser' }
      & Pick<AssignedUser, 'id' | 'name' | 'email'>
    )> }
  ) }
);

export type FetchCertificationGroupsQueryVariables = Exact<{
  searchStr: Scalars['String'];
  region?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  osType?: Maybe<Scalars['String']>;
  marketViewEntity?: Maybe<MarketViewEntityInput>;
}>;


export type FetchCertificationGroupsQuery = (
  { __typename?: 'Query' }
  & { fetchCertificationGroups: Array<(
    { __typename?: 'CertificationGroup' }
    & Pick<CertificationGroup, 'id' | 'name' | 'description' | 'certificationState'>
    & { marketViewEntity?: Maybe<(
      { __typename?: 'MarketViewEntity' }
      & Pick<MarketViewEntity, 'name'>
    )>, contact?: Maybe<(
      { __typename?: 'AssignedUser' }
      & Pick<AssignedUser, 'id' | 'name' | 'email'>
    )> }
  )> }
);

export type FetchCertifiedGroupWorkflowsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type FetchCertifiedGroupWorkflowsQuery = (
  { __typename?: 'Query' }
  & { fetchCertifiedGroupWorkflows: Array<(
    { __typename?: 'Workflow' }
    & Pick<Workflow, 'id' | 'integrationId' | 'product'>
  )> }
);

export type FetchDeviceTypeGroupsQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type FetchDeviceTypeGroupsQuery = (
  { __typename?: 'Query' }
  & { fetchDeviceTypeGroups: Array<(
    { __typename?: 'DeviceTypeGroup' }
    & Pick<DeviceTypeGroup, 'id' | 'name'>
    & { deviceTypes: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name'>
    )> }
  )> }
);

export type FetchDogApplicationQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type FetchDogApplicationQuery = (
  { __typename?: 'Query' }
  & { fetchDogApplication: (
    { __typename?: 'Application' }
    & Pick<Application, 'adMeasurement' | 'agfIdentity' | 'appType' | 'applicationTemplateId' | 'cms' | 'countryCode' | 'createdAt' | 'darReport' | 'ddrsEntityId' | 'deploymentType' | 'description' | 'dpr' | 'facebookSuppression' | 'geoFencing' | 'groupName' | 'id' | 'implementationType' | 'jicCode' | 'legacyPing' | 'localTvEligible' | 'marketIntelligence' | 'mocrEntityId' | 'multiLingualCms' | 'name' | 'operatingSystem' | 'ownerType' | 'products' | 'rowVersion' | 'singleConfiguration' | 'status' | 'storeUrl' | 'supportUrl' | 'suspendedProducts' | 'syndicated' | 'updatedAt' | 'userOptOut' | 'vaBeacon' | 'videoCensus' | 'vri' | 'websiteUrl'>
    & { ddrsEntity?: Maybe<(
      { __typename?: 'DdrsEntity' }
      & Pick<DdrsEntity, 'activeLocalDdrsCount' | 'clientId' | 'countryCode' | 'effectiveDate' | 'id' | 'level' | 'name' | 'parent' | 'rowVersion' | 'status' | 'vcId'>
    )>, owner?: Maybe<(
      { __typename?: 'Owner' }
      & Pick<Owner, 'activeAppsCount' | 'countryCode' | 'createdAt' | 'id' | 'name' | 'publisherType' | 'rowVersion' | 'status' | 'updatedAt'>
    )> }
  ) }
);

export type FetchDogApplicationsQueryVariables = Exact<{
  vcId: Scalars['String'];
  clientId: Scalars['String'];
}>;


export type FetchDogApplicationsQuery = (
  { __typename?: 'Query' }
  & { fetchDogApplications: Array<(
    { __typename?: 'Application' }
    & Pick<Application, 'adMeasurement' | 'agfIdentity' | 'appType' | 'applicationTemplateId' | 'cms' | 'countryCode' | 'createdAt' | 'darReport' | 'ddrsEntityId' | 'deploymentType' | 'description' | 'dpr' | 'facebookSuppression' | 'geoFencing' | 'groupName' | 'id' | 'implementationType' | 'jicCode' | 'legacyPing' | 'localTvEligible' | 'marketIntelligence' | 'mocrEntityId' | 'multiLingualCms' | 'name' | 'operatingSystem' | 'ownerType' | 'products' | 'rowVersion' | 'singleConfiguration' | 'status' | 'storeUrl' | 'supportUrl' | 'suspendedProducts' | 'syndicated' | 'updatedAt' | 'userOptOut' | 'vaBeacon' | 'videoCensus' | 'vri' | 'websiteUrl'>
    & { ddrsEntity?: Maybe<(
      { __typename?: 'DdrsEntity' }
      & Pick<DdrsEntity, 'activeLocalDdrsCount' | 'clientId' | 'countryCode' | 'effectiveDate' | 'id' | 'level' | 'name' | 'parent' | 'rowVersion' | 'status' | 'vcId'>
    )>, owner?: Maybe<(
      { __typename?: 'Owner' }
      & Pick<Owner, 'activeAppsCount' | 'countryCode' | 'createdAt' | 'id' | 'name' | 'publisherType' | 'rowVersion' | 'status' | 'updatedAt'>
    )> }
  )> }
);

export type FetchDogCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchDogCountriesQuery = (
  { __typename?: 'Query' }
  & { fetchDogCountries: Array<(
    { __typename?: 'Country' }
    & Pick<Country, 'code' | 'name' | 'primaryTimezone' | 'rowVersion' | 'status'>
  )> }
);

export type FetchDogSearchQueryVariables = Exact<{
  search: Scalars['String'];
  operatingSystem?: Maybe<Os>;
  countryCode?: Maybe<Scalars['String']>;
  deploymentType?: Maybe<Deployment>;
}>;


export type FetchDogSearchQuery = (
  { __typename?: 'Query' }
  & { fetchDogSearch: Array<(
    { __typename?: 'Application' }
    & Pick<Application, 'id' | 'ownerType' | 'name' | 'operatingSystem' | 'countryCode' | 'status' | 'description' | 'products' | 'updatedAt'>
    & { ddrsEntity?: Maybe<(
      { __typename?: 'DdrsEntity' }
      & Pick<DdrsEntity, 'vcId' | 'name' | 'level' | 'clientId' | 'parent' | 'id'>
    )>, owner?: Maybe<(
      { __typename?: 'Owner' }
      & Pick<Owner, 'name'>
    )> }
  )> }
);

export type FetchGroupUsersQueryVariables = Exact<{
  perPage: Scalars['Int'];
  page: Scalars['Int'];
  groupType: Group;
}>;


export type FetchGroupUsersQuery = (
  { __typename?: 'Query' }
  & { fetchGroupUsers: Array<(
    { __typename?: 'GroupUser' }
    & Pick<GroupUser, 'total'>
    & { users: (
      { __typename?: 'UsersConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Users' }
        & Pick<Users, 'email' | 'name'>
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
      ) }
    ) }
  )> }
);

export type FetchIntegrationQueryVariables = Exact<{
  integrationId: Scalars['Int'];
}>;


export type FetchIntegrationQuery = (
  { __typename?: 'Query' }
  & { fetchIntegration: (
    { __typename?: 'Integration' }
    & Pick<Integration, 'appId' | 'clientId' | 'id' | 'jicCode' | 'name' | 'os' | 'region' | 'status' | 'isActive' | 'createdAt' | 'updatedAt' | 'products' | 'individualState' | 'recertifiable' | 'certificationState'>
    & { certificationGroup?: Maybe<(
      { __typename?: 'CertificationGroup' }
      & Pick<CertificationGroup, 'id'>
    )>, deviceTypes?: Maybe<Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'kind' | 'createdAt' | 'updatedAt'>
    )>>, tags?: Maybe<Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'kind' | 'createdAt' | 'updatedAt'>
    )>>, marketViewEntity?: Maybe<(
      { __typename?: 'MarketViewEntity' }
      & Pick<MarketViewEntity, 'id' | 'parentName' | 'c6' | 'clientId' | 'createdAt' | 'updatedAt'>
    )>, assignedUser?: Maybe<(
      { __typename?: 'AssignedUser' }
      & Pick<AssignedUser, 'id' | 'name' | 'email'>
    )> }
  ) }
);

export type FetchIntegrationsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  searchStr: Scalars['String'];
  region?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  osType?: Maybe<Scalars['String']>;
  deviceTypeGroupId?: Maybe<Scalars['Int']>;
  withCommonParent?: Maybe<MarketViewEntityInput>;
  status?: Maybe<Scalars['String']>;
}>;


export type FetchIntegrationsQuery = (
  { __typename?: 'Query' }
  & { fetchIntegrations: (
    { __typename?: 'IntegrationConnection' }
    & Pick<IntegrationConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ), nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Integration' }
      & Pick<Integration, 'appId' | 'clientId' | 'c6' | 'id' | 'jicCode' | 'name' | 'os' | 'region' | 'status' | 'isActive' | 'parentName' | 'individualState' | 'certificationState'>
      & { deviceTypes?: Maybe<Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'id' | 'name' | 'createdAt' | 'updatedAt'>
      )>>, tags?: Maybe<Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'id' | 'name' | 'kind' | 'createdAt' | 'updatedAt'>
      )>>, marketViewEntity?: Maybe<(
        { __typename?: 'MarketViewEntity' }
        & Pick<MarketViewEntity, 'id' | 'parentName' | 'parentTreeId' | 'c6' | 'clientId' | 'createdAt' | 'updatedAt'>
      )>, assignedUser?: Maybe<(
        { __typename?: 'AssignedUser' }
        & Pick<AssignedUser, 'id' | 'name' | 'email'>
      )>, workflows?: Maybe<Array<(
        { __typename?: 'Workflow' }
        & Pick<Workflow, 'id' | 'product' | 'smState'>
      )>> }
    )>>> }
  ) }
);

export type FetchIntegrationsByAppIdQueryVariables = Exact<{
  appId: Scalars['String'];
}>;


export type FetchIntegrationsByAppIdQuery = (
  { __typename?: 'Query' }
  & { fetchIntegrationsByAppId: Array<(
    { __typename?: 'Integration' }
    & Pick<Integration, 'appId' | 'clientId' | 'id' | 'jicCode' | 'name' | 'os' | 'region' | 'status' | 'isActive' | 'parentName' | 'createdAt' | 'updatedAt' | 'recertifiable' | 'email'>
    & { deviceTypes?: Maybe<Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'kind' | 'createdAt' | 'updatedAt'>
    )>>, tags?: Maybe<Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'kind' | 'createdAt' | 'updatedAt'>
    )>>, marketViewEntity?: Maybe<(
      { __typename?: 'MarketViewEntity' }
      & Pick<MarketViewEntity, 'id' | 'parentName' | 'c6' | 'clientId' | 'createdAt' | 'updatedAt'>
    )> }
  )> }
);

export type FetchOwlBrandQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type FetchOwlBrandQuery = (
  { __typename?: 'Query' }
  & { fetchOwlBrand: (
    { __typename?: 'Brand' }
    & Pick<Brand, 'adSupported' | 'id' | 'name' | 'parentId' | 'status' | 'subCategoryId' | 'type'>
    & { parent?: Maybe<(
      { __typename?: 'Parent' }
      & Pick<Parent, 'adSupported' | 'id' | 'name' | 'status' | 'type'>
      & { defaultCid?: Maybe<(
        { __typename?: 'DefaultCid' }
        & Pick<DefaultCid, 'clientId' | 'countryCode' | 'createdAt' | 'mvParentId' | 'status' | 'updatedAt'>
      )> }
    )>, subCategory?: Maybe<(
      { __typename?: 'SubCategory' }
      & Pick<SubCategory, 'createdAt' | 'id' | 'name' | 'status' | 'updatedAt'>
      & { category?: Maybe<(
        { __typename?: 'Category' }
        & Pick<Category, 'createdAt' | 'id' | 'name' | 'status' | 'updatedAt'>
      )> }
    )> }
  ) }
);

export type FetchOwlC6sQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type FetchOwlC6sQuery = (
  { __typename?: 'Query' }
  & { fetchOwlC6s: Array<(
    { __typename?: 'C6s' }
    & Pick<C6s, 'cid' | 'code' | 'effectiveStartDate' | 'name' | 'syndicated' | 'status'>
  )> }
);

export type FetchOwlChannelQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type FetchOwlChannelQuery = (
  { __typename?: 'Query' }
  & { fetchOwlChannel: (
    { __typename?: 'Channel' }
    & Pick<Channel, 'adSupported' | 'brandId' | 'country' | 'id' | 'name' | 'status' | 'subCategoryId' | 'type'>
    & { brand?: Maybe<(
      { __typename?: 'Brand' }
      & Pick<Brand, 'adSupported' | 'id' | 'name' | 'parentId' | 'status' | 'subCategoryId' | 'type'>
      & { parent?: Maybe<(
        { __typename?: 'Parent' }
        & Pick<Parent, 'adSupported' | 'id' | 'name' | 'status' | 'type'>
        & { defaultCid?: Maybe<(
          { __typename?: 'DefaultCid' }
          & Pick<DefaultCid, 'clientId' | 'countryCode' | 'status'>
        )> }
      )>, subCategory?: Maybe<(
        { __typename?: 'SubCategory' }
        & Pick<SubCategory, 'createdAt' | 'id' | 'name' | 'status' | 'updatedAt'>
        & { category?: Maybe<(
          { __typename?: 'Category' }
          & Pick<Category, 'name'>
        )> }
      )> }
    )>, subCategory?: Maybe<(
      { __typename?: 'SubCategory' }
      & Pick<SubCategory, 'createdAt' | 'id' | 'name' | 'status' | 'updatedAt'>
      & { category?: Maybe<(
        { __typename?: 'Category' }
        & Pick<Category, 'createdAt' | 'id' | 'name' | 'status' | 'updatedAt'>
      )> }
    )> }
  ) }
);

export type FetchOwlExcerptQueryVariables = Exact<{
  id: Scalars['Int'];
  q?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
}>;


export type FetchOwlExcerptQuery = (
  { __typename?: 'Query' }
  & { fetchOwlExcerpt: (
    { __typename?: 'TreeNode' }
    & Pick<TreeNode, 'id' | 'name' | 'type'>
    & { children?: Maybe<Array<(
      { __typename?: 'TreeNode' }
      & Pick<TreeNode, 'id' | 'name' | 'type'>
      & { children?: Maybe<Array<(
        { __typename?: 'TreeNode' }
        & Pick<TreeNode, 'id' | 'name' | 'type'>
      )>> }
    )>> }
  ) }
);

export type FetchOwlFindQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type FetchOwlFindQuery = (
  { __typename?: 'Query' }
  & { fetchOwlFind?: Maybe<Array<(
    { __typename?: 'TreeNode' }
    & Pick<TreeNode, 'id' | 'name' | 'type'>
  )>> }
);

export type FetchOwlTreeNodesQueryVariables = Exact<{
  q: Scalars['String'];
  type?: Maybe<Array<Scope> | Scope>;
  status?: Maybe<Array<Status> | Status>;
}>;


export type FetchOwlTreeNodesQuery = (
  { __typename?: 'Query' }
  & { fetchOwlTreeNodes: Array<(
    { __typename?: 'TreeNode' }
    & Pick<TreeNode, 'id' | 'name' | 'type'>
  )> }
);

export type FetchReportQueryVariables = Exact<{
  marketViewEntities?: Maybe<Array<MarketViewEntityInput> | MarketViewEntityInput>;
  os?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  region?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  products?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  daterange?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  deviceGroups?: Maybe<Array<ReportDeviceTypeGroupInput> | ReportDeviceTypeGroupInput>;
  status?: Maybe<Array<DogStatus> | DogStatus>;
}>;


export type FetchReportQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'fetchReports'>
);

export type FetchFiltersQueryVariables = Exact<{
  currentUser: Scalars['Boolean'];
}>;


export type FetchFiltersQuery = (
  { __typename?: 'Query' }
  & { fetchFilters: Array<(
    { __typename?: 'ReportFilter' }
    & Pick<ReportFilter, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'email'>
    & { filters?: Maybe<(
      { __typename?: 'SavedFilter' }
      & Pick<SavedFilter, 'region' | 'products' | 'os' | 'status'>
      & { deviceGroups?: Maybe<Array<(
        { __typename?: 'DeviceTypeGroup' }
        & Pick<DeviceTypeGroup, 'id'>
      )>>, marketViewEntities?: Maybe<Array<(
        { __typename?: 'MarketViewEntityParentFilter' }
        & Pick<MarketViewEntityParentFilter, 'id' | 'name' | 'selection'>
      )>>, daterange?: Maybe<(
        { __typename?: 'SavedFilterDaterange' }
        & Pick<SavedFilterDaterange, 'name' | 'startDate' | 'endDate'>
      )> }
    )> }
  )> }
);

export type FetchTagsQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  kind?: Maybe<TagKind>;
}>;


export type FetchTagsQuery = (
  { __typename?: 'Query' }
  & { fetchTags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'kind' | 'name' | 'updatedAt' | 'createdAt'>
  )> }
);

export type FetchTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchTokenQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'fetchToken'>
);

export type FetchTransitionsQueryVariables = Exact<{
  workflowId: Scalars['Int'];
}>;


export type FetchTransitionsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'fetchTransitions'>
);

export type FetchWorkflowQueryVariables = Exact<{
  workflowId: Scalars['Int'];
}>;


export type FetchWorkflowQuery = (
  { __typename?: 'Query' }
  & { fetchWorkflow: (
    { __typename?: 'Workflow' }
    & Pick<Workflow, 'createdAt' | 'id' | 'integrationId' | 'product' | 'smState' | 'updatedAt' | 'recertReference'>
    & { workflowStates?: Maybe<Array<(
      { __typename?: 'WorkflowState' }
      & Pick<WorkflowState, 'createdAt' | 'validation' | 'fields' | 'id' | 'name' | 'assignee' | 'stateType' | 'updatedAt'>
      & { workflowStateLinks?: Maybe<Array<(
        { __typename?: 'WorkflowStateLink' }
        & Pick<WorkflowStateLink, 'createdAt' | 'id' | 'link' | 'linkType' | 'updatedAt'>
      )>>, comments?: Maybe<Array<(
        { __typename?: 'Comment' }
        & Pick<Comment, 'id' | 'content' | 'createdAt' | 'updatedAt' | 'email'>
      )>> }
    )>> }
  ) }
);

export type FetchWorkflowFileUrlQueryVariables = Exact<{
  workflowStateId: Scalars['ID'];
  attachmentId: Scalars['ID'];
}>;


export type FetchWorkflowFileUrlQuery = (
  { __typename?: 'Query' }
  & { fetchWorkflowFileUrl: (
    { __typename?: 'FileUrl' }
    & Pick<FileUrl, 'url' | 'name'>
  ) }
);

export type FetchWorkflowLogsQueryVariables = Exact<{
  integrationId: Scalars['Int'];
  product: Scalars['String'];
}>;


export type FetchWorkflowLogsQuery = (
  { __typename?: 'Query' }
  & { fetchWorkflowLogs: Array<(
    { __typename?: 'Version' }
    & Pick<Version, 'id' | 'itemType' | 'itemSubtype' | 'event' | 'whodunnit' | 'objectChanges' | 'createdAt'>
  )> }
);

export type FetchWorkflowsQueryVariables = Exact<{
  integrationId: Scalars['Int'];
}>;


export type FetchWorkflowsQuery = (
  { __typename?: 'Query' }
  & { fetchWorkflows: Array<(
    { __typename?: 'Workflow' }
    & Pick<Workflow, 'id' | 'integrationId' | 'product' | 'smState' | 'createdAt' | 'updatedAt' | 'recertReference'>
  )> }
);

export type FetchWorkflowsByProductQueryVariables = Exact<{
  integrationId: Scalars['Int'];
  product?: Maybe<Scalars['String']>;
}>;


export type FetchWorkflowsByProductQuery = (
  { __typename?: 'Query' }
  & { fetchWorkflows: Array<(
    { __typename?: 'Workflow' }
    & Pick<Workflow, 'id' | 'integrationId' | 'product' | 'smState' | 'createdAt' | 'updatedAt' | 'recertReference'>
    & { workflowStates?: Maybe<Array<(
      { __typename?: 'WorkflowState' }
      & Pick<WorkflowState, 'createdAt' | 'fields' | 'validation' | 'id' | 'name' | 'assignee' | 'stateType' | 'updatedAt'>
      & { workflowStateLinks?: Maybe<Array<(
        { __typename?: 'WorkflowStateLink' }
        & Pick<WorkflowStateLink, 'createdAt' | 'id' | 'link' | 'linkType' | 'updatedAt'>
      )>>, comments?: Maybe<Array<(
        { __typename?: 'Comment' }
        & Pick<Comment, 'id' | 'content' | 'createdAt' | 'updatedAt' | 'isEditable' | 'email'>
      )>> }
    )>> }
  )> }
);
