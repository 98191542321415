import { setupCentrifugeClient } from '@/api/centrifuge';
import { UserApi } from '@/api/user';
import { FetchGroupUsersQueryVariables, Group, User } from '@/generated/graphql';
import { fetchSafe } from '@/helpers/storeHelper';
import { ActionContext } from 'vuex';
import { UserMutations } from './mutations.user';
import { UserState } from './user';

export const UserActions = {
  FETCH_TOKEN: 'FETCH_TOKEN',
  FETCH_STANDARD_USERS: 'FETCH_STANDARD_USERS',
  UPDATE_CURRENT_USER: 'UPDATE_CURRENT_USER',
};

export default {
  async [UserActions.FETCH_TOKEN](context: ActionContext<UserState, {}>) {
    context.commit(UserMutations.SET_LOADING, true);
    const response = await fetchSafe(UserApi.token, {});
    context.commit(UserMutations.SET_LOADING, false);
    if (response?.fetchToken) {
      await setupCentrifugeClient(response?.fetchToken);
    }
  },

  async [UserActions.UPDATE_CURRENT_USER](context: ActionContext<UserState, {}>, payload: User) {
    context.commit(UserMutations.SET_USER, payload);
  },

  async [UserActions.FETCH_STANDARD_USERS](context: ActionContext<UserState, {}>) {
    context.commit(UserMutations.SET_STANDARD_USER, []);
    context.commit(UserMutations.SET_LOADING, true);
    const payload: FetchGroupUsersQueryVariables = {
      page: -1,
      perPage: 25,
      groupType: Group.Standard,
    };
    const allUsers = [];
    let total = 1;

    while (total > allUsers.length) {
      payload.page = payload.page + 1;
      const response = await fetchSafe(UserApi.groupUsers, payload);
      total = response?.fetchGroupUsers[0].total!;
      allUsers.push(...response!.fetchGroupUsers[0].users!.nodes!);
    }
    context.commit(UserMutations.SET_STANDARD_USER, allUsers);
    context.commit(UserMutations.SET_LOADING, false);
  },
};
