import { Country, DeviceTypeGroup, Os, PageInfo, Product, TreeNode, DogStatus } from '@/generated/graphql';
import Vue from 'vue';
import { SearchState, IntegrationsFilters } from './search';

export const SearchMutations = {
  SET_FILTERS_DEVICE_TYPE_GROUP: 'SET_FILTERS_DEVICE_TYPE_GROUP',
  SET_DEVICE_TYPES: 'SET_DEVICE_TYPES',
  SET_FILTERS: 'SET_FILTERS',
  SET_FILTERS_OS: 'SET_FILTERS_OS',
  SET_FILTERS_COUNTRY: 'SET_FILTERS_COUNTRY',
  SET_FILTERS_PRODUCT: 'SET_FILTERS_PRODUCT',
  SET_FILTERS_STATUS: 'SET_FILTERS_STATUS',
  SET_ITEMS_PER_PAGE: 'SET_ITEMS_PER_PAGE',
  SET_SEARCHTERM: 'SET_SEARCHTERM',
  SET_TOTAL_COUNT: 'SET_TOTAL_COUNT',
  SET_PAGE: 'SET_PAGE',
  RESET_SEARCH: 'RESET_SEARCH',
  SET_PAGE_INFO: 'SET_PAGE_INFO',
  SET_RESULT: 'SET_RESULT',
  SET_LOADING: 'SET_LOADING',
};

export default {
  [SearchMutations.SET_FILTERS_DEVICE_TYPE_GROUP](state: SearchState, value: number) {
    state.filters.deviceTypeGroupId = value;
  },
  [SearchMutations.SET_DEVICE_TYPES](state: SearchState, value: DeviceTypeGroup[]) {
    state.deviceTypeGroups = value;
  },
  [SearchMutations.SET_FILTERS](state: SearchState, filters: IntegrationsFilters) {
    state.filters = filters;
  },
  [SearchMutations.SET_FILTERS_OS](state: SearchState, v: Os) {
    const copy = { ...state.filters };
    copy.operatingSystem = v;
    state.filters = copy;
  },
  [SearchMutations.SET_FILTERS_COUNTRY](state: SearchState, v: Country['code']) {
    const copy = { ...state.filters };
    copy.region = v;
    state.filters = copy;
  },
  [SearchMutations.SET_FILTERS_PRODUCT](state: SearchState, v: Product) {
    const copy = { ...state.filters };
    copy.product = v;
    state.filters = copy;
  },
  [SearchMutations.SET_FILTERS_STATUS](state: SearchState, v: DogStatus) {
    const copy = { ...state.filters };
    copy.status = v;
    state.filters = copy;
  },
  [SearchMutations.SET_SEARCHTERM](state: SearchState, v: string) {
    state.searchTerm = v;
  },
  [SearchMutations.SET_RESULT](state: SearchState, v: TreeNode[]) {
    Vue.set(state, 'result', [...v]);
  },
  [SearchMutations.SET_TOTAL_COUNT](state: SearchState, v: number) {
    state.totalCount = v;
  },
  [SearchMutations.SET_PAGE_INFO](state: SearchState, v: PageInfo) {
    state.pageInfo = v;
  },
  [SearchMutations.SET_ITEMS_PER_PAGE](state: SearchState, v: number) {
    state.itemsPerPage = v;
  },
  [SearchMutations.SET_PAGE](state: SearchState, v: number) {
    state.page = v;
  },
  [SearchMutations.RESET_SEARCH](state: SearchState) {
    state.page = 1;
    state.after = '';
    state.before = '';
    state.totalCount = 0;
    state.pageInfo = {
      hasNextPage: true,
      hasPreviousPage: false,
      endCursor: '',
      startCursor: '',
    };
  },
  [SearchMutations.SET_LOADING](state: SearchState, v: boolean) {
    state.loading = v;
  },
};
