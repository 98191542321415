import actions from './actions.cat';
import getters from './getter.cat';
import mutations from './mutations.cat';

export interface CatState {
  testsessions: {
    name: string;
  }[];
}

export const state: CatState = {
  testsessions: [],
};

const Cat = {
  namespaced: true as true,
  state,
  getters,
  mutations,
  actions,
};

export default Cat;
