// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function objectWithoutType(obj: any): any {
  if (!obj) return null;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { __typename, ...assignedUser } = obj;
  return assignedUser;
}

export function generateName(email: string): string {
  return email
    .split('@')[0]
    .split('.')
    .filter(word => word.toUpperCase() !== 'CONSULTANT')
    .map(n => {
      return n.charAt(0).toUpperCase() + n.slice(1);
    })
    .join(' ');
}
