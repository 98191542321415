// import '@nielsen-media/nlsn-vue-ui/lib/theme.css';
// @ts-ignore
import directives from '@nielsen-media/nlsn-vue-ui/lib/directives.js';
import '@/assets/theme.scss';

import BaseView from '@/components/external/BaseView.vue';
import SearchBar from '@/components/search/SearchBar.vue';

import Vue from 'vue';

Vue.use(directives);

Vue.component('base-view', BaseView);
Vue.component('search-bar', SearchBar);
