export const navigation = {
  top: [
    {
      icon: 'search',
      text: 'Search',
      route: '/',
    },
    {
      icon: 'insights',
      text: 'Report',
      route: '/report',
    },
    {
      icon: 'list',
      text: 'Groups',
      route: '/groups',
    },
  ],
  bottom: [
    {
      icon: 'add_circle',
      text: 'Create Integration',
      route: '/create-integration',
    },
  ],
};
