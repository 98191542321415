import { OwlApi } from '@/api/owl';
import { FoxApi } from '@/api/fox';
import {
  CreateCustomDeviceGroupMutationVariables,
  FetchOwlExcerptQueryVariables,
  FetchOwlTreeNodesQueryVariables,
  FetchDeviceTypeGroupsQueryVariables,
  CreateReportFiltersMutationVariables,
  FetchFiltersQueryVariables,
  UpdateReportFiltersMutationVariables,
  Country,
  FetchOwlFindQuery,
  ReportFilter,
} from '@/generated/graphql';
import { fetchSafe } from '@/helpers/storeHelper';
import { ActionContext } from 'vuex';
import { ReportMutations } from './mutations.report';
import { ReportState, ReportType } from './report';
import {
  generateBarChartData,
  generateDonutData,
  generateFilterPayload,
  generateStateDeviceTypeTableData,
  generateTimeSeriesData,
  thisYearDates,
  generateSavedFilter,
  getDateRange,
} from '@/helpers/reportingHelper';
import { FETCH_REPORT_DEBOUNCE } from '@/constants';
import i18n from '@/plugins/i18n';
import { CoreGetter } from '../core/getter.core';
import { ApolloQueryResult } from '@apollo/client';

let reportTimeout: NodeJS.Timeout | null = null;

export const ReportActions = {
  UPDATE_REPORT_FILTERS: 'UPDATE_REPORT_FILTERS',
  SAVE_REPORT_FILTERS: 'SAVE_REPORT_FILTERS',
  CREATE_CUSTOM_DEVICE_GROUP: 'CREATE_CUSTOM_DEVICE_GROUP',
  FETCH_OWL_TREENODES: 'FETCH_OWL_TREENODES',
  FETCH_EXCERPT: 'FETCH_EXCERPT',
  FETCH_DEVICE_TYPE_GROUPS: 'FETCH_DEVICE_TYPE_GROUPS',
  FETCH_REPORT: 'FETCH_REPORT',
  FETCH_REPORT_FILTERS: 'FETCH_REPORT_FILTERS',
  SET_SAVED_FILTER: 'SET_SAVED_FILTER',
};

export default {
  async [ReportActions.FETCH_OWL_TREENODES](
    { commit }: ActionContext<ReportState, {}>,
    payload: FetchOwlTreeNodesQueryVariables
  ) {
    commit(ReportMutations.SET_LOADING, true);
    const response = await fetchSafe(OwlApi.search, payload);
    commit(ReportMutations.SET_LOADING, false);
    return response?.fetchOwlTreeNodes;
  },

  async [ReportActions.FETCH_EXCERPT](
    { commit }: ActionContext<ReportState, {}>,
    payload: FetchOwlExcerptQueryVariables
  ) {
    commit(ReportMutations.SET_LOADING, true);
    const response = await fetchSafe(OwlApi.excerpt, payload);
    commit(ReportMutations.SET_LOADING, false);
    commit(ReportMutations.SET_PARENT_EXCERPT, response?.fetchOwlExcerpt);
  },

  async [ReportActions.FETCH_DEVICE_TYPE_GROUPS](
    { commit }: ActionContext<ReportState, {}>,
    payload: FetchDeviceTypeGroupsQueryVariables
  ) {
    const response = await fetchSafe(FoxApi.deviceTypeGroups, payload);
    commit(ReportMutations.SET_DEVICE_TYPES, response?.fetchDeviceTypeGroups);
  },

  async [ReportActions.CREATE_CUSTOM_DEVICE_GROUP](
    { commit, dispatch }: ActionContext<ReportState, {}>,
    payload: CreateCustomDeviceGroupMutationVariables
  ) {
    const response = await fetchSafe(FoxApi.createDeviceGroup, payload);
    commit(ReportMutations.UPDATE_DEVICE_TYPES, response?.createDeviceTypeGroup?.group);
    commit(ReportMutations.UPDATE_SELECTED_DEVICE, response?.createDeviceTypeGroup?.group);
    dispatch(ReportActions.FETCH_REPORT);
  },

  async [ReportActions.SAVE_REPORT_FILTERS](
    { state, commit }: ActionContext<ReportState, {}>,
    payload: CreateReportFiltersMutationVariables
  ) {
    payload.data = generateSavedFilter(payload.data, state);
    const response = await fetchSafe(FoxApi.saveReportFilter, payload);
    if (response) {
      commit(ReportMutations.SET_LOADED_FILTER, response.createReportFilters?.filters);
    }
    return response;
  },

  async [ReportActions.UPDATE_REPORT_FILTERS](
    { state, commit }: ActionContext<ReportState, {}>,
    payload: UpdateReportFiltersMutationVariables
  ) {
    payload.data = generateSavedFilter(payload.data, state);
    const response = await fetchSafe(FoxApi.updateReportFilter, payload);
    if (response) {
      commit(ReportMutations.SET_LOADED_FILTER, response.updateReportFilters?.filters);
    }
    return response;
  },

  async [ReportActions.FETCH_REPORT_FILTERS](_: ActionContext<ReportState, {}>, payload: FetchFiltersQueryVariables) {
    return await fetchSafe(FoxApi.filters, payload);
  },

  async [ReportActions.FETCH_REPORT]({ commit, state }: ActionContext<ReportState, {}>) {
    return new Promise(resolve => {
      commit(ReportMutations.SET_LOADING, true);
      state.report = null;
      if (reportTimeout) {
        clearTimeout(reportTimeout);
        reportTimeout = null;
      }

      reportTimeout = setTimeout(async () => {
        if (!state.selectedDate) {
          commit(ReportMutations.SET_SELECTED_DATE, thisYearDates());
        }
        const payload = generateFilterPayload(state);
        const response = await fetchSafe(FoxApi.report, payload);

        let report = {};
        if (Object.keys(response?.fetchReports).length > 0) {
          report = {
            ...response?.fetchReports,
            charts: {
              stateDeviceType: generateStateDeviceTypeTableData(response?.fetchReports),
              donut: generateDonutData(response?.fetchReports),
              bar: generateBarChartData(response?.fetchReports),
              timeSeries: generateTimeSeriesData(response?.fetchReports),
            },
          } as ReportType;
        }

        commit(ReportMutations.SET_REPORT, report);
        commit(ReportMutations.SET_LOADING, false);
        resolve(report);
      }, FETCH_REPORT_DEBOUNCE);
    });
  },

  async [ReportActions.SET_SAVED_FILTER](
    { commit, state, rootGetters, dispatch }: ActionContext<ReportState, {}>,
    filter: ReportFilter
  ) {
    if (!filter.filters) return;
    commit(ReportMutations.SET_LOADED_FILTER, filter);
    state.selectedParents = {};
    state.selectedCountries = [];
    state.selectedProducts = [];
    state.selectedOs = [];
    state.selectedDevices = [];
    state.selectedEntities = [];
    state.selectedStatus = [];

    const filters = filter.filters;
    if (filters.os) {
      commit(
        ReportMutations.SET_SELECTED_OS,
        filters?.os.map(o => ({ code: o, name: i18n.t(`osNames.${o}`) }))
      );
    }
    if (filters.daterange) {
      commit(ReportMutations.SET_SELECTED_DATE, getDateRange(filters.daterange));
    }
    if (filters.deviceGroups) {
      const selected = [];
      for (const s of filters.deviceGroups) {
        if (s.id) {
          selected.push(state.deviceTypes.find(g => g.id === s.id));
        }
      }
      commit(ReportMutations.SET_SELECTED_DEVICES, selected);
    }
    if (filters.marketViewEntities) {
      const responses: Promise<ApolloQueryResult<FetchOwlFindQuery>>[] = [];
      for (const mve of filters.marketViewEntities) {
        responses.push(OwlApi.find({ id: mve.id }));
      }
      await Promise.all(responses);
      for (const resp of responses) {
        const data = (await resp).data;
        if (data.fetchOwlFind && data.fetchOwlFind.length > 0) {
          const treeId = data.fetchOwlFind[0].id;
          commit(ReportMutations.SET_SELECTED_PARENT, data.fetchOwlFind[0]);
          commit(ReportMutations.SET_SELECTED_ENTITIES, {
            parentId: treeId,
            selection: filters.marketViewEntities.find(mve => mve.id === treeId)!.selection,
          });
        }
      }
    }
    if (filters.products) {
      commit(
        ReportMutations.SET_SELECTED_PRODUCTS,
        filters?.products.map(o => ({ code: o, name: i18n.t(`products.${o}`) }))
      );
    }
    if (filters.region) {
      const selected = [];
      for (const s of filters.region) {
        selected.push(rootGetters[`core/${CoreGetter.GET_COUNTRIES}`].find((c: Country) => c.code === s));
      }
      commit(ReportMutations.SET_SELECTED_COUNTRIES, selected);
    }
    if (filters.status) {
      commit(
        ReportMutations.SET_SELECTED_STATUS,
        filters?.status.map(o => ({ code: o, name: i18n.t(`intStatus.${o}`) }))
      );
    }
    dispatch(ReportActions.FETCH_REPORT);
  },
};
