import { ReportState } from './report';
export const ReportGetter = {
  GET_DEVICE_TYPES: 'GET_DEVICE_TYPES',
  GET_INDIVIDUAL_DEVICE_TYPES: 'GET_INDIVIDUAL_DEVICE_TYPES',
  GET_SELECTED_PARENTS: 'GET_SELECTED_PARENTS',
  GET_SELECTED_ENTITIES: 'GET_SELECTED_ENTITIES',
  GET_SELECTED_OS: 'GET_SELECTED_OS',
  GET_SELECTED_COUNTRIES: 'GET_SELECTED_COUNTRIES',
  GET_SELECTED_PRODUCTS: 'GET_SELECTED_PRODUCTS',
  GET_SELECTED_DEVICES: 'GET_SELECTED_DEVICES',
  GET_SELECTED_DATE: 'GET_SELECTED_DATE',
  GET_SELECTED_STATUS: 'GET_SELECTED_STATUS',
  GET_LOADING: 'GET_LOADING',
  GET_REPORT: 'GET_REPORT',
  SHOW_EXPORT_DIALOG: 'SHOW_EXPORT_DIALOG',
  SHOW_SAVE_FILTER_DIALOG: 'SHOW_SAVE_FILTER_DIALOG',
  SHOW_LOAD_FILTER_DIALOG: 'SHOW_LOAD_FILTER_DIALOG',
  GET_LOADED_FILTER: 'GET_LOADED_FILTER',
  IS_ANY_NON_DATE_FILTER_SET: 'IS_ANY_NON_DATE_FILTER_SET',
};

export default {
  [ReportGetter.GET_DEVICE_TYPES](state: ReportState) {
    return state.deviceTypes;
  },
  [ReportGetter.GET_INDIVIDUAL_DEVICE_TYPES](state: ReportState) {
    return state.deviceTypes.reduce<Record<string, string[]>>((acc, e) => {
      const deviceGroup = e.name.toLowerCase();

      if (deviceGroup.length) {
        acc[deviceGroup] = [];
        e.deviceTypes.forEach(d => {
          acc[deviceGroup].push(d.name.toLowerCase());
        });
      }
      return acc;
    }, {});
  },
  [ReportGetter.GET_SELECTED_PARENTS](state: ReportState) {
    return state.selectedParents;
  },
  [ReportGetter.GET_SELECTED_OS](state: ReportState) {
    return state.selectedOs;
  },
  [ReportGetter.GET_SELECTED_COUNTRIES](state: ReportState) {
    return state.selectedCountries;
  },
  [ReportGetter.GET_SELECTED_PRODUCTS](state: ReportState) {
    return state.selectedProducts;
  },
  [ReportGetter.GET_SELECTED_DEVICES](state: ReportState) {
    return state.selectedDevices;
  },
  [ReportGetter.GET_SELECTED_DATE](state: ReportState) {
    return state.selectedDate;
  },
  [ReportGetter.GET_SELECTED_STATUS](state: ReportState) {
    return state.selectedStatus;
  },

  [ReportGetter.GET_LOADING](state: ReportState) {
    return state.loading;
  },

  [ReportGetter.GET_REPORT](state: ReportState) {
    return state.report;
  },
  [ReportGetter.SHOW_EXPORT_DIALOG](state: ReportState) {
    return state.showReportDialog;
  },
  [ReportGetter.SHOW_SAVE_FILTER_DIALOG](state: ReportState) {
    return state.showSaveFilterDialog;
  },
  [ReportGetter.SHOW_LOAD_FILTER_DIALOG](state: ReportState) {
    return state.showLoadFilterDialog;
  },
  [ReportGetter.GET_LOADED_FILTER](state: ReportState) {
    return state.loadedFilter;
  },
  [ReportGetter.IS_ANY_NON_DATE_FILTER_SET](state: ReportState) {
    return (
      Object.keys(state.selectedCountries).length > 0 ||
      Object.keys(state.selectedDevices).length > 0 ||
      Object.keys(state.selectedEntities).length > 0 ||
      Object.keys(state.selectedOs).length > 0 ||
      Object.keys(state.selectedProducts).length > 0
    );
  },
};
