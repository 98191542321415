import { SubscribePayload, centrifugeClient, PublicationResult } from '@/api/centrifuge';
import { FoxApi } from '@/api/fox';
import {
  FetchTransitionsQueryVariables,
  StartCertificationMutationVariables,
  StartRecertificationMutationVariables,
  UpdateWorkflowStateMutationVariables,
  UpdateWorkflowStateLinkMutationVariables,
  CreateDirectUploadMutationMutationVariables,
  AttachWorkflowStateFileMutationMutationVariables,
  FetchWorkflowFileUrlQueryVariables,
  WorkflowState,
  CreateCommentMutationVariables,
  UpdateCommentMutationVariables,
  FetchWorkflowsByProductQueryVariables,
  FetchWorkflowLogsQueryVariables,
  User,
  WorkflowTransitionMutationVariables,
} from '@/generated/graphql';
import { fetchSafe } from '@/helpers/storeHelper';
import { JoinLeaveContext } from 'centrifuge';
import Vue from 'vue';
import { ActionContext } from 'vuex';
import { RootState } from '..';
import { UserGetter } from '../user/getters.user';
import { WorkflowMutations } from './mutations.workflow';
import { WorkflowStoreState } from './workflow';

export const WorkflowActions = {
  FETCH_WORKFLOW: 'FETCH_WORKFLOW',
  FETCH_WORKFLOWS_BY_PRODUCT: 'FETCH_WORKFLOWS_BY_PRODUCT',
  FETCH_WORKFLOW_LOGS: 'FETCH_WORKFLOW_LOGS',
  FETCH_TRANSITIONS: 'FETCH_TRANSITIONS',
  FETCH_FILE_URL: 'FETCH_WORKFLOW_FILE_URL',
  MUTATION_START_CERTIFICATION: 'MUTATION_START_CERTIFICATION',
  MUTATION_WORKFLOW_TRANSITION: 'MUTATION_WORKFLOW_TRANSITION',
  MUTATION_START_RECERTIFICATION: 'MUTATION_START_RECERTIFICATION',
  MUTATION_START_COMPLETE_RECERTIFICATION: 'MUTATION_START_COMPLETE_RECERTIFICATION',
  MUTATION_UPDATE_WORKFLOW_STATE: 'MUTATION_UPDATE_WORKFLOW_STATE',
  MUTATION_UPDATE_WORKFLOW_STATE_LINK: 'MUTATION_UPDATE_WORKFLOW_STATE_LINK',
  MUTATION_CREATE_DIRECT_UPLOAD: 'MUTATION_CREATE_DIRECT_UPLOAD',
  MUTATION_ATTACH_FILE_TO_STATE: 'MUTATION_ATTACH_FILE_TO_STATE',
  MUTATION_ADD_COMMENT: 'MUTATION_ADD_COMMENT',
  MUTATION_UPDATE_COMMENT: 'MUTATION_UPDATE_COMMENT',
  SUBSCRIBE_TO_WORKFLOW: 'SUBSCRIBE_TO_WORKFLOW',
  LOCK_WORKFLOW: 'LOCK_WORKFLOW',
  UNLOCK_WORKFLOW: 'UNLOCK_WORKFLOW',
  UNSUBSCRIBE_FROM_WORKFLOW: 'UNSUBSCRIBE_FROM_WORKFLOW',
};

interface CreateCommentsVariables extends CreateCommentMutationVariables {
  workflowIndex: number;
}

interface UpdateCommentsVariables extends UpdateCommentMutationVariables {
  workflowIndex: number;
}

export default {
  async [WorkflowActions.SUBSCRIBE_TO_WORKFLOW](context: ActionContext<WorkflowStoreState, RootState>) {
    await centrifugeClient.ready;
    context.state.selectedWorkflowsLocked = undefined;
    const payload: SubscribePayload = {
      integrationId: context.rootState.core.selectedIntegration!.id,
      product: context.state.selectedWorkflows![0].product!,
    };
    const callbacks = {
      publish: (m: PublicationResult) => {
        const user: User = context.rootGetters[`user/${UserGetter.GET_USER}`];
        if (m.info?.user != user.email) {
          context.commit(WorkflowMutations.CURRENT_WORKFLOW_LOCK, m);
          if (!m.data.lock) {
            context.dispatch(WorkflowActions.FETCH_WORKFLOWS_BY_PRODUCT, payload);
          }
          // context.dispatch(WorkflowActions.FETCH_WORKFLOWS_BY_PRODUCT, payload);
        }
      },
      join: (m: JoinLeaveContext) => {
        context.commit(WorkflowMutations.ADD_CURRENT_WORKFLOW_VIEWERS, m);
      },
      leave: (m: JoinLeaveContext) => {
        context.commit(WorkflowMutations.HANDLE_SUBSCRIPTION_LEAVE, m);
      },
    };
    const sub = centrifugeClient.subscribe(payload, callbacks);

    if (sub) {
      const history = await sub.history();
      history.publications.forEach(m => {
        if (context.rootState.user.user?.email != m.info?.user) {
          context.commit(WorkflowMutations.CURRENT_WORKFLOW_LOCK, m);
        }
      });
    }
    const viewers = await centrifugeClient.presence(payload);
    context.commit(WorkflowMutations.SET_CURRENT_WORKFLOW_VIEWERS, viewers);
  },

  async [WorkflowActions.LOCK_WORKFLOW](context: ActionContext<WorkflowStoreState, RootState>) {
    await centrifugeClient.ready;
    const payload: SubscribePayload = {
      integrationId: context.rootState.core.selectedIntegration!.id,
      product: context.state.selectedWorkflows![0].product!,
    };
    if (!context.state.selectedWorkflowsLocked) {
      centrifugeClient.publish(payload, { lock: true });
    } else {
      console.log('cannot lock workflow');
    }
  },
  async [WorkflowActions.UNLOCK_WORKFLOW](context: ActionContext<WorkflowStoreState, RootState>) {
    const payload: SubscribePayload = {
      integrationId: context.rootState.core.selectedIntegration!.id,
      product: context.state.selectedWorkflows![0].product!,
    };
    await centrifugeClient.ready;
    centrifugeClient.publish(payload, { lock: false });
  },

  async [WorkflowActions.UNSUBSCRIBE_FROM_WORKFLOW](context: ActionContext<WorkflowStoreState, RootState>) {
    const payload: SubscribePayload = {
      integrationId: context.rootState.core.selectedIntegration!.id,
      product: context.state.selectedWorkflows![0].product!,
    };
    await centrifugeClient.ready;
    centrifugeClient.unsubscribe(payload);
    Vue.set(context.state, 'viewers', {});
  },

  async [WorkflowActions.MUTATION_ADD_COMMENT](
    context: ActionContext<WorkflowStoreState, {}>,
    payload: CreateCommentsVariables
  ) {
    context.commit(WorkflowMutations.SET_LOADING, true);
    const response = await fetchSafe(FoxApi.createComment, payload);
    context.commit(WorkflowMutations.SET_LOADING, false);
    if (response && response.createComment && response.createComment.comment && context.state.selectedWorkflows) {
      let index = context.state.selectedWorkflows[payload.workflowIndex].workflowStates?.findIndex(
        (s: WorkflowState) => s.id === payload.workflowStateId
      );
      index = index !== undefined ? index : -1;
      const comments = context.state.selectedWorkflows[payload.workflowIndex].workflowStates?.[index].comments;
      if (comments!.length >= 1) {
        // @ts-ignore
        comments[0].isEditable = false;
      }
      comments?.push(response.createComment.comment);
    }
    return response?.createComment;
  },

  async [WorkflowActions.MUTATION_UPDATE_COMMENT](
    context: ActionContext<WorkflowStoreState, {}>,
    payload: UpdateCommentsVariables
  ) {
    context.commit(WorkflowMutations.SET_LOADING, true);
    const response = await fetchSafe(FoxApi.updateComment, payload);
    context.commit(WorkflowMutations.SET_LOADING, false);
    if (response && response.updateComment && response.updateComment.comment && context.state.selectedWorkflows) {
      let index = context.state.selectedWorkflows[payload.workflowIndex].workflowStates?.findIndex(
        (s: WorkflowState) => s.id === payload.workflowStateId
      );
      index = index !== undefined ? index : -1;
      const comments = context.state.selectedWorkflows[payload.workflowIndex].workflowStates?.[index].comments;
      const commentIndex = comments?.findIndex(comment => comment.id == response.updateComment?.comment?.id);
      // @ts-ignore
      comments[commentIndex] = response.updateComment.comment;
    }
    return response?.updateComment;
  },

  async [WorkflowActions.MUTATION_START_CERTIFICATION](
    context: ActionContext<WorkflowStoreState, {}>,
    payload: StartCertificationMutationVariables
  ) {
    context.commit(WorkflowMutations.SET_LOADING, true);
    const response = await fetchSafe(FoxApi.startCertification, payload);
    context.commit(WorkflowMutations.SET_LOADING, false);
    if (response && response.createWorkflow) {
      context.commit(WorkflowMutations.UPDATE_SELECTED_WORKFLOWS, {
        workflow: response?.createWorkflow?.workflow,
        action: 'update',
      });
    }
    return response?.createWorkflow;
  },

  async [WorkflowActions.MUTATION_WORKFLOW_TRANSITION](
    context: ActionContext<WorkflowStoreState, {}>,
    payload: WorkflowTransitionMutationVariables
  ) {
    context.commit(WorkflowMutations.SET_LOADING, true);
    const response = await fetchSafe(FoxApi.workflowTransition, payload);
    context.commit(WorkflowMutations.SET_LOADING, false);
    if (response && response.workflowTransition) {
      context.commit(WorkflowMutations.UPDATE_SELECTED_WORKFLOWS, {
        workflow: response?.workflowTransition?.workflow,
        action: 'update',
      });
    }
    return response?.workflowTransition;
  },

  async [WorkflowActions.MUTATION_START_RECERTIFICATION](
    context: ActionContext<WorkflowStoreState, {}>,
    payload: StartRecertificationMutationVariables
  ) {
    context.commit(WorkflowMutations.SET_LOADING, true);
    const response = await fetchSafe(FoxApi.startRecertification, payload);
    context.commit(WorkflowMutations.SET_LOADING, false);
    if (response && response.createRecertWorkflow) {
      context.commit(WorkflowMutations.UPDATE_SELECTED_WORKFLOWS, {
        workflow: response?.createRecertWorkflow?.workflow,
        action: 'add-new',
      });
    }
    return response?.createRecertWorkflow;
  },

  async [WorkflowActions.MUTATION_UPDATE_WORKFLOW_STATE](
    context: ActionContext<WorkflowStoreState, {}>,
    payload: UpdateWorkflowStateMutationVariables
  ) {
    context.commit(WorkflowMutations.SET_LOADING, true);
    const response = await fetchSafe(FoxApi.updateWorkflowState, payload);
    context.commit(WorkflowMutations.SET_LOADING, false);
    if (response?.updateWorkflowState?.workflowState) {
      context.commit(WorkflowMutations.UPDATE_SELECTED_WORKFLOW_STATE, response.updateWorkflowState.workflowState);
    }
  },

  async [WorkflowActions.MUTATION_UPDATE_WORKFLOW_STATE_LINK](
    context: ActionContext<WorkflowStoreState, {}>,
    payload: UpdateWorkflowStateLinkMutationVariables
  ) {
    const response = await fetchSafe(FoxApi.updateWorkflowStateLink, payload);
    return response?.updateWorkflowStateLink?.workflowStateLink?.id;
  },

  async [WorkflowActions.MUTATION_CREATE_DIRECT_UPLOAD](
    context: ActionContext<WorkflowStoreState, {}>,
    payload: CreateDirectUploadMutationMutationVariables
  ) {
    const response = await fetchSafe(FoxApi.createDirectUpload, payload);
    return response;
  },

  async [WorkflowActions.MUTATION_ATTACH_FILE_TO_STATE](
    context: ActionContext<WorkflowStoreState, {}>,
    payload: AttachWorkflowStateFileMutationMutationVariables
  ) {
    const response = await fetchSafe(FoxApi.attachFileToState, payload);
    return response;
  },

  async [WorkflowActions.FETCH_WORKFLOWS_BY_PRODUCT](
    context: ActionContext<WorkflowStoreState, {}>,
    payload: FetchWorkflowsByProductQueryVariables
  ) {
    const response = await fetchSafe(FoxApi.workflowsByProduct, payload);
    context.commit(WorkflowMutations.SET_SELECTED_WORKFLOWS, response?.fetchWorkflows);
  },

  async [WorkflowActions.FETCH_WORKFLOW_LOGS](
    context: ActionContext<WorkflowStoreState, {}>,
    payload: FetchWorkflowLogsQueryVariables
  ) {
    const response = await fetchSafe(FoxApi.workflowLogs, payload);
    context.commit(WorkflowMutations.SET_WORKFLOW_HISTORY, response?.fetchWorkflowLogs);
  },

  async [WorkflowActions.FETCH_TRANSITIONS](context: ActionContext<WorkflowStoreState, {}>) {
    if (context.state.selectedWorkflows === null) return;
    const payload: FetchTransitionsQueryVariables = {
      workflowId: context.state.selectedWorkflows[0]!.id,
    };
    context.commit(WorkflowMutations.SET_LOADING, true);
    const response = await fetchSafe(FoxApi.transitions, payload);
    context.commit(WorkflowMutations.SET_LOADING, false);
    return response?.fetchTransitions;
  },

  async [WorkflowActions.FETCH_FILE_URL](
    context: ActionContext<WorkflowState, {}>,
    payload: FetchWorkflowFileUrlQueryVariables
  ) {
    const response = await fetchSafe(FoxApi.workflowFileUrl, payload);
    return response;
  },
};
