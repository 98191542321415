import { ClientInfo, PresenceMap } from 'centrifuge';
import { Workflow, Version } from '@/generated/graphql';
import actions from './actions.workflow';
import getters from './getter.workflow';
import mutations from './mutations.workflow';
import VueI18n from 'vue-i18n';

export interface WorkflowStoreState {
  loading: boolean;
  selectedWorkflowsLocked: ClientInfo | undefined;
  viewers: PresenceMap;
  workflowHistory: Version[] | null;
  selectedWorkflows: Workflow[] | null;
}

export interface ReducedWorkflow {
  product: VueI18n.TranslateResult;
  codeProduct: string | null | undefined;
  state: string | null | undefined;
}

const state: WorkflowStoreState = {
  loading: false,
  workflowHistory: [],
  selectedWorkflows: null,
  selectedWorkflowsLocked: undefined,
  viewers: {},
};

const WorkflowStore = {
  namespaced: true as true,
  state,
  getters,
  mutations,
  actions,
};
export default WorkflowStore;
