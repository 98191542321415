import { User } from '@/generated/graphql';
import Vue from 'vue';
import { UserState } from './user';

export const UserMutations = {
  SET_LOADING: 'SET_LOADING',
  SET_USER: 'SET_USER',
  SET_STANDARD_USER: 'SET_STANDARD_USER',
};

export default {
  [UserMutations.SET_LOADING](state: UserState, v: boolean) {
    state.loading = v;
  },

  [UserMutations.SET_USER](state: UserState, v: User) {
    Vue.set(state, 'user', { ...v });
  },

  [UserMutations.SET_STANDARD_USER](state: UserState, v: User[]) {
    state.standardUsers = v;
  },
};
