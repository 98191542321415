import { Workflow, WorkflowState, Version } from '@/generated/graphql';
import { WorkflowStoreState } from './workflow';
import Vue from 'vue';
import { getSocketId } from '@/helpers/localStorageHelper';
import { PublicationResult } from '@/api/centrifuge';
import { JoinLeaveContext, PresenceResult } from 'centrifuge';

export const WorkflowMutations = {
  SET_SELECTED_WORKFLOWS: 'SET_SELECTED_WORKFLOWS',
  SET_LOADING: 'SET_LOADING',
  UPDATE_SELECTED_WORKFLOWS: 'UPDATE_SELECTED_WORKFLOWS',
  UPDATE_SELECTED_WORKFLOW_STATE: 'UPDATE_SELECTED_WORKFLOW_STATE',
  CURRENT_WORKFLOW_LOCK: 'CURRENT_WORKFLOW_LOCK',
  HANDLE_SUBSCRIPTION_LEAVE: 'HANDLE_SUBSCRIPTION_LEAVE',
  SET_CURRENT_WORKFLOW_VIEWERS: 'CURRENT_WORKFLOW_VIEWERS',
  ADD_CURRENT_WORKFLOW_VIEWERS: 'ADD_CURRENT_WORKFLOW_VIEWERS',
  SET_WORKFLOW_HISTORY: 'SET_WORKFLOW_HISTORY',
};
export default {
  [WorkflowMutations.SET_SELECTED_WORKFLOWS](state: WorkflowStoreState, workflows: [Workflow]) {
    state.selectedWorkflows = workflows;
  },

  [WorkflowMutations.UPDATE_SELECTED_WORKFLOWS](
    state: WorkflowStoreState,
    { workflow, action }: { workflow: Workflow; action: string }
  ) {
    if (state.selectedWorkflows === null) return;
    switch (action) {
      case 'update': {
        const index = state.selectedWorkflows?.findIndex(w => w.id == workflow.id);
        Vue.set(state.selectedWorkflows, index, workflow);
        break;
      }
      case 'add-new': {
        state.selectedWorkflows.unshift(workflow);
        break;
      }
    }
  },

  [WorkflowMutations.UPDATE_SELECTED_WORKFLOW_STATE](state: WorkflowStoreState, workflow: WorkflowState) {
    if (state.selectedWorkflows === null) return;

    const workflowStates = state.selectedWorkflows[0]?.workflowStates; // selectedWorkflows[0] is always the current workflow by definition. Other workflows' states cannot be updated by definition;
    if (workflowStates) {
      const i = workflowStates.findIndex(s => s.id === workflow.id);
      if (i !== undefined && i > -1) {
        Vue.set(workflowStates, i, workflow);
      }
    }
  },

  [WorkflowMutations.SET_WORKFLOW_HISTORY](state: WorkflowStoreState, v: Version[]) {
    state.workflowHistory = v;
  },

  [WorkflowMutations.SET_LOADING](state: WorkflowStoreState, v: boolean) {
    state.loading = v;
  },

  [WorkflowMutations.CURRENT_WORKFLOW_LOCK](state: WorkflowStoreState, v: PublicationResult) {
    state.selectedWorkflowsLocked = v.data.lock ? v.info : undefined;
  },
  [WorkflowMutations.SET_CURRENT_WORKFLOW_VIEWERS](state: WorkflowStoreState, v: PresenceResult) {
    state.viewers = v.presence;
    if (state.selectedWorkflowsLocked) {
      let foundLockUser = false;
      for (const key in v.presence) {
        if (Object.prototype.hasOwnProperty.call(v.presence, key)) {
          const p = v.presence[key];
          if (state.selectedWorkflowsLocked?.user == p.user) {
            foundLockUser = true;
          }
        }
      }
      if (!foundLockUser) {
        state.selectedWorkflowsLocked = undefined;
      }
    }
    const me = getSocketId()!;
    Vue.delete(state.viewers, me);
  },
  [WorkflowMutations.HANDLE_SUBSCRIPTION_LEAVE](state: WorkflowStoreState, v: JoinLeaveContext) {
    if (state.selectedWorkflowsLocked && state.selectedWorkflowsLocked.user == v.info.user) {
      state.selectedWorkflowsLocked = undefined;
    }
    Vue.delete(state.viewers, v.info.client!);
  },
  [WorkflowMutations.ADD_CURRENT_WORKFLOW_VIEWERS](state: WorkflowStoreState, v: JoinLeaveContext) {
    Vue.set(state.viewers, v.info.client!, v.info);
    const me = getSocketId()!;
    Vue.delete(state.viewers, me);
  },
};
