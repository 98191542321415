






import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
//@ts-ignore
import { ROUTER } from '@/constants';
import { Integration, Workflow } from '@/generated/graphql';
import { CoreGetter } from '@/store/core/getter.core';
import { WorkflowGetter } from '@/store/workflow/getter.workflow';
import { SearchGetter } from '@/store/search/getter.search';
import { IntegrationsFilters } from '@/store/search/search';
import { filtersToQueryString } from '@/helpers/routeHelper';

@Component({})
export default class BreadCrumbs extends Vue {
  @Getter(`core/${CoreGetter.GET_SELECTED_INTEGRATION}`)
  currentIntegration!: Integration | null;

  @Getter(`workflow/${WorkflowGetter.GET_SELECTED_WORKFLOWS}`)
  currentWorkflows!: Workflow[] | null;

  @Getter(`search/${SearchGetter.GET_SEARCHTERM}`)
  currentSearchTerm!: string | null;

  @Getter(`search/${SearchGetter.GET_FILTERS}`)
  filters!: IntegrationsFilters;

  currentTerm = this.currentSearchTerm;

  scrollToTop() {
    window.scroll({ top: 0, behavior: 'smooth' });
  }

  get crumbs() {
    const list = [];
    this.routeCheckBeforeEnter();
    if (this.currentIntegration && this.$router.currentRoute.path.indexOf('/integration') !== -1) {
      list.push({
        text: this.$t('search.search'),
        disabled: false,
        to: { name: ROUTER.SEARCH },
        exact: true,
      });

      if (
        this.currentSearchTerm &&
        this.currentSearchTerm !== '' &&
        this.currentTerm !== '' &&
        this.$router.currentRoute.path.indexOf('/integration') !== -1
      ) {
        const queryFilters = filtersToQueryString(this.filters);
        const search = this.currentTerm == '' ? this.currentTerm : '"' + this.currentSearchTerm + '"';
        list.push({
          text: search,
          disabled: false,
          to: {
            name: ROUTER.SEARCH_RESULT,
            query: {
              searchTerm: this.currentSearchTerm as string,
              ...queryFilters,
            },
          },
          exact: true,
        });
      }
    }
    if (this.currentWorkflows && this.$router.currentRoute.name === ROUTER.WORKFLOW_DETAILS) {
      const integrationName = this.currentIntegration?.name;
      const product = this.$router.currentRoute?.params?.product;

      list.push({
        text: integrationName,
        disabled: false,
        to: { name: ROUTER.WORKFLOW_LIST, params: { integrationId: this.currentIntegration?.id } },
        exact: true,
      });

      if (product) {
        list.push({
          text: `${this.$t(`products.${product}`)}`,
          disabled: true,
        });
      }
    }

    return list;
  }

  routeCheckBeforeEnter() {
    this.$router.beforeEach((to, from, next) => {
      if (from.path.indexOf('/create-integration') !== -1 && to.query.isCreated == 'true') {
        this.currentTerm = '';
      } else if (from.query.isCreated == 'true' || to.query.isCreated == 'true') {
        this.currentTerm = '';
      } else {
        this.currentTerm = this.currentSearchTerm;
      }
      next();
    });
  }
}
