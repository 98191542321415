import availableColors from '@/assets/colors.scss';
export const urlRegexp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/;
export const urlRegex = new RegExp(urlRegexp);

function getCssNameFromState(state: string | null | undefined): string {
  if (!state) {
    return '';
  }
  return state
    .replace(/WorkflowStates::/g, '')
    .replace(/(?:^|\.?)([A-Z])/g, function(x, y) {
      // PascalCase (DevSanity) to snake_case (dev_sanity) for simplified refs
      return '_' + y.toLowerCase();
    })
    .replace(/^_/, '');
}

export function getCssClassFromState(state: string | null | undefined): string {
  return `--${getCssNameFromState(state)}`;
}

export function getColorFromState(state: string | null | undefined): string {
  return availableColors[getCssNameFromState(state)];
}
