import { User } from '@/generated/graphql';
import actions from './actions.user';
import getters from './getters.user';
import mutations from './mutations.user';

export interface UserState {
  user: User | null;
  standardUsers: User[] | null;
  loading: boolean;
}

export const state: UserState = {
  user: null,
  loading: false,
  standardUsers: null,
};

const UserStore = {
  namespaced: true as true,
  state,
  getters,
  mutations,
  actions,
};

export default UserStore;
