import { Scope } from '@/generated/graphql';
import { Route } from 'vue-router';
import { IntegrationsFilters } from '@/store/search/search';

export function isBrand(route: Route) {
  return route.params.type === Scope.Brand;
}

export function filtersToQueryString(o: { [k: string]: string } | IntegrationsFilters) {
  return Object.keys(o).reduce((obj: { [k: string]: string }, k: string) => {
    const val = o[k as keyof IntegrationsFilters];
    if (val) {
      obj[k] = val as string;
    }
    return obj;
  }, {});
}
