import { FoxApi } from '@/api/fox';
import { FetchDeviceTypeGroupsQueryVariables, FetchIntegrationsQueryVariables } from '@/generated/graphql';
import { fetchSafe } from '@/helpers/storeHelper';

import { ActionContext } from 'vuex';
import { SearchGetter } from './getter.search';
import { SearchMutations } from './mutations.search';
import { SearchState } from './search';

export const SearchActions = {
  FETCH_SEARCH: 'FETCH_SEARCH',
  FETCH_DEVICE_TYPE_GROUPS: 'FETCH_DEVICE_TYPE_GROUPS',
};

export default {
  async [SearchActions.FETCH_DEVICE_TYPE_GROUPS](
    { commit }: ActionContext<SearchState, {}>,
    payload: FetchDeviceTypeGroupsQueryVariables
  ) {
    const response = await fetchSafe(FoxApi.deviceTypeGroups, payload);
    commit(SearchMutations.SET_DEVICE_TYPES, response?.fetchDeviceTypeGroups);
  },

  async [SearchActions.FETCH_SEARCH](
    { state, getters, commit }: ActionContext<SearchState, {}>,
    payload: { isPrevious: boolean }
  ) {
    if (getters[SearchGetter.IS_LOADING]) {
      return;
    }

    const requestPayload: FetchIntegrationsQueryVariables = {
      searchStr: state.searchTerm,
      osType: state.filters.operatingSystem,
      region: state.filters.region,
      product: state.filters.product,
      deviceTypeGroupId: state.filters.deviceTypeGroupId,
      status: state.filters.status,
      first: undefined,
      after: '',
    };

    if (payload.isPrevious) {
      requestPayload.before = state.pageInfo.startCursor;
      requestPayload.last = state.itemsPerPage;
    } else {
      requestPayload.after = state.pageInfo.endCursor;
      requestPayload.first = state.itemsPerPage;
    }

    try {
      commit(SearchMutations.SET_LOADING, true);
      const response = await fetchSafe(FoxApi.searchIntegration, requestPayload);
      commit(SearchMutations.SET_TOTAL_COUNT, response?.fetchIntegrations.totalCount);
      commit(SearchMutations.SET_PAGE_INFO, response?.fetchIntegrations.pageInfo);
      commit(SearchMutations.SET_RESULT, response?.fetchIntegrations.nodes);
      commit(SearchMutations.SET_LOADING, false);
    } catch {
      commit(SearchMutations.SET_LOADING, false);
    }
  },
};
