import { UserState } from './user';

export const UserGetter = {
  IS_LOADING: 'IS_LOADING',
  GET_USER: 'GET_USER',
  GET_STANDARD_USER: 'GET_STANDARD_USER',
};

export default {
  [UserGetter.IS_LOADING](state: UserState) {
    return state.loading;
  },

  [UserGetter.GET_USER](state: UserState) {
    return state.user;
  },

  [UserGetter.GET_STANDARD_USER](state: UserState) {
    return state.standardUsers;
  },
};
