import {
  CreateIntegrationMutation,
  CreateIntegrationMutationVariables,
  UpdateIntegrationMutation,
  UpdateIntegrationMutationVariables,
  FetchDeviceTypeGroupsQueryVariables,
  FetchDeviceTypeGroupsQuery,
  FetchDogCountriesQuery,
  FetchDogCountriesQueryVariables,
  FetchIntegrationQuery,
  FetchIntegrationQueryVariables,
  FetchIntegrationsByAppIdQuery,
  FetchIntegrationsByAppIdQueryVariables,
  FetchIntegrationsQuery,
  FetchIntegrationsQueryVariables,
  FetchTransitionsQuery,
  FetchTransitionsQueryVariables,
  FetchWorkflowQuery,
  FetchWorkflowQueryVariables,
  FetchWorkflowsQuery,
  FetchWorkflowsQueryVariables,
  FetchWorkflowsByProductQuery,
  FetchWorkflowsByProductQueryVariables,
  FetchWorkflowLogsQuery,
  FetchWorkflowLogsQueryVariables,
  FetchTagsQueryVariables,
  FetchTagsQuery,
  StartCertificationMutation,
  StartCertificationMutationVariables,
  StartRecertificationMutation,
  StartRecertificationMutationVariables,
  StartCompleteRecertificationMutation,
  StartCompleteRecertificationMutationVariables,
  UpdateWorkflowStateMutation,
  UpdateWorkflowStateMutationVariables,
  FetchCatSessionsQuery,
  FetchCatSessionsQueryVariables,
  UpdateWorkflowStateLinkMutation,
  UpdateWorkflowStateLinkMutationVariables,
  CreateDirectUploadMutationMutationVariables,
  CreateDirectUploadMutationMutation,
  AttachWorkflowStateFileMutationMutationVariables,
  AttachWorkflowStateFileMutationMutation,
  FetchWorkflowFileUrlQuery,
  FetchWorkflowFileUrlQueryVariables,
  CreateCommentMutationVariables,
  CreateCommentMutation,
  UpdateCommentMutationVariables,
  UpdateCommentMutation,
  CreateCustomDeviceGroupMutation,
  CreateCustomDeviceGroupMutationVariables,
  FetchReportQueryVariables,
  FetchReportQuery,
  WorkflowTransitionMutationVariables,
  WorkflowTransitionMutation,
  CreateReportFiltersMutationVariables,
  CreateReportFiltersMutation,
  UpdateReportFiltersMutationVariables,
  UpdateReportFiltersMutation,
  FetchFiltersQueryVariables,
  FetchFiltersQuery,
  FetchCertificationGroupQueryVariables,
  FetchCertificationGroupQuery,
  FetchCertifiedGroupWorkflowsQueryVariables,
  FetchCertifiedGroupWorkflowsQuery,
  CreateCertificationGroupMutationInput,
  CreateCertificationGroupMutation,
  UpdateCertificationGroupMutationInput,
  UpdateCertificationGroupMutation,
  FetchCertificationGroupsQueryVariables,
  FetchCertificationGroupsQuery,
  AddIntegrationsToCertificationGroupMutationInput,
  AddIntegrationsToCertificationGroupMutation,
  RemoveIntegrationsFromCertificationGroupMutationInput,
  RemoveIntegrationsFromCertificationGroupMutation,
} from '@/generated/graphql';

import { client } from './apollo';
import { ApolloQueryResult, FetchResult } from '@apollo/client';
import createComment from './gql/mutation/createComment.gql';
import updateComment from './gql/mutation/updateComment.gql';
import createIntegration from './gql/mutation/createIntegration.gql';
import updateIntegration from './gql/mutation/updateIntegration.gql';
import startCertification from './gql/mutation/createWorkflow.gql';
import workflowTransition from './gql/mutation/workflowTransition.gql';
import startRecertification from './gql/mutation/createRecertification.gql';
import startCompleteRecertification from './gql/mutation/createCompleteRecertification.gql';
import updateWorkflowState from './gql/mutation/updateWorkflowState.gql';
import fetchIntegration from './gql/query/fetchIntegration.gql';
import fetchIntegrations from './gql/query/fetchIntegrations.gql';
import fetchIntegrationsByAppId from './gql/query/fetchIntegrationsByAppId.gql';
import fetchWorkflows from './gql/query/fetchWorkflows.gql';
import fetchWorkflowsByProduct from './gql/query/fetchWorkflowsByProduct.gql';
import fetchWorkflow from './gql/query/fetchWorkflow.gql';
import fetchWorkflowLogs from './gql/query/fetchWorkflowLogs.gql';
import fetchDogCountries from './gql/query/fetchDogCountries.gql';
import fetchDeviceTypeGroups from './gql/query/fetchDeviceTypeGroups.gql';
import fetchTransitions from './gql/query/fetchTransitions.gql';
import fetchCatSessions from './gql/query/fetchCatSessions.gql';
import updateWorkflowStateLink from './gql/mutation/updateWorkflowStateLink.gql';
import createDirectUploadMutation from './gql/mutation/createDirectUploadMutation.gql';
import attachWorkflowStateFileMutation from './gql/mutation/attachFileToWorkflowState.gql';
import fetchWorkflowFileUrl from './gql/query/fetchWorkflowFileUrl.gql';
import fetchTags from './gql/query/fetchTags.gql';
import createCustomDeviceGroup from './gql/mutation/createCustomDeviceGroup.gql';
import fetchReport from './gql/query/fetchReport.gql';
import createReportFilters from './gql/mutation/createReportFilters.gql';
import updateReportFilters from './gql/mutation/updateReportFilters.gql';
import fetchReportFilters from './gql/query/fetchReportFilters.gql';
import fetchCertificationGroups from './gql/query/fetchCertificationGroups.gql';
import createCertificationGroup from './gql/mutation/createCertificationGroup.gql';
import updateCertificationGroup from './gql/mutation/updateCertificationGroup.gql';
import fetchCertificationGroup from './gql/query/fetchCertificationGroup.gql';
import fetchCertifiedGroupWorkflows from './gql/query/fetchCertifiedGroupWorkflows.gql';
import addIntegrationsToGroup from './gql/mutation/addIntegrationsToCertificationGroup.gql';
import removeIntegrationFromGroup from './gql/mutation/removeIntegrationFromGroup.gql';

export class FoxApi {
  public static async createComment(vars: CreateCommentMutationVariables): Promise<FetchResult<CreateCommentMutation>> {
    return client.mutate<CreateCommentMutation, CreateCommentMutationVariables>({
      variables: vars,
      mutation: createComment,
    });
  }
  public static async updateComment(vars: UpdateCommentMutationVariables): Promise<FetchResult<UpdateCommentMutation>> {
    return client.mutate<UpdateCommentMutation, UpdateCommentMutationVariables>({
      variables: vars,
      mutation: updateComment,
    });
  }
  public static async createIntegration(
    vars: CreateIntegrationMutationVariables
  ): Promise<FetchResult<CreateIntegrationMutation>> {
    return client.mutate<CreateIntegrationMutation, CreateIntegrationMutationVariables>({
      variables: vars,
      mutation: createIntegration,
    });
  }

  public static async startCertification(
    vars: StartCertificationMutationVariables
  ): Promise<FetchResult<StartCertificationMutation>> {
    return client.mutate<StartCertificationMutation, StartCertificationMutationVariables>({
      variables: vars,
      mutation: startCertification,
    });
  }
  public static async workflowTransition(
    vars: WorkflowTransitionMutationVariables
  ): Promise<FetchResult<WorkflowTransitionMutation>> {
    return client.mutate<WorkflowTransitionMutation, WorkflowTransitionMutationVariables>({
      variables: vars,
      mutation: workflowTransition,
    });
  }

  public static async startRecertification(
    vars: StartRecertificationMutationVariables
  ): Promise<FetchResult<StartRecertificationMutation>> {
    return client.mutate<StartRecertificationMutation, StartRecertificationMutationVariables>({
      variables: vars,
      mutation: startRecertification,
    });
  }

  public static async startCompleteRecertification(
    vars: StartCompleteRecertificationMutationVariables
  ): Promise<FetchResult<StartCompleteRecertificationMutation>> {
    return client.mutate<StartCompleteRecertificationMutation, StartCompleteRecertificationMutationVariables>({
      variables: vars,
      mutation: startCompleteRecertification,
    });
  }

  public static async updateWorkflowState(
    vars: UpdateWorkflowStateMutationVariables
  ): Promise<FetchResult<UpdateWorkflowStateMutation>> {
    return client.mutate<UpdateWorkflowStateMutation, UpdateWorkflowStateMutationVariables>({
      variables: vars,
      mutation: updateWorkflowState,
    });
  }

  public static integration(vars: FetchIntegrationQueryVariables): Promise<ApolloQueryResult<FetchIntegrationQuery>> {
    return client.query<FetchIntegrationQuery, FetchIntegrationQueryVariables>({
      variables: vars,
      query: fetchIntegration,
    });
  }

  public static integrationsByAppId(
    vars: FetchIntegrationsByAppIdQueryVariables
  ): Promise<ApolloQueryResult<FetchIntegrationsByAppIdQuery>> {
    return client.query<FetchIntegrationsByAppIdQuery, FetchIntegrationsByAppIdQueryVariables>({
      variables: vars,
      query: fetchIntegrationsByAppId,
    });
  }

  public static searchIntegration(
    vars: FetchIntegrationsQueryVariables
  ): Promise<ApolloQueryResult<FetchIntegrationsQuery>> {
    return client.query<FetchIntegrationsQuery, FetchIntegrationsQueryVariables>({
      variables: vars,
      query: fetchIntegrations,
    });
  }

  public static workflows(vars: FetchWorkflowsQueryVariables): Promise<ApolloQueryResult<FetchWorkflowsQuery>> {
    return client.query<FetchWorkflowsQuery, FetchWorkflowsQueryVariables>({
      variables: vars,
      query: fetchWorkflows,
    });
  }

  public static workflowsByProduct(
    vars: FetchWorkflowsByProductQueryVariables
  ): Promise<ApolloQueryResult<FetchWorkflowsByProductQuery>> {
    return client.query<FetchWorkflowsByProductQuery, FetchWorkflowsByProductQueryVariables>({
      variables: vars,
      query: fetchWorkflowsByProduct,
    });
  }

  public static workflow(vars: FetchWorkflowQueryVariables): Promise<ApolloQueryResult<FetchWorkflowQuery>> {
    return client.query<FetchWorkflowQuery, FetchWorkflowQueryVariables>({
      variables: vars,
      query: fetchWorkflow,
    });
  }

  public static workflowLogs(
    vars: FetchWorkflowLogsQueryVariables
  ): Promise<ApolloQueryResult<FetchWorkflowLogsQuery>> {
    return client.query<FetchWorkflowLogsQuery, FetchWorkflowLogsQueryVariables>({
      variables: vars,
      query: fetchWorkflowLogs,
    });
  }

  public static tags(vars: FetchTagsQueryVariables): Promise<ApolloQueryResult<FetchTagsQuery>> {
    return client.query<FetchTagsQuery, FetchTagsQueryVariables>({
      variables: vars,
      query: fetchTags,
    });
  }

  public static async createDeviceGroup(
    vars: CreateCustomDeviceGroupMutationVariables
  ): Promise<FetchResult<CreateCustomDeviceGroupMutation>> {
    return client.mutate<CreateCustomDeviceGroupMutation, CreateCustomDeviceGroupMutationVariables>({
      variables: vars,
      mutation: createCustomDeviceGroup,
    });
  }

  public static deviceTypeGroups(
    vars: FetchDeviceTypeGroupsQueryVariables
  ): Promise<ApolloQueryResult<FetchDeviceTypeGroupsQuery>> {
    return client.query<FetchDeviceTypeGroupsQuery, FetchDeviceTypeGroupsQueryVariables>({
      variables: vars,
      query: fetchDeviceTypeGroups,
    });
  }

  public static countries(vars: FetchDogCountriesQueryVariables): Promise<ApolloQueryResult<FetchDogCountriesQuery>> {
    return client.query<FetchDogCountriesQuery, FetchDogCountriesQueryVariables>({
      variables: vars,
      query: fetchDogCountries,
    });
  }

  public static transitions(vars: FetchTransitionsQueryVariables): Promise<ApolloQueryResult<FetchTransitionsQuery>> {
    return client.query<FetchTransitionsQuery, FetchTransitionsQueryVariables>({
      variables: vars,
      query: fetchTransitions,
    });
  }

  public static testSessions(vars: FetchCatSessionsQueryVariables): Promise<ApolloQueryResult<FetchCatSessionsQuery>> {
    return client.query<FetchCatSessionsQuery, FetchCatSessionsQueryVariables>({
      variables: vars,
      query: fetchCatSessions,
    });
  }

  public static async updateWorkflowStateLink(
    vars: UpdateWorkflowStateLinkMutationVariables
  ): Promise<FetchResult<UpdateWorkflowStateLinkMutation>> {
    return client.mutate<UpdateWorkflowStateLinkMutation, UpdateWorkflowStateLinkMutationVariables>({
      variables: vars,
      mutation: updateWorkflowStateLink,
    });
  }

  public static async createDirectUpload(
    vars: CreateDirectUploadMutationMutationVariables
  ): Promise<FetchResult<CreateDirectUploadMutationMutation>> {
    return client.mutate<CreateDirectUploadMutationMutation, CreateDirectUploadMutationMutationVariables>({
      variables: vars,
      mutation: createDirectUploadMutation,
    });
  }

  public static async attachFileToState(
    vars: AttachWorkflowStateFileMutationMutationVariables
  ): Promise<FetchResult<AttachWorkflowStateFileMutationMutation>> {
    return client.mutate<AttachWorkflowStateFileMutationMutation, AttachWorkflowStateFileMutationMutationVariables>({
      variables: vars,
      mutation: attachWorkflowStateFileMutation,
    });
  }

  public static workflowFileUrl(
    vars: FetchWorkflowFileUrlQueryVariables
  ): Promise<ApolloQueryResult<FetchWorkflowFileUrlQuery>> {
    return client.query<FetchWorkflowFileUrlQuery, FetchWorkflowFileUrlQueryVariables>({
      variables: vars,
      query: fetchWorkflowFileUrl,
    });
  }

  public static report(vars: FetchReportQueryVariables): Promise<ApolloQueryResult<FetchReportQuery>> {
    return client.query<FetchReportQuery, FetchReportQueryVariables>({
      variables: vars,
      query: fetchReport,
    });
  }
  public static saveReportFilter(
    vars: CreateReportFiltersMutationVariables
  ): Promise<FetchResult<CreateReportFiltersMutation>> {
    return client.mutate<CreateReportFiltersMutation, CreateReportFiltersMutationVariables>({
      variables: vars,
      mutation: createReportFilters,
    });
  }
  public static updateReportFilter(
    vars: UpdateReportFiltersMutationVariables
  ): Promise<FetchResult<UpdateReportFiltersMutation>> {
    return client.mutate<UpdateReportFiltersMutation, UpdateReportFiltersMutationVariables>({
      variables: vars,
      mutation: updateReportFilters,
    });
  }
  public static filters(vars: FetchFiltersQueryVariables): Promise<ApolloQueryResult<FetchFiltersQuery>> {
    return client.query<FetchFiltersQuery, FetchFiltersQueryVariables>({
      variables: vars,
      query: fetchReportFilters,
    });
  }

  public static async updateIntegration(
    vars: UpdateIntegrationMutationVariables
  ): Promise<FetchResult<UpdateIntegrationMutation>> {
    return client.mutate<UpdateIntegrationMutation, UpdateIntegrationMutationVariables>({
      variables: vars,
      mutation: updateIntegration,
    });
  }

  public static certificationGroups(
    vars: FetchCertificationGroupsQueryVariables
  ): Promise<ApolloQueryResult<FetchCertificationGroupsQuery>> {
    return client.query<FetchCertificationGroupsQuery, FetchCertificationGroupsQueryVariables>({
      variables: vars,
      query: fetchCertificationGroups,
    });
  }

  public static createCertificationGroup(
    vars: CreateCertificationGroupMutationInput
  ): Promise<FetchResult<CreateCertificationGroupMutation>> {
    return client.mutate<CreateCertificationGroupMutation, CreateCertificationGroupMutationInput>({
      variables: vars,
      mutation: createCertificationGroup,
    });
  }

  public static updateCertificationGroup(
    vars: UpdateCertificationGroupMutationInput
  ): Promise<FetchResult<UpdateCertificationGroupMutation>> {
    return client.mutate<UpdateCertificationGroupMutation, UpdateCertificationGroupMutationInput>({
      variables: vars,
      mutation: updateCertificationGroup,
    });
  }

  public static certificationGroup(
    vars: FetchCertificationGroupQueryVariables
  ): Promise<ApolloQueryResult<FetchCertificationGroupQuery>> {
    return client.query<FetchCertificationGroupQuery, FetchCertificationGroupQueryVariables>({
      variables: vars,
      query: fetchCertificationGroup,
    });
  }

  public static certifiedGroupWorkflows(
    vars: FetchCertifiedGroupWorkflowsQueryVariables
  ): Promise<ApolloQueryResult<FetchCertifiedGroupWorkflowsQuery>> {
    return client.query<FetchCertifiedGroupWorkflowsQuery, FetchCertifiedGroupWorkflowsQueryVariables>({
      variables: vars,
      query: fetchCertifiedGroupWorkflows,
    });
  }

  public static addIntegrationsToGroup(
    vars: AddIntegrationsToCertificationGroupMutationInput
  ): Promise<FetchResult<AddIntegrationsToCertificationGroupMutation>> {
    return client.mutate<AddIntegrationsToCertificationGroupMutation, AddIntegrationsToCertificationGroupMutationInput>(
      {
        variables: vars,
        mutation: addIntegrationsToGroup,
      }
    );
  }

  public static removeIntegrationFromGroup(
    vars: RemoveIntegrationsFromCertificationGroupMutationInput
  ): Promise<FetchResult<RemoveIntegrationsFromCertificationGroupMutation>> {
    return client.mutate<
      RemoveIntegrationsFromCertificationGroupMutation,
      RemoveIntegrationsFromCertificationGroupMutationInput
    >({
      variables: vars,
      mutation: removeIntegrationFromGroup,
    });
  }
}
