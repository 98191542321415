import {
  TreeNode,
  Os,
  Country,
  Product,
  DeviceTypeGroup,
  Scalars,
  ReportFilter,
  SavedFilterDaterange,
  DogStatus,
} from '@/generated/graphql';
import { TranslateResult } from 'vue-i18n';
import Vue from 'vue';
import { ReportState } from './report';
export interface SetSelectedEntities {
  parentId: number;
  selection: number[];
}

export const ReportMutations = {
  SET_DEVICE_TYPES: 'SET_DEVICE_TYPES',
  UPDATE_SELECTED_DEVICE: 'UPDATE_SELECTED_DEVICE',
  UPDATE_DEVICE_TYPES: 'UPDATE_DEVICE_TYPES',
  SET_PARENT_EXCERPT: 'SET_PARENT_EXCERPT',
  SET_SELECTED_PARENT: 'SET_SELECTED_PARENT',
  REMOVE_SELECTED_PARENT: 'REMOVE_SELECTED_PARENT',
  SET_SELECTED_ENTITIES: 'SET_SELECTED_ENTITIES',
  SET_SELECTED_OS: 'SET_SELECTED_OS',
  SET_SELECTED_COUNTRIES: 'SET_SELECTED_COUNTRIES',
  SET_SELECTED_PRODUCTS: 'SET_SELECTED_PRODUCTS',
  SET_SELECTED_DEVICES: 'SET_SELECTED_DEVICES',
  SET_SELECTED_DATE: 'SET_SELECTED_DATE',
  SET_SELECTED_STATUS: 'SET_SELECTED_STATUS',
  REMOVE_SELECTED_OS: 'REMOVE_SELECTED_OS',
  REMOVE_SELECTED_COUNTRY: 'REMOVE_SELECTED_COUNTRY',
  REMOVE_SELECTED_PRODUCT: 'REMOVE_SELECTED_PRODUCT',
  REMOVE_SELECTED_DEVICE: 'REMOVE_SELECTED_DEVICE',
  REMOVE_SELECTED_STATUS: 'REMOVE_SELECTED_STATUS',
  SET_LOADING: 'SET_LOADING',
  SET_REPORT: 'SET_REPORT',
  SHOW_EXPORT_DIALOG: 'SHOW_EXPORT_DIALOG',
  SHOW_SAVE_FILTER_DIALOG: 'SHOW_SAVE_FILTER_DIALOG',
  SHOW_LOAD_FILTER_DIALOG: 'SHOW_LOAD_FILTER_DIALOG',
  SET_LOADED_FILTER: 'SET_LOADED_FILTER',
};

export default {
  [ReportMutations.REMOVE_SELECTED_PARENT](state: ReportState, value: number) {
    delete state.selectedParents[value];
  },
  [ReportMutations.SET_SELECTED_PARENT](state: ReportState, value: TreeNode) {
    if (!state.selectedParents[value.id]) {
      state.selectedParents = {
        ...state.selectedParents,
        [value.id]: {
          entity: value,
          selected: [],
          excerpt: null,
        },
      };
    }
  },
  [ReportMutations.SET_SELECTED_ENTITIES](state: ReportState, value: SetSelectedEntities) {
    const selectedParents = state.selectedParents;
    selectedParents[value.parentId].selected = value.selection;
    Vue.set(state, 'selectedParents', selectedParents);
  },
  [ReportMutations.SET_DEVICE_TYPES](state: ReportState, value: DeviceTypeGroup[]) {
    state.deviceTypes = value;
  },
  [ReportMutations.UPDATE_DEVICE_TYPES](state: ReportState, value: DeviceTypeGroup) {
    state.deviceTypes.push(value);
  },
  [ReportMutations.UPDATE_SELECTED_DEVICE](state: ReportState, value: DeviceTypeGroup) {
    state.selectedDevices.push(value);
  },

  [ReportMutations.SET_PARENT_EXCERPT](state: ReportState, value: TreeNode) {
    const selected = state.selectedParents[value.id];
    selected.excerpt = value;
    Vue.set(state.selectedParents, value.id, selected);
  },
  [ReportMutations.SET_SELECTED_OS](state: ReportState, value: { code: Os; name: TranslateResult }[]) {
    state.selectedOs = value;
  },
  [ReportMutations.REMOVE_SELECTED_OS](state: ReportState, code: string) {
    const index = state.selectedOs?.findIndex(e => e.code === code);
    if (index !== undefined && index > -1) state.selectedOs?.splice(index, 1);
  },
  [ReportMutations.SET_SELECTED_COUNTRIES](state: ReportState, value: Country[]) {
    state.selectedCountries = value;
  },
  [ReportMutations.REMOVE_SELECTED_COUNTRY](state: ReportState, code: string) {
    const index = state.selectedCountries?.findIndex(e => e.code === code);
    if (index !== undefined && index > -1) state.selectedCountries?.splice(index, 1);
  },
  [ReportMutations.SET_SELECTED_PRODUCTS](state: ReportState, value: { code: Product; name: TranslateResult }[]) {
    state.selectedProducts = value;
  },
  [ReportMutations.REMOVE_SELECTED_PRODUCT](state: ReportState, code: string) {
    const index = state.selectedProducts?.findIndex(e => e.code === code);
    if (index !== undefined && index > -1) state.selectedProducts?.splice(index, 1);
  },
  [ReportMutations.SET_SELECTED_DEVICES](state: ReportState, value: DeviceTypeGroup[]) {
    state.selectedDevices = value;
  },
  [ReportMutations.REMOVE_SELECTED_DEVICE](state: ReportState, id: number) {
    const index = state.selectedDevices?.findIndex(e => e.id === id);
    if (index !== undefined && index > -1) state.selectedDevices?.splice(index, 1);
  },
  [ReportMutations.SET_SELECTED_DATE](state: ReportState, value: SavedFilterDaterange | null) {
    state.selectedDate = value;
  },
  [ReportMutations.SET_SELECTED_STATUS](state: ReportState, value: { code: DogStatus; name: TranslateResult }[]) {
    state.selectedStatus = value;
  },
  [ReportMutations.REMOVE_SELECTED_STATUS](state: ReportState, code: DogStatus) {
    const index = state.selectedStatus?.findIndex(e => e.code === code);
    if (index !== undefined && index > -1) state.selectedStatus?.splice(index, 1);
  },
  [ReportMutations.SET_LOADING](state: ReportState, value: boolean) {
    state.loading = value;
  },
  [ReportMutations.SET_REPORT](state: ReportState, value: Scalars['Json']) {
    state.report = value;
  },
  [ReportMutations.SHOW_EXPORT_DIALOG](state: ReportState, value: boolean) {
    state.showReportDialog = value;
  },
  [ReportMutations.SHOW_SAVE_FILTER_DIALOG](state: ReportState, value: boolean) {
    state.showSaveFilterDialog = value;
  },
  [ReportMutations.SHOW_LOAD_FILTER_DIALOG](state: ReportState, value: boolean) {
    state.showLoadFilterDialog = value;
  },
  [ReportMutations.SET_LOADED_FILTER](state: ReportState, value: ReportFilter) {
    state.loadedFilter = value;
  },
};
