import { client } from './apollo';
import tokenQuery from './gql/query/fetchToken.gql';
import fetchGroupUsers from './gql/query/fetchGroupUsers.gql';
import { FetchTokenQuery, FetchGroupUsersQuery, FetchGroupUsersQueryVariables } from '@/generated/graphql';
import { ApolloQueryResult } from '@apollo/client';

export class UserApi {
  public static async token(): Promise<ApolloQueryResult<FetchTokenQuery>> {
    return client.query<FetchTokenQuery>({
      query: tokenQuery,
    });
  }

  public static async groupUsers(
    vars: FetchGroupUsersQueryVariables
  ): Promise<ApolloQueryResult<FetchGroupUsersQuery>> {
    return client.query<FetchGroupUsersQuery, FetchGroupUsersQueryVariables>({
      variables: vars,
      query: fetchGroupUsers,
    });
  }
}
