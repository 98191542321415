import {
  Application,
  Brand,
  C6s,
  Channel,
  Country,
  Integration,
  TreeNode,
  Workflow,
  AssignedUser,
} from '@/generated/graphql';
import actions from './actions.core';
import getters from './getter.core';
import mutations from './mutations.core';

export interface CoreState {
  c6s: C6s | null;
  node: TreeNode | null;
  ddrsEntry: Brand | Channel | null;
  application: Application | null;
  applications: Application[];
  assignedUsersHen: AssignedUser[] | null;
  selectedIntegration: Integration | null;
  selectedIntegrationWorkflows: Workflow[] | null;
  countries: Country[];
}

export const state: CoreState = {
  countries: [],
  c6s: null,
  node: null,
  application: null,
  ddrsEntry: null,
  applications: [],
  assignedUsersHen: [],
  selectedIntegration: null,
  selectedIntegrationWorkflows: null,
};

const Core = {
  namespaced: true as true,
  state,
  getters,
  mutations,
  actions,
};

export default Core;
