import { CatState } from './cat';

export const CatGetter = {
  GET_TESTSESSIONS: 'GET_TESTSESSIONS',
};

export default {
  [CatGetter.GET_TESTSESSIONS](state: CatState) {
    return state.testsessions;
  },
};
