






import { WorkflowGetter } from '@/store/workflow/getter.workflow';
import { ClientInfo } from 'centrifuge';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Viewer from '@/components/Viewer.vue';
@Component({
  components: { Viewer },
})
export default class Viewers extends Vue {
  @Getter(`workflow/${WorkflowGetter.GET_WORKFLOW_VIEWER}`)
  workflowViewers!: { [key: string]: ClientInfo };

  @Watch('workflowViewers', { deep: true })
  onViewerChage() {
    this.$forceUpdate();
  }
}
