import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import './plugins/common';
import './plugins/moment';
import './plugins/sentry';
import './plugins/htmlToCanvas';

// @ts-ignore
import { allCap, avatarName, cap, stripSubject } from './helpers/filters';
import { Endpoints, IdentityVuePlugin } from '@nielsen-media/identity-spa';

Vue.config.productionTip = false;

const endpoints: Endpoints = {
  authentication: process.env.VUE_APP_IDENTITY_AUTHENTICATION!,
  authorization: process.env.VUE_APP_IDENTITY_AUTHORIZATION!,
};

Vue.filter('avatarName', avatarName);
Vue.filter('stripSubject', stripSubject);
Vue.filter('cap', cap);
Vue.filter('allCap', allCap);
Vue.use(IdentityVuePlugin, { endpoints, router, immediate: true });

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app');
