import { SearchState } from './search';

export const SearchGetter = {
  GET_DEVICE_TYPE_GROUPS: 'GET_DEVICE_TYPE_GROUPS',
  GET_FILTERS: 'GET_FILTERS',
  GET_ITEMS_PER_PAGE: 'GET_ITEMS_PER_PAGE',
  GET_PAGE: 'GET_PAGE',
  GET_PAGE_INFO: 'GET_PAGE_INFO',
  GET_SEARCHTERM: 'GET_SEARCHTERM',
  GET_SEARCH_RESULT: 'GET_SEARCH_RESULT',
  GET_TOTAL_COUNT: 'GET_TOTAL_COUNT',
  IS_LOADING: 'IS_LOADING',
};

export default {
  [SearchGetter.GET_DEVICE_TYPE_GROUPS](state: SearchState) {
    return state.deviceTypeGroups;
  },
  [SearchGetter.GET_FILTERS](state: SearchState) {
    return state.filters;
  },
  [SearchGetter.GET_ITEMS_PER_PAGE](state: SearchState) {
    return state.itemsPerPage;
  },
  [SearchGetter.GET_PAGE](state: SearchState) {
    return state.page;
  },
  [SearchGetter.GET_SEARCHTERM](state: SearchState) {
    return state.searchTerm;
  },
  [SearchGetter.GET_SEARCH_RESULT](state: SearchState) {
    return state.result;
  },
  [SearchGetter.GET_TOTAL_COUNT](state: SearchState) {
    return state.totalCount;
  },
  [SearchGetter.GET_PAGE_INFO](state: SearchState) {
    return state.pageInfo;
  },
  [SearchGetter.IS_LOADING](state: SearchState) {
    return state.loading;
  },
};
