





















import { Component } from 'vue-property-decorator';
import { navigation } from '@/router/navigation';
import NiApp from '@/components/external/ni-app/NiApp.vue';
//@ts-ignore
import { mixins } from 'vue-class-component';
import { WithEventBus } from '@/mixins/eventBus';
import { EVENTS } from './constants';
import BreadCrumbs from './components/common/BreadCrumbs.vue';
import Viewers from '@/components/Viewers.vue';
import { Action } from 'vuex-class';
import { ActionMethod } from 'vuex';
import { UserActions } from '@/store/user/actions.user';
import { generateName } from './helpers/userHelper';

@Component({
  components: {
    NiApp,
    BreadCrumbs,
    Viewers,
  },
})
export default class App extends mixins(WithEventBus) {
  showSnackbar = false;
  snackbarText = '';
  showNavBar = true;
  authenticated = false;
  navList = navigation;
  knownApps = [];
  icon = '/images/FOX.png';
  user = null as { name: string; email: string; acronym: string } | null;

  @Action(`user/${UserActions.FETCH_TOKEN}`)
  performFetchToken!: ActionMethod;

  @Action(`user/${UserActions.UPDATE_CURRENT_USER}`)
  updateCurrentUser!: ActionMethod;

  get title(): string {
    return process.env.VUE_APP_AUTH_BYPASS ? 'FOX-E2E' : 'FOX';
  }

  toggleNavBar() {
    this.showNavBar = !this.showNavBar;
  }

  async mounted() {
    await this.$identity.authenticate(() => {
      this.authenticated = true;
      const email = this.$identity.principal.email;
      const name = generateName(email);
      this.user = {
        name: name,
        email: email.toLowerCase(),
        acronym: this.$identity.acronym(),
      };
      this.performFetchToken();
      this.updateCurrentUser(this.user);
    });
    this.eventBus.$on(EVENTS.GLOBAL_ALERT, (alertData: Error) => this.showAlert(alertData));
  }

  showAlert(data: Error) {
    this.showSnackbar = true;
    this.snackbarText = data.message;
  }
}
