import { Integration, AssignedUser } from '@/generated/graphql';
import { GroupsState } from './groups';
import { CertificationGroupFrontEnd } from '@/store/groups/groups';
export const GroupsMutations = {
  SET_GROUPS: 'SET_GROUPS',
  SET_GROUPS_LOADING: 'SET_GROUPS_LOADING',
  SET_GROUP: 'SET_GROUP',
  SET_GROUP_LOADING: 'SET_GROUP_LOADING',
  SET_INTEGRATIONS: 'SET_INTEGRATIONS',
  SET_INTEGRATIONS_LOADING: 'SET_INTEGRATIONS_LOADING',
  SET_MVE_LOADING: 'SET_MVE_LOADING',
  SET_USERS_FROM_HIERACHY: 'SET_USERS_FROM_HIERACHY',
  SET_RECERT_LOADING: 'SET_RECERT_LOADING',
};
export default {
  [GroupsMutations.SET_GROUPS](state: GroupsState, groups: CertificationGroupFrontEnd[]) {
    state.groups = groups;
  },
  [GroupsMutations.SET_GROUPS_LOADING](state: GroupsState, loading: boolean) {
    state.groupsLoading = loading;
  },
  [GroupsMutations.SET_GROUP](state: GroupsState, group: CertificationGroupFrontEnd) {
    state.group = group;
  },
  [GroupsMutations.SET_GROUP_LOADING](state: GroupsState, loading: boolean) {
    state.groupLoading = loading;
  },
  [GroupsMutations.SET_INTEGRATIONS](state: GroupsState, integrations: Integration[]) {
    state.integrations = integrations;
  },
  [GroupsMutations.SET_INTEGRATIONS_LOADING](state: GroupsState, loading: boolean) {
    state.integrationsLoading = loading;
  },
  [GroupsMutations.SET_MVE_LOADING](state: GroupsState, loading: boolean) {
    state.mveLoading = loading;
  },
  [GroupsMutations.SET_USERS_FROM_HIERACHY](state: GroupsState, v: AssignedUser[]) {
    state.assignedUsersHen = v;
  },
  [GroupsMutations.SET_RECERT_LOADING](state: GroupsState, loading: boolean) {
    state.recertLoading = loading;
  },
};
