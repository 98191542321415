import { client } from './apollo';
import fetchAssignedUsers from './gql/query/fetchAssignedUsers.gql';
import fetchAllUsersForHierarchy from './gql/query/fetchAllUsersForHierarchy.gql';
import {
  FetchAssignedUsersQuery,
  FetchAssignedUsersQueryVariables,
  FetchAllUsersForHierarchyQuery,
  FetchAllUsersForHierarchyQueryVariables,
} from '@/generated/graphql';
import { ApolloQueryResult } from '@apollo/client';

export class HenApi {
  public static async assignedUsers(
    vars: FetchAssignedUsersQueryVariables
  ): Promise<ApolloQueryResult<FetchAssignedUsersQuery>> {
    return client.query<FetchAssignedUsersQuery, FetchAssignedUsersQueryVariables>({
      variables: vars,
      query: fetchAssignedUsers,
    });
  }

  public static async allUsersForHierarchy(
    vars: FetchAllUsersForHierarchyQueryVariables
  ): Promise<ApolloQueryResult<FetchAllUsersForHierarchyQuery>> {
    return client.query<FetchAllUsersForHierarchyQuery, FetchAllUsersForHierarchyQueryVariables>({
      variables: vars,
      query: fetchAllUsersForHierarchy,
    });
  }
}
