






































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { UserAvatar } from '@nielsen-media/components';

@Component({
  components: { UserAvatar },
  props: {},
  methods: {
    logoutHandler() {
      // @ts-ignore
      console.log('not implemented yet');
    },
  },
})
export default class AppBar extends Vue {
  @Prop() title!: string;
  @Prop() icon!: string;
  @Prop() apps!: {}[];
  @Prop() currentUser!: {};

  imgError = false;
}
