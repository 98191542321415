import { ApolloLink, ApolloClient, InMemoryCache, HttpLink, ServerError } from '@apollo/client/core';
import { ErrorLink, ErrorResponse, onError } from '@apollo/client/link/error';
import { getIdentity, HttpStatuses } from '@nielsen-media/identity-spa';

const cache = new InMemoryCache({ addTypename: false });

const errorLink = onError((({ networkError }: ErrorResponse) => {
  if (networkError && (networkError as ServerError).statusCode === HttpStatuses.Unauthorized) {
    getIdentity().logout();
    getIdentity().authenticate(); // <--- this is the important part. Also the server needs to return a 401 if the user is not authenticated.
  }
}) as ErrorLink.ErrorHandler);

export const client = new ApolloClient({
  link: ApolloLink.from([
    errorLink.concat(
      new HttpLink({
        uri: process.env.VUE_APP_API_URL,
        credentials: 'include',
      })
    ),
  ]),
  credentials: 'include',
  cache: cache,
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
  },
});
