import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import workflow from '@/store/workflow/workflow';
import core, { CoreState } from '@/store/core/core';
import search, { SearchState } from '@/store/search/search';
import report, { ReportState } from '@/store/report/report';
import groups, { GroupsState } from '@/store/groups/groups';
import user, { UserState } from '@/store/user/user';
import cat, { CatState } from '@/store/cat/cat';
import { WorkflowState } from '@/generated/graphql';

Vue.use(Vuex);

export interface RootState {
  search: SearchState;
  core: CoreState;
  workflow: WorkflowState;
  user: UserState;
  cat: CatState;
  report: ReportState;
  groups: GroupsState;
}

const store = new Store<RootState>({
  modules: {
    search,
    workflow,
    core,
    user,
    cat,
    report,
    groups,
  },
});

export default store;
