import { ActionContext } from 'vuex';
import { CatState } from './cat';
import { FetchCatSessionsQueryVariables } from '@/generated/graphql';
import { FoxApi } from '@/api/fox';
import { fetchSafe } from '@/helpers/storeHelper';
export const CatActions = {
  FETCH_TESTSESSION: 'FETCH_TESTSESSIONS',
};

export default {
  async [CatActions.FETCH_TESTSESSION](context: ActionContext<CatState, {}>, payload: FetchCatSessionsQueryVariables) {
    const response = await fetchSafe(FoxApi.testSessions, payload);
    return response?.fetchCatSessions;
  },
};
