import Vue from 'vue';
import * as Sentry from '@sentry/vue';

if (process.env.VUE_APP_SENTRY_DSN && !process.env.VUE_APP_AUTH_BYPASS) {
  Sentry.init({
    Vue: Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_ENV,
  });
}
