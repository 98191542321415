




import { ClientInfo } from 'centrifuge';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { UserAvatar } from '@nielsen-media/components';
import { generateName } from '../helpers/userHelper';

@Component({
  components: { UserAvatar },
})
export default class Viewers extends Vue {
  @Prop()
  viewer!: ClientInfo;

  created() {
    this.viewer.conn_info['name'] = generateName(this.viewer.user!);
  }
}
