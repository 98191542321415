import { CoreState } from './core';
import { Country } from '@/generated/graphql';

export const CoreGetter = {
  GET_COUNTRIES: 'GET_COUNTRIES',
  GET_SORTED_COUNTRIES: 'GET_SORTED_COUNTRIES',
  GET_C6S: 'GET_C6S',
  GET_NODE: 'GET_NODE',
  GET_APPLICATION: 'GET_APPLICATION',
  GET_APPLICATIONS: 'GET_APPLICATIONS',
  GET_USERS_FROM_HEN: 'GET_USERS_FROM_HEN',
  GET_DDRS_ENTRY: 'GET_DDRS_ENTRY',
  GET_SELECTED_INTEGRATION: 'GET_SELECTED_INTEGRATION',
  GET_SELECTED_INTEGRATION_WORKFLOWS: 'GET_SELECTED_INTEGRATION_WORKFLOWS',
  GET_RECERTIFIABILITY: 'GET_RECERTIFIABILITY',
};

const currentActiveCountries = ['DE', 'DK', 'IT', 'US', 'SE'];

export default {
  [CoreGetter.GET_C6S](state: CoreState) {
    return state.c6s;
  },

  [CoreGetter.GET_NODE](state: CoreState) {
    return state.node;
  },

  [CoreGetter.GET_APPLICATION](state: CoreState) {
    return state.application;
  },

  [CoreGetter.GET_APPLICATIONS](state: CoreState) {
    return state.applications.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
  },

  [CoreGetter.GET_USERS_FROM_HEN](state: CoreState) {
    return state.assignedUsersHen;
  },

  [CoreGetter.GET_DDRS_ENTRY](state: CoreState) {
    return state.ddrsEntry;
  },

  [CoreGetter.GET_SELECTED_INTEGRATION](state: CoreState) {
    return state.selectedIntegration;
  },

  [CoreGetter.GET_SELECTED_INTEGRATION_WORKFLOWS](state: CoreState) {
    return state.selectedIntegrationWorkflows;
  },

  [CoreGetter.GET_SELECTED_INTEGRATION_WORKFLOWS](state: CoreState) {
    return state.selectedIntegrationWorkflows;
  },
  [CoreGetter.GET_COUNTRIES](state: CoreState) {
    return state.countries;
  },
  [CoreGetter.GET_SORTED_COUNTRIES](state: CoreState) {
    let mostRecent = 0;
    // more convienient for current subset of users
    const mostSelectedCountries = state.countries.reduce((acc, e) => {
      if (e && e.code) {
        if (currentActiveCountries.indexOf(e.code) > -1) {
          acc.splice(mostRecent, 0, e);
          mostRecent += 1;
        } else {
          acc.push(e);
        }
      }
      return acc;
    }, [] as Country[]);
    return mostSelectedCountries;
  },
  [CoreGetter.GET_RECERTIFIABILITY](state: CoreState) {
    return state.selectedIntegration?.recertifiable;
  },
};
