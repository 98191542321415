import { GroupsState } from './groups';
import { Integration } from '@/generated/graphql';
export const GroupsGetter = {
  GET_GROUPS: 'GET_GROUPS',
  GET_GROUP: 'GET_GROUP',
  GET_GROUPS_LOADING: 'GET_GROUPS_LOADING',
  GET_GROUP_LOADING: 'GET_GROUP_LOADING',
  GET_INTEGRATIONS: 'GET_INTEGRATIONS',
  GET_INTEGRATIONS_LOADING: 'GET_INTEGRATIONS_LOADING',
  GET_MVE_LOADING: 'GET_MVE_LOADING',
  GET_USERS_FROM_HEN: 'GET_USERS_FROM_HEN',
  GET_GROUP_INTEGRATIONS: 'GET_GROUP_INTEGRATIONS',
  GET_RECERT_LOADING: 'GET_RECERT_LOADING',
};

export default {
  [GroupsGetter.GET_GROUPS](state: GroupsState) {
    return state.groups;
  },
  [GroupsGetter.GET_GROUP](state: GroupsState) {
    return state.group;
  },
  [GroupsGetter.GET_GROUPS_LOADING](state: GroupsState) {
    return state.groupsLoading;
  },
  [GroupsGetter.GET_GROUP_LOADING](state: GroupsState) {
    return state.groupLoading;
  },
  [GroupsGetter.GET_INTEGRATIONS](state: GroupsState) {
    return state.integrations;
  },
  [GroupsGetter.GET_GROUP_INTEGRATIONS](state: GroupsState) {
    return (state.group as { integrations: Integration[] }).integrations;
  },
  [GroupsGetter.GET_INTEGRATIONS_LOADING](state: GroupsState) {
    return state.integrationsLoading;
  },
  [GroupsGetter.GET_MVE_LOADING](state: GroupsState) {
    return state.mveLoading;
  },
  [GroupsGetter.GET_USERS_FROM_HEN](state: GroupsState) {
    return state.assignedUsersHen;
  },
  [GroupsGetter.GET_RECERT_LOADING](state: GroupsState) {
    return state.recertLoading;
  },
};
