















import { ROUTER } from '@/constants';
import Vue from 'vue';
import { MutationMethod } from 'vuex';
import { SearchGetter } from '@/store/search/getter.search';
import { SearchMutations } from '@/store/search/mutations.search';
import { Component, Prop } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import { IntegrationsFilters } from '@/store/search/search';
import { filtersToQueryString } from '@/helpers/routeHelper';

@Component({ components: {} })
export default class SearchBar extends Vue {
  name = 'SearchBar';

  @Prop({ default: () => false })
  flat!: boolean;

  @Prop({ default: () => false })
  outlined!: boolean;

  @Prop({ default: () => false })
  solo!: boolean;

  @Prop({ default: () => true })
  changeRoute!: boolean;

  @Getter(`search/${SearchGetter.GET_FILTERS}`)
  filters!: IntegrationsFilters;

  @Getter(`search/${SearchGetter.GET_SEARCHTERM}`)
  _getSearchTerm!: string;

  @Mutation(`search/${SearchMutations.RESET_SEARCH}`)
  resetSearch!: MutationMethod;

  @Mutation(`search/${SearchMutations.SET_SEARCHTERM}`)
  _setSearchTerm!: MutationMethod;

  get searchTerm() {
    return this._getSearchTerm;
  }

  set searchTerm(val: string) {
    this._setSearchTerm(val);
  }

  search() {
    this.resetSearch();
    if (this.changeRoute) {
      const queryFilters = filtersToQueryString(this.filters);
      this.$router
        .push({
          name: ROUTER.SEARCH_RESULT,
          query: {
            searchTerm: this.searchTerm as string,
            ...queryFilters,
          },
        })
        .catch(err => console.error(err));
    }

    this.$emit('do-search');
  }
}
