import { AssignedUser, CertificationGroup, Integration } from '@/generated/graphql';
import actions from './actions.groups';
import getters from './getter.groups';
import mutations from './mutations.groups';

export interface CertificationGroupFrontEnd extends CertificationGroup {
  user: AssignedUser;
}
export interface GroupsState {
  groups: CertificationGroupFrontEnd[];
  groupsLoading: boolean;
  group: CertificationGroupFrontEnd | {};
  groupLoading: boolean;
  integrations: Integration[];
  integrationsLoading: boolean;
  mveLoading: boolean;
  recertLoading: boolean;
  assignedUsersHen: AssignedUser[] | null;
}

export const state: GroupsState = {
  groups: [],
  groupsLoading: false,
  group: {} as CertificationGroupFrontEnd,
  groupLoading: false,
  integrations: [],
  integrationsLoading: false,
  mveLoading: false,
  recertLoading: false,
  assignedUsersHen: [],
};

const Groups = {
  namespaced: true as true,
  state,
  getters,
  mutations,
  actions,
};

export default Groups;
