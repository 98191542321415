import { setSocketId } from '@/helpers/localStorageHelper';
import Centrifuge, { ClientInfo, JoinLeaveContext } from 'centrifuge';

export interface SubscribePayload {
  integrationId: number;
  product: string;
}

export function generateChannelName(p: SubscribePayload) {
  return `fox:${process.env.VUE_APP_CENTRIFUGE_TOPIC_PREFIX}${p.integrationId}-${p.product}`;
}

export interface Publication {
  lock: boolean;
}

export interface PublicationResult {
  info?: ClientInfo;
  data: Publication;
}

export interface CentrifugeCallbacks {
  publish(message: PublicationResult): void;
  join(message: JoinLeaveContext): void;
  leave(message: JoinLeaveContext): void;
}

export class CentrifugeWrap {
  private client: Centrifuge | null = null;
  private _ready: Promise<Centrifuge | null> | null = new Promise(r => {
    this.readyResolve = r;
  });
  private readyResolve!: (value: Centrifuge | PromiseLike<Centrifuge | null> | null) => void;

  get ready(): Promise<Centrifuge | null> | null {
    return this._ready;
  }

  async init(token: string): Promise<Centrifuge | null> {
    this._ready = new Promise((resolve, reject) => {
      this.client = new Centrifuge(process.env.VUE_APP_WEBSOCKET_ENDPOINT!, {});
      this.client.on('connect', context => {
        setSocketId(context.client);
        resolve(this.client);
        this.readyResolve(this.client);
      });
      try {
        this.client.setToken(token);
        this.client.connect();
      } catch (e) {
        console.error(e);
        this.client = null;
        reject(e);
      }
    });
    return this._ready;
  }

  subscribe(p: SubscribePayload, callbacks: CentrifugeCallbacks) {
    const preSub = this.getSubscription(p);
    if (preSub) {
      return preSub;
    }
    return this.client?.subscribe(generateChannelName(p), callbacks);
  }
  unsubscribe(p: SubscribePayload) {
    this.getSubscription(p)?.unsubscribe();
  }
  publish(p: SubscribePayload, data: Publication) {
    this.getSubscription(p)?.publish(data);
  }
  presence(p: SubscribePayload) {
    return this.getSubscription(p)?.presence();
  }

  getSubscription(p: SubscribePayload) {
    return this.client?.getSub(generateChannelName(p));
  }
  isConnected(): boolean {
    return this.client ? this.client?.isConnected() : false;
  }
}

export const centrifugeClient: CentrifugeWrap = new CentrifugeWrap();

/**
 * initiates centrifuge connection
 *
 * TODO: handle case where token is not present or request failed
 * @param token The centrifuge token
 */
export async function setupCentrifugeClient(token: string) {
  if (centrifugeClient.isConnected()) return centrifugeClient;
  await centrifugeClient.init(token);
  return centrifugeClient;
}
