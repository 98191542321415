import { client } from './apollo';
import searchQuery from './gql/query/fetchOwlSearch.gql';
import fetchOwlFind from './gql/query/fetchOwlFind.gql';
import c6sQuery from './gql/query/fetchOwlC6s.gql';
import brandQuery from './gql/query/fetchOwlBrand.gql';
import channelQuery from './gql/query/fetchOwlChannel.gql';
import fetchOwlExcerpt from './gql/query/fetchOwlExcerpt.gql';
import {
  FetchOwlBrandQuery,
  FetchOwlBrandQueryVariables,
  FetchOwlC6sQuery,
  FetchOwlC6sQueryVariables,
  FetchOwlChannelQuery,
  FetchOwlChannelQueryVariables,
  FetchOwlExcerptQuery,
  FetchOwlExcerptQueryVariables,
  FetchOwlFindQuery,
  FetchOwlFindQueryVariables,
  FetchOwlTreeNodesQuery,
  FetchOwlTreeNodesQueryVariables,
} from '@/generated/graphql';
import { ApolloQueryResult } from '@apollo/client';

export class OwlApi {
  public static async c6s(vars: FetchOwlC6sQueryVariables): Promise<ApolloQueryResult<FetchOwlC6sQuery>> {
    return client.query<FetchOwlC6sQuery, FetchOwlC6sQueryVariables>({
      variables: vars,
      query: c6sQuery,
    });
  }

  public static async find(vars: FetchOwlFindQueryVariables): Promise<ApolloQueryResult<FetchOwlFindQuery>> {
    return client.query<FetchOwlFindQuery, FetchOwlFindQueryVariables>({
      variables: vars,
      query: fetchOwlFind,
    });
  }
  public static async search(
    vars: FetchOwlTreeNodesQueryVariables
  ): Promise<ApolloQueryResult<FetchOwlTreeNodesQuery>> {
    return client.query<FetchOwlTreeNodesQuery, FetchOwlTreeNodesQueryVariables>({
      variables: vars,
      query: searchQuery,
    });
  }

  public static async channel(vars: FetchOwlChannelQueryVariables): Promise<ApolloQueryResult<FetchOwlChannelQuery>> {
    return client.query<FetchOwlChannelQuery, FetchOwlChannelQueryVariables>({
      variables: vars,
      query: channelQuery,
    });
  }

  public static async brand(vars: FetchOwlBrandQueryVariables): Promise<ApolloQueryResult<FetchOwlBrandQuery>> {
    return client.query<FetchOwlBrandQuery, FetchOwlBrandQueryVariables>({
      variables: vars,
      query: brandQuery,
    });
  }

  public static async excerpt(vars: FetchOwlExcerptQueryVariables): Promise<ApolloQueryResult<FetchOwlExcerptQuery>> {
    return client.query<FetchOwlExcerptQuery, FetchOwlExcerptQueryVariables>({
      variables: vars,
      query: fetchOwlExcerpt,
    });
  }
}
