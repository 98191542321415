import { DogApi } from '@/api/dog';

import { ActionContext } from 'vuex';
import { CoreMutations } from './mutations.core';
import { CoreState } from './core';
import {
  Country,
  CreateIntegrationMutationVariables,
  FetchAssignedUsersQueryVariables,
  FetchDogApplicationQueryVariables,
  FetchDogApplicationsQueryVariables,
  FetchDogCountriesQueryVariables,
  FetchDogSearchQueryVariables,
  FetchIntegrationQueryVariables,
  FetchIntegrationsByAppIdQueryVariables,
  FetchTagsQueryVariables,
  FetchWorkflowsQueryVariables,
  StartCertificationMutationVariables,
  UpdateIntegrationMutationVariables,
} from '@/generated/graphql';
import { FoxApi } from '@/api/fox';
import { CoreGetter } from './getter.core';
import { fetchSafe } from '@/helpers/storeHelper';
import { HenApi } from '@/api/hen';
export const CoreActions = {
  FETCH_TAGS: 'FETCH_TAGS',
  FETCH_COUNTRIES: 'FETCH_COUNTRIES',
  FETCH_APPLICATIONS: 'FETCH_APPLICATIONS',
  FETCH_APPLICATION: 'FETCH_APPLICATION',
  FETCH_APPLICATION_SEARCH: 'FETCH_APPLICATION_SEARCH',
  FETCH_USERS_FROM_HEN: 'FETCH_USERS_FROM_HEN',
  FETCH_SELECTED_INTEGRATION: 'FETCH_SELECTED_INTEGRATION',
  FETCH_WORKFLOWS: 'FETCH_WORKFLOWS',
  MUTATION_CREATE_INTEGRATION: 'MUTATION_CREATE_INTEGRATION',
  MUTATION_START_CERTIFICATION: 'MUTATION_START_CERTIFICATION',
  GET_COUNTRY_FOR_CODE: 'GET_COUNTRY_FOR_CODE',
  FETCH_INTEGRATION_BY_APP_ID: 'FETCH_INTEGRATION_BY_APP_ID',
  MUTATION_UPDATE_INTEGRATION: 'MUTATION_UPDATE_INTEGRATION',
};

export default {
  async [CoreActions.MUTATION_CREATE_INTEGRATION](
    context: ActionContext<CoreState, {}>,
    payload: CreateIntegrationMutationVariables
  ) {
    const response = await fetchSafe(FoxApi.createIntegration, payload);
    return response;
  },

  async [CoreActions.MUTATION_UPDATE_INTEGRATION](
    context: ActionContext<CoreState, {}>,
    payload: UpdateIntegrationMutationVariables
  ) {
    const response = await fetchSafe(FoxApi.updateIntegration, payload);
    return response?.updateIntegration;
  },

  async [CoreActions.MUTATION_START_CERTIFICATION](
    context: ActionContext<CoreState, {}>,
    payload: StartCertificationMutationVariables
  ) {
    const response = await fetchSafe(FoxApi.startCertification, payload);
    return response?.createWorkflow;
  },

  async [CoreActions.FETCH_APPLICATION_SEARCH](
    context: ActionContext<CoreState, {}>,
    payload: FetchDogSearchQueryVariables
  ) {
    return await fetchSafe(DogApi.search, payload);
  },

  async [CoreActions.FETCH_APPLICATIONS](
    context: ActionContext<CoreState, {}>,
    payload: FetchDogApplicationsQueryVariables
  ) {
    context.commit(CoreMutations.SET_APPLICATIONS, []);
    const response = await fetchSafe(DogApi.find, payload);
    context.commit(CoreMutations.SET_APPLICATIONS, response?.fetchDogApplications);
  },

  async [CoreActions.FETCH_APPLICATION](
    context: ActionContext<CoreState, {}>,
    payload: FetchDogApplicationQueryVariables
  ) {
    context.commit(CoreMutations.SET_APPLICATION, null);
    const response = await fetchSafe(DogApi.application, payload);
    context.commit(CoreMutations.SET_APPLICATION, response?.fetchDogApplication);
  },

  async [CoreActions.FETCH_SELECTED_INTEGRATION](
    context: ActionContext<CoreState, {}>,
    payload: FetchIntegrationQueryVariables
  ) {
    context.commit(CoreMutations.SET_SELECTED_INTEGRATION, null);
    const response = await fetchSafe(FoxApi.integration, payload);
    context.commit(CoreMutations.SET_SELECTED_INTEGRATION, response?.fetchIntegration);
    return response?.fetchIntegration;
  },

  async [CoreActions.FETCH_WORKFLOWS](context: ActionContext<CoreState, {}>, payload: FetchWorkflowsQueryVariables) {
    context.commit(CoreMutations.SET_SELECTED_INTEGRATION, []);
    const response = await fetchSafe(FoxApi.workflows, payload);
    context.commit(CoreMutations.SET_SELECTED_INTEGRATION_WORKFLOWS, response?.fetchWorkflows);
    return response?.fetchWorkflows;
  },

  async [CoreActions.FETCH_TAGS](context: ActionContext<CoreState, {}>, payload: FetchTagsQueryVariables) {
    const response = await fetchSafe(FoxApi.tags, payload);
    return response;
  },

  async [CoreActions.FETCH_COUNTRIES](context: ActionContext<CoreState, {}>, payload: FetchDogCountriesQueryVariables) {
    if (context.getters[CoreGetter.GET_COUNTRIES] && context.getters[CoreGetter.GET_COUNTRIES].length > 0) {
      return; // countries already stored
    }
    const response = await fetchSafe(FoxApi.countries, payload);
    context.commit(CoreMutations.SET_COUNTRIES, response?.fetchDogCountries);
  },

  async [CoreActions.GET_COUNTRY_FOR_CODE](context: ActionContext<CoreState, {}>, payload: string) {
    if (!context.state.countries) {
      await context.dispatch(CoreActions.FETCH_COUNTRIES);
    }

    const c = context.state.countries!.find((c: Country) => c.code === payload);
    return c;
  },

  /**
   * We don't need to save the result into the store,
   * as this is a one-time query and we don't reuse the results anywhere
   * @param payload FetchIntegrationsByAppIdQueryVariables
   */
  async [CoreActions.FETCH_INTEGRATION_BY_APP_ID](
    _: ActionContext<CoreState, {}>,
    payload: FetchIntegrationsByAppIdQueryVariables
  ) {
    return await fetchSafe(FoxApi.integrationsByAppId, payload);
  },

  async [CoreActions.FETCH_USERS_FROM_HEN](
    context: ActionContext<CoreState, {}>,
    payload: FetchAssignedUsersQueryVariables
  ) {
    context.commit(CoreMutations.SET_USERS_FROM_HEN, []);
    const response = await fetchSafe(HenApi.assignedUsers, payload);
    context.commit(CoreMutations.SET_USERS_FROM_HEN, response?.fetchHenAssignedUsers);
  },
};
