import { ROUTER } from '@/constants';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import VueGtag from 'vue-gtag';
import '@nielsen-media/auth-api/src/auth0-permissions';
// @ts-ignore
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue'),
    children: [
      {
        path: '/',
        name: ROUTER.SEARCH,

        component: () => import(/* webpackChunkName: "search-result" */ '../views/search/Search.vue'),
      },
      {
        path: '/search',
        name: ROUTER.SEARCH_RESULT,
        props: true,
        component: () => import(/* webpackChunkName: "search-result" */ '../views/search/SearchResult.vue'),
      },
    ],
  },
  {
    path: '/integration/:integrationId',
    name: ROUTER.WORKFLOW_LIST,
    props: true,
    component: () => import(/* webpackChunkName: "core" */ '../views/WorkflowList.vue'),
  },
  {
    path: '/integration/:integrationId/workflow/:product',
    name: ROUTER.WORKFLOW_DETAILS,
    props: true,
    component: () => import(/* webpackChunkName: "workflow" */ '../views/WorkflowDetails.vue'),
  },
  {
    path: '/report',
    name: ROUTER.REPORT,
    component: () => import(/* webpackChunkName: "report" */ '../views/Report.vue'),
  },
  {
    path: '/groups',
    name: ROUTER.GROUPS,
    component: () => import(/* webpackChunkName: "groups" */ '../views/groups/GroupsHome.vue'),
  },
  {
    path: '/groups/:groupId',
    name: ROUTER.GROUP_DETAILS,
    component: () => import(/* webpackChunkName: "groups" */ '../views/groups/GroupDetails.vue'),
  },
  {
    path: '/create-integration',
    name: ROUTER.INTEGRATIONS,
    component: () => import(/* webpackChunkName: "core" */ '../views/CreateIntegrationBase.vue'),
  },
  { path: '*', redirect: '/' },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_GA || '121087711-16' }, // staging default
    params: {
      /* eslint-disable @typescript-eslint/camelcase */
      send_page_view: true,
    },
    appName: 'FOX',
    pageTrackerScreenviewEnabled: true,
  },
  router
);

export default router;
