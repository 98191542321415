import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import 'material-design-icons-iconfont/dist/material-design-icons.css';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'md',
  },
  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        primary: '#2dabea',
        secondary: colors.blueGrey.lighten1,
        accent: '#8e24aa',
        success: '#7bbd42',
        warning: '#fcb346',
        error: '#d51e24',
        primaryButtonColor: colors.orange.darken1,
      },
    },
  },
});
