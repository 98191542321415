






































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({ components: {}, props: {} })
export default class BaseView extends Vue {
  @Prop({ default: '' }) name!: string;
  @Prop() title!: string;
  @Prop({ default: null }) subtitle!: string | null;
  @Prop({ default: false }) loading!: boolean;
  @Prop({ default: () => [] }) properties!: string[];

  get listContentClasses() {
    if (this.$router.currentRoute.path.indexOf('/integration') === -1) return 'list-content';
    return 'list-content-breadcrumb';
  }
}
