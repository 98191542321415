import { getColorFromState } from './integrationHelper';
import i18n from '@/plugins/i18n';
import { isNumber } from './typeHelpers';
import { ParentResultHash, ReportState, ReportType } from '@/store/report/report';
import {
  Country,
  FiltersInput,
  MarketViewEntityParentFilterInput,
  ReportFilterInput,
  SavedFilterDaterange,
  UpdateReportFilterInput,
} from '@/generated/graphql';
import { defaultDateTimeFormat } from '@/mixins/date';
import moment from 'moment';
import { CUSTOM_DATE_RANGE_NAME } from '@/constants';

export interface TableData {
  [name: string]: string | number;
}
export const colorOrder = [
  'planned',
  'dev_sanity',
  'smoke_test',
  'quality_assurance',
  'cert_review',
  'build_approved',
  'post_launch_verification',
  'production',
];
export const LABEL_DELIMITER = '|';

export const REPORTING_DATES_FORMAT = 'YYYY-MM-DD';

const thisMonth: SavedFilterDaterange = { name: 'thisMonth', startDate: '', endDate: '' },
  lastMonth: SavedFilterDaterange = { name: 'lastMonth', startDate: '', endDate: '' },
  thisYear: SavedFilterDaterange = { name: 'thisYear', startDate: '', endDate: '' },
  lastYear: SavedFilterDaterange = { name: 'lastYear', startDate: '', endDate: '' },
  allTime: SavedFilterDaterange = { name: 'allTime', startDate: '', endDate: '' };

export function thisMonthDates() {
  thisMonth.startDate = moment()
    .startOf('month')
    .format(REPORTING_DATES_FORMAT);
  thisMonth.endDate = moment().format(REPORTING_DATES_FORMAT);
  return thisMonth;
}
export function lastMonthDates() {
  lastMonth.startDate = moment()
    .subtract(1, 'months')
    .startOf('month')
    .format(REPORTING_DATES_FORMAT);
  lastMonth.endDate = moment()
    .subtract(1, 'months')
    .endOf('month')
    .format(REPORTING_DATES_FORMAT);
  return lastMonth;
}
export function thisYearDates() {
  thisYear.startDate = moment()
    .startOf('year')
    .format(REPORTING_DATES_FORMAT);
  thisYear.endDate = moment().format(REPORTING_DATES_FORMAT);
  return thisYear;
}
export function lastYearDates() {
  lastYear.startDate = moment()
    .subtract(1, 'years')
    .startOf('year')
    .format(REPORTING_DATES_FORMAT);
  lastYear.endDate = moment()
    .subtract(1, 'years')
    .endOf('year')
    .format(REPORTING_DATES_FORMAT);
  return lastYear;
}

export function allTimeDates() {
  allTime.startDate = moment('1189-07-06').format(REPORTING_DATES_FORMAT);

  allTime.endDate = moment().format(REPORTING_DATES_FORMAT);
  return allTime;
}

export function getDateRange(dateRange: SavedFilterDaterange): SavedFilterDaterange {
  switch (dateRange.name) {
    case 'lastYear':
      return lastYearDates();
    case 'thisYear':
      return thisYearDates();
    case 'lastMonth':
      return lastMonthDates();
    case 'thisMonth':
      return thisMonthDates();
    case 'allTime':
      return allTimeDates();
    case CUSTOM_DATE_RANGE_NAME:
      return dateRange;
    default:
      return thisYearDates();
  }
}

// returns colors for the usual state order
export function getStateColors(): string[] {
  const result = [];
  for (let i = 0; i < colorOrder.length; i++) {
    result.push(getColorFromState(colorOrder[i]));
  }
  return result;
}
// returns names for the usual state order
export function getStateNames(): string[] {
  const result = [];
  for (let i = 0; i < colorOrder.length; i++) {
    result.push(i18n.t(`smStates.${colorOrder[i]}`).toString());
  }
  return result;
}

export function generateTotals(items: TableData[]): TableData[] {
  const result: TableData[] = [];
  const total: TableData = {
    name: i18n.t('total').toString(),
    total: 0,
  };
  items.forEach(item => {
    const keys = Object.keys(item);
    let itemTotal = 0;
    keys.forEach(key => {
      if (isNumber(item[key]) && total[key] === undefined) {
        total[key] = 0;
      }
      if (isNumber(item[key])) {
        total[key] = (total[key] as number) + (item[key] as number);
        itemTotal += item[key] as number;
        total['total'] = (total['total'] as number) + (item[key] as number);
      }
    });
    item['total'] = itemTotal;
    result.push(item);
  });
  result.push(total);
  return result;
}

function capitalize(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function generateStateDeviceTypeHeaderValue(type: string): string {
  let result = '';
  const tokens = type.split(' ');
  tokens.forEach((s, i) => {
    if (i === 0) {
      result = s.toLowerCase();
    } else {
      result += capitalize(s);
    }
  });

  return result;
}

function reduceRegions(selectedCountries: Country[]) {
  return selectedCountries.reduce((acc, c) => {
    if (c.code) {
      acc.push(c.code);
    }
    return acc;
  }, [] as string[]);
}

function reduceEntities(selectedParents: ParentResultHash) {
  return Object.keys(selectedParents).reduce((acc, e) => {
    const selectedEntities = selectedParents[parseInt(e)]?.selected;
    if (selectedEntities) {
      const selected = selectedEntities.map((e: number) => {
        return { id: e };
      });
      acc = [...acc, ...selected];
    }
    return acc;
  }, [] as { id: number }[]);
}

// we cant use a getter since we create a completely new object that should not be cached by vuex
export function generateFilterPayload(state: ReportState) {
  const filters = {} as FiltersInput;
  filters.marketViewEntities = reduceEntities(state.selectedParents);
  filters.os = state.selectedOs.map(os => os.code);
  filters.region = reduceRegions(state.selectedCountries);
  filters.products = state.selectedProducts.map(p => p.code);
  filters.daterange = state.selectedDate ? [state.selectedDate?.startDate, state.selectedDate?.endDate] : [];
  filters.deviceGroups = state.selectedDevices;
  filters.status = state.selectedStatus.map(status => status.code);
  return filters;
}

export function generateStateDeviceTypeTableData(report: ReportType) {
  const data: { [name: string]: number | string }[] = [];
  const headers = [];
  for (const c of colorOrder) {
    if (report.count_by_state_and_device_types[c]) {
      const ele = report.count_by_state_and_device_types[c][0];
      data.push({
        name: c,
        ...ele,
      });
      for (const d in ele) {
        if (headers.indexOf(d) === -1) {
          headers.push(d);
        }
      }
    } else {
      data.push({
        name: c,
      });
    }
  }
  for (const e of data) {
    for (const h of headers) {
      if (!e[h]) {
        e[h] = '-';
      }
    }
  }
  return {
    data,
    headers,
  };
}

export function getAllProducts(report: ReportType): string[] {
  const result: string[] = [];
  for (const os in report.count_by_product_os) {
    const ps = report.count_by_product_os[os].map(p => Object.keys(p)[0]);
    for (const p of ps) {
      if (result.indexOf(p) === -1) {
        result.push(p);
      }
    }
  }
  return result.sort();
}

export function findProductAndState(
  product: string,
  stateName: string,
  data: [{ [name: string]: [{ [name: string]: number }] }]
): number {
  for (const ele of data) {
    if (Object.keys(ele)[0] === product) {
      for (const state of ele[Object.keys(ele)[0]]) {
        if (state[stateName]) return state[stateName];
      }
    }
  }
  return 0;
}

function sortBarChartData(a: { title: string }, b: { title: string }) {
  if (a.title < b.title) {
    return -1;
  }
  if (a.title > b.title) {
    return 1;
  }
  return 0;
}

export function generateBarChartData(report: ReportType) {
  const chartData = [];
  const products = getAllProducts(report);
  for (const os in report.count_by_product_os) {
    const result = {
      title: os,
      products: products,
      series: [] as unknown[],
    };
    for (const c of colorOrder) {
      const seriesItem = { name: i18n.t(`smStates.${c}`), data: [] as unknown[] };
      for (const product of products) {
        seriesItem.data.push(findProductAndState(product, c, report.count_by_product_os[os]));
      }
      result.series.push(seriesItem);
    }
    chartData.push(result);
  }
  return chartData.sort(sortBarChartData);
}

export function generateTimeSeriesData(report: ReportType) {
  const data = {
    headers: [] as string[],
    data: [
      { name: i18n.t('new').toString(), data: [] },
      { name: i18n.t('complete').toString(), data: [] },
    ],
  };
  for (const element of report.count_by_time_series) {
    data.headers.push(moment(element[0]).format(defaultDateTimeFormat));
    data.data[0].data.push(element[1]['planned']);
    data.data[1].data.push(element[1]['complete']);
  }
  return data;
}

export function generateDonutData(report: ReportType) {
  const data = [];
  for (const c of colorOrder) {
    const value = report.count_by_current_state[c];
    data.push(value !== undefined ? value : 0);
  }
  return data;
}

export function generateSavedFilter<T extends ReportFilterInput | UpdateReportFilterInput>(
  data: T,
  state: ReportState
): T {
  data.filters = {};

  const mves: MarketViewEntityParentFilterInput[] = [];
  for (const p in state.selectedParents) {
    mves.push({
      id: state.selectedParents[p].entity.id,
      name: state.selectedParents[p].entity.name,
      selection: state.selectedParents[p].selected,
    });
  }
  data.filters.daterange = state.selectedDate;
  data.filters.deviceGroups = state.selectedDevices;
  data.filters.marketViewEntities = mves;
  data.filters.os = state.selectedOs.map(o => o.code);
  data.filters.products = state.selectedProducts.map(o => o.code);
  data.filters.region = state.selectedCountries.map(o => o.code!);
  data.filters.status = state.selectedStatus.map(s => s.code);
  return data;
}
