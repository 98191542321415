import { FoxApi } from '@/api/fox';
import { OwlApi } from '@/api/owl';
import { HenApi } from '@/api/hen';
import {
  FetchCertificationGroupsQueryVariables,
  FetchCertificationGroupQueryVariables,
  FetchAllUsersForHierarchyQueryVariables,
  CreateCertificationGroupMutationInput,
  FetchOwlTreeNodesQueryVariables,
  UpdateCertificationGroupMutationInput,
  FetchIntegrationsQueryVariables,
  AddIntegrationsToCertificationGroupMutationInput,
  RemoveIntegrationsFromCertificationGroupMutationInput,
  StartCompleteRecertificationMutationVariables,
  FetchCertifiedGroupWorkflowsQueryVariables,
} from '@/generated/graphql';
import { fetchSafe } from '@/helpers/storeHelper';

import { ActionContext } from 'vuex';
import { GroupsMutations } from './mutations.groups';
import { GroupsState } from './groups';

export const GroupsActions = {
  FETCH_GROUPS: 'FETCH_GROUPS',
  FETCH_GROUP: 'FETCH_GROUP',
  CREATE_GROUP: 'CREATE_GROUP',
  EDIT_GROUP: 'EDIT_GROUP',
  FETCH_INTEGRATIONS: 'FETCH_INTEGRATIONS',
  ADD_INTEGRATIONS_TO_GROUP: 'ADD_INTEGRATIONS_TO_GROUP',
  RECERTIFY_INTEGRATIONS_IN_GROUP: 'RECERTIFY_INTEGRATIONS_IN_GROUP',
  REMOVE_INTEGRATION_FROM_GROUP: 'REMOVE_INTEGRATION_FROM_GROUP',
  FETCH_MARKET_VIEW_ENTITIES: 'FETCH_MARKET_VIEW_ENTITIES',
  FETCH_ALL_USERS_FROM_HIERARCHY: 'FETCH_ALL_USERS_FROM_HIERARCHY',
  START_GROUP_RECERTIFICATION: 'START_GROUP_RECERTIFICATION',
  FETCH_CERTIFIED_WORKFLOWS: 'FETCH_CERTIFIED_WORKFLOWS',
};

export default {
  async [GroupsActions.FETCH_GROUPS](
    { commit }: ActionContext<GroupsState, {}>,
    payload: FetchCertificationGroupsQueryVariables
  ) {
    commit(GroupsMutations.SET_GROUPS_LOADING, true);
    const response = await fetchSafe(FoxApi.certificationGroups, payload);
    commit(GroupsMutations.SET_GROUPS, response?.fetchCertificationGroups);
    commit(GroupsMutations.SET_GROUPS_LOADING, false);
  },

  async [GroupsActions.FETCH_GROUP](
    { commit }: ActionContext<GroupsState, {}>,
    payload: FetchCertificationGroupQueryVariables
  ) {
    commit(GroupsMutations.SET_GROUP_LOADING, true);
    commit(GroupsMutations.SET_GROUP, {});
    const response = await fetchSafe(FoxApi.certificationGroup, payload);
    commit(GroupsMutations.SET_GROUP, response?.fetchCertificationGroup);
    commit(GroupsMutations.SET_GROUP_LOADING, false);
  },

  async [GroupsActions.CREATE_GROUP](
    { commit }: ActionContext<GroupsState, {}>,
    payload: CreateCertificationGroupMutationInput
  ) {
    commit(GroupsMutations.SET_GROUP, {});
    const response = await fetchSafe(FoxApi.createCertificationGroup, payload);
    commit(GroupsMutations.SET_GROUP, response?.createCertificationGroup?.certificationGroup);
    return response?.createCertificationGroup?.certificationGroup;
  },

  async [GroupsActions.EDIT_GROUP](
    { commit }: ActionContext<GroupsState, {}>,
    payload: UpdateCertificationGroupMutationInput
  ) {
    commit(GroupsMutations.SET_GROUP_LOADING, true);
    commit(GroupsMutations.SET_GROUP, {});
    const response = await fetchSafe(FoxApi.updateCertificationGroup, payload);
    commit(GroupsMutations.SET_GROUP, response?.updateCertificationGroup?.certificationGroup);
    commit(GroupsMutations.SET_GROUP_LOADING, false);
  },

  async [GroupsActions.FETCH_INTEGRATIONS](
    { commit }: ActionContext<GroupsState, {}>,
    payload: FetchIntegrationsQueryVariables
  ) {
    commit(GroupsMutations.SET_INTEGRATIONS_LOADING, true);
    const response = await fetchSafe(FoxApi.searchIntegration, payload);
    commit(GroupsMutations.SET_INTEGRATIONS, response?.fetchIntegrations.nodes);
    commit(GroupsMutations.SET_INTEGRATIONS_LOADING, false);
  },

  async [GroupsActions.ADD_INTEGRATIONS_TO_GROUP](
    { commit }: ActionContext<GroupsState, {}>,
    payload: AddIntegrationsToCertificationGroupMutationInput
  ) {
    commit(GroupsMutations.SET_GROUP_LOADING, true);
    commit(GroupsMutations.SET_GROUP, {});
    const response = await fetchSafe(FoxApi.addIntegrationsToGroup, payload);
    commit(GroupsMutations.SET_GROUP, response?.addIntegrationsToCertificationGroup?.certificationGroup);
    commit(GroupsMutations.SET_GROUP_LOADING, false);
  },

  async [GroupsActions.REMOVE_INTEGRATION_FROM_GROUP](
    { commit }: ActionContext<GroupsState, {}>,
    payload: RemoveIntegrationsFromCertificationGroupMutationInput
  ) {
    commit(GroupsMutations.SET_GROUP_LOADING, true);
    commit(GroupsMutations.SET_GROUP, {});
    const response = await fetchSafe(FoxApi.removeIntegrationFromGroup, payload);
    commit(GroupsMutations.SET_GROUP, response?.removeIntegrationsFromCertificationGroup?.certificationGroup);
    commit(GroupsMutations.SET_GROUP_LOADING, false);
  },

  async [GroupsActions.FETCH_MARKET_VIEW_ENTITIES](
    { commit }: ActionContext<GroupsState, {}>,
    payload: FetchOwlTreeNodesQueryVariables
  ) {
    commit(GroupsMutations.SET_MVE_LOADING, true);
    const response = await fetchSafe(OwlApi.search, payload);
    commit(GroupsMutations.SET_MVE_LOADING, false);
    return response?.fetchOwlTreeNodes;
  },

  async [GroupsActions.FETCH_ALL_USERS_FROM_HIERARCHY](
    context: ActionContext<GroupsState, {}>,
    payload: FetchAllUsersForHierarchyQueryVariables
  ) {
    context.commit(GroupsMutations.SET_USERS_FROM_HIERACHY, []);
    const response = await fetchSafe(HenApi.allUsersForHierarchy, payload);
    context.commit(GroupsMutations.SET_USERS_FROM_HIERACHY, response?.fetchAllUsersForHierarchy);
  },

  async [GroupsActions.START_GROUP_RECERTIFICATION](
    context: ActionContext<GroupsState, {}>,
    payload: StartCompleteRecertificationMutationVariables
  ) {
    context.commit(GroupsMutations.SET_RECERT_LOADING, true);
    await fetchSafe(FoxApi.startCompleteRecertification, payload);
    // @ts-ignore
    const groupId = context.state.group.id;
    if (groupId) {
      await context.dispatch(GroupsActions.FETCH_GROUP, { id: groupId });
    }

    context.commit(GroupsMutations.SET_RECERT_LOADING, false);
  },

  async [GroupsActions.FETCH_CERTIFIED_WORKFLOWS](
    { commit }: ActionContext<GroupsState, {}>,
    payload: FetchCertifiedGroupWorkflowsQueryVariables
  ) {
    commit(GroupsMutations.SET_INTEGRATIONS_LOADING, true);
    const response = await fetchSafe(FoxApi.certifiedGroupWorkflows, payload);
    commit(GroupsMutations.SET_INTEGRATIONS_LOADING, false);
    return response?.fetchCertifiedGroupWorkflows;
  },
};
