import {
  TreeNode,
  Os,
  Country,
  Product,
  DeviceTypeGroup,
  ReportFilter,
  SavedFilterDaterange,
  DogStatus,
} from '@/generated/graphql';
import { TranslateResult } from 'vue-i18n';
import actions from './actions.report';
import getters from './getter.report';
import mutations from './mutations.report';

export type ParentResultHash = {
  [treeId: number]: ParentResult;
};

export type PdfRowType = {
  content: string | TranslateResult;
  styles?: { [fillColor: string]: string };
};

export type ReportType = {
  certifications: { [key: string]: string }[];
  count_by_current_state: { [name: string]: number };
  count_by_product_os: { [name: string]: [{ [name: string]: [{ [name: string]: number }] }] };
  count_by_state_and_device_types: { [name: string]: [{ [name: string]: number }] };
  count_by_time_series: [];
  charts: {
    stateDeviceType: { data: { [name: string]: string | number }[]; headers: string[] };
    donut: number[];
    bar: { title: string; products: string[]; series: unknown[] }[];
    timeSeries: { headers: string[]; data: { name: string; data: never[] }[] };
  };
};

export interface ParentResult {
  entity: TreeNode;
  excerpt: TreeNode | null;
  selected: number[];
}
export interface ReportState {
  loading: boolean;
  deviceTypes: DeviceTypeGroup[];
  selectedCountries: Country[];
  selectedProducts: { code: Product; name: TranslateResult }[];
  selectedOs: { code: Os; name: TranslateResult }[];
  selectedDate: SavedFilterDaterange | null;
  selectedDevices: DeviceTypeGroup[];
  selectedParents: ParentResultHash;
  selectedEntities: { id: number }[];
  selectedStatus: { code: DogStatus; name: TranslateResult }[];
  report: ReportType | null;
  showReportDialog: boolean;
  showSaveFilterDialog: boolean;
  showLoadFilterDialog: boolean;
  loadedFilter: ReportFilter | null;
}

export const state: ReportState = {
  loading: false,
  deviceTypes: [],
  selectedCountries: [],
  selectedProducts: [],
  selectedOs: [],
  selectedDate: null,
  selectedDevices: [],
  selectedParents: {},
  selectedEntities: [],
  selectedStatus: [],
  report: null,
  showReportDialog: false,
  showSaveFilterDialog: false,
  showLoadFilterDialog: false,
  loadedFilter: null,
};

const Report = {
  namespaced: true as true,
  state,
  getters,
  mutations,
  actions,
};

export default Report;
