import { EVENTS } from '@/constants';
import { EventBus } from '@/mixins/eventBus';
import { FetchResult } from '@apollo/client';
import { HttpStatuses } from '@nielsen-media/identity-spa';

/**
 * runs the fn inside a try catch block and will report errors to the global error snackbar
 *
 * @param fn the api call
 * @param args payload for api call
 */
export async function fetchSafe<T, V>(fn: (arg: V) => Promise<FetchResult<T>>, args: V): Promise<T | null> {
  try {
    const response = await fn(args);
    if (response.errors) {
      EventBus.$emit(EVENTS.GLOBAL_ALERT, response.errors[0]);
    }
    return response.data ? response.data : null;
  } catch (e) {
    if (!e.networkError || !e.networkError.statusCode || e.networkError.statusCode != HttpStatuses.Unauthorized) {
      EventBus.$emit(EVENTS.GLOBAL_ALERT, e);
    }
    return e;
  }
}
