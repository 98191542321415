import { Integration, PageInfo, Country, Os, JicCode, Product, DeviceTypeGroup, DogStatus } from '@/generated/graphql';
import actions from './actions.search';
import getters from './getter.search';
import mutations from './mutations.search';
import VueI18n from 'vue-i18n';

export interface ComputedHeaders {
  text: string | VueI18n.TranslateResult;
  value: string;
  align: string;
  sortable: boolean;
  hide?: string;
}

export interface IntegrationsFilters {
  region: Country['code'] | null;
  operatingSystem: Os | null;
  product: Product | null;
  jicCode?: JicCode | null;
  deviceTypeGroupId: number | null;
  status: DogStatus | null;
}

export interface SearchState {
  result: Integration[];
  searchTerm: string;
  page: number;
  itemsPerPage: number;
  after: string;
  before: string;
  totalCount: number;
  pageInfo: PageInfo;
  filters: IntegrationsFilters;
  loading: boolean;
  deviceTypeGroups: DeviceTypeGroup[];
}

export const state: SearchState = {
  result: [],
  searchTerm: '',
  page: 1,
  itemsPerPage: 15,
  after: '',
  before: '',
  totalCount: 0,
  pageInfo: {
    hasNextPage: true,
    hasPreviousPage: false,
    endCursor: '',
    startCursor: '',
  },
  filters: {
    region: null,
    jicCode: null,
    operatingSystem: null,
    product: null,
    deviceTypeGroupId: null,
    status: null,
  },
  deviceTypeGroups: [],
  loading: false,
};

const Search = {
  namespaced: true as true,
  state,
  getters,
  mutations,
  actions,
};

export default Search;
