import {
  Application,
  Brand,
  C6s,
  Channel,
  Country,
  Integration,
  TreeNode,
  Workflow,
  AssignedUser,
} from '@/generated/graphql';
import { CoreState } from './core';

export const CoreMutations = {
  CLEAR: 'CLEAR',
  SET_COUNTRIES: 'SET_COUNTRIES',
  SET_C6S: 'SET_C6s',
  SET_NODE: 'SET_NODE',
  SET_APPLICATION: 'SET_APPLICATION',
  SET_APPLICATIONS: 'SET_APPLICATIONS',
  SET_USERS_FROM_HEN: 'SET_USERS_FROM_HEN',
  SET_DDRS_ENTRY: 'SET_DDRS_ENTRY',
  SET_SELECTED_INTEGRATION: 'SET_SELECTED_INTEGRATION',
  SET_SELECTED_INTEGRATION_WORKFLOWS: 'SET_SELECTED_INTEGRATION_WORKFLOWS',
};

export default {
  [CoreMutations.CLEAR](state: CoreState) {
    state.selectedIntegration = null;
  },

  [CoreMutations.SET_C6S](state: CoreState, v: C6s) {
    state.c6s = v;
  },

  [CoreMutations.SET_NODE](state: CoreState, v: TreeNode) {
    state.node = v;
  },

  [CoreMutations.SET_APPLICATION](state: CoreState, v: Application) {
    state.application = v;
  },

  [CoreMutations.SET_APPLICATIONS](state: CoreState, v: Application[]) {
    state.applications = v;
  },

  [CoreMutations.SET_USERS_FROM_HEN](state: CoreState, v: AssignedUser[]) {
    const value = v && v.length > 0 ? v : [];
    state.assignedUsersHen = value;
  },

  [CoreMutations.SET_DDRS_ENTRY](state: CoreState, v: Brand | Channel) {
    state.ddrsEntry = v;
  },

  [CoreMutations.SET_SELECTED_INTEGRATION](state: CoreState, v: Integration | null) {
    state.selectedIntegration = v;
  },

  [CoreMutations.SET_SELECTED_INTEGRATION_WORKFLOWS](state: CoreState, v: Workflow[] | null) {
    state.selectedIntegrationWorkflows = v;
  },

  [CoreMutations.SET_COUNTRIES](state: CoreState, v: Country[]) {
    if (v.length > 0) {
      state.countries = [{ code: null, name: 'Not Selected' }, ...v];
      return;
    }
    state.countries = [];
  },
};
